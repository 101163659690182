// Analytics
import ReactGA from 'react-ga';

export const config = {
    trackingId: process.env.REACT_APP_ANALYTICS_CODE_UA,
};

ReactGA.initialize(config.trackingId);

export async function eventTrack (category, action, label) {
    console.log("GA event:", category, ":", action, ":", label);
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
}