import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";

export default function TextView(props) {
  const { textColor="#424242", textAlign="left", fontWeight, fullWidth=false, children } = props;
  const useStyles = makeStyles({
    textColor: {
      color: textColor
    },
    textColorFont: {
      color: textColor,
      fontWeight: fontWeight
    }
  });
  const classes = useStyles();

  let { ml=0, mt=0, mr=0, mb=0 } = props;

  if (props.m) {
    ml = mt = mr = mb = props.m;
  }
  if (props.my) {
    mt = mb = props.my;
  }
  if (props.mx) {
    ml = mr = props.mx;
  }

  if (fullWidth) {
    return (
      <Box width={1} ml={ml} mt={mt} mr={mr} mb={mb} textAlign={textAlign}>
        <Typography 
          className={
            fontWeight === undefined ? 
              classes.textColor : 
              classes.textColorFont
            } 
          {...props}
        >
          {children}
        </Typography>
      </Box>
    );
  }

  return (
    <Box ml={ml} mt={mt} mr={mr} mb={mb} textAlign={textAlign}>
      <Typography 
        className={
          fontWeight === undefined ? 
            classes.textColor : 
            classes.textColorFont
          } 
        {...props}
      >
        {children}
      </Typography>
    </Box>
  );
}