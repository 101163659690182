import React from 'react';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';

// Sections
import BannerSection from './sections/BannerSection';
import DescriptionSection from './sections/DescriptionSection';

// Components
import Parallax from '../../components/Parallax';

const useStyles = makeStyles({
  cardRoot: {
    marginTop: 0,
    position: "relative",
    background: "#fff",
    zIndex: "3"
  }
});

function LocationsPage() {
    const classes = useStyles();
  
    return (
      <Box>
        <Parallax image="/image/Fondo_contancto.png">
          <BannerSection/>
        </Parallax>
        <Box className={classes.cardRoot}>
          <DescriptionSection/>
        </Box>
      </Box>
    );
  }
  
  export default LocationsPage;
  