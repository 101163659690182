import React from 'react';

// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Hidden from '@material-ui/core/Hidden';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';

// Components
import PlanDetails from './PlanDetails';
import CardView from '../../../components/CardView';
import TextView from '../../../components/TextView';
import { planes, parque } from '../../../ArraysDB';
import { getScreenRange } from '../../../App';

const money = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
});

const PlanItem = ({ plan, showCustomDialog }) => {
  let range = getScreenRange();
  let dialogWidth = 30;

  if (range === "xs") {
    dialogWidth = 100;
  }
  if (range === "sm") {
    dialogWidth = 65;
  }
  if (range === "md") {
    dialogWidth = 50;
  }
  if (range === "lg") {
    dialogWidth = 35;
  }

  const handleClickSelectPlan = event => {
    let selectedIndex = localStorage.getItem(`${plan.name}Index`);
    let selectedPlazo = plan.pagosConAnticipo[selectedIndex];
    let tipoDePlan = {
      planId: plan.id,
      anticipo: true,
      nombreAlternativo: false,
      plazo: selectedPlazo.plazo,
      primerPago: selectedPlazo.quincenal.primerPago,
      parcialidad: selectedPlazo.quincenal.pago,
      total: selectedPlazo.quincenal.total
    }
    localStorage.setItem('tipoDePlan', JSON.stringify(tipoDePlan));
    window.location.href='/comprar-plan';
  }
  const handleClickViewDetails = event => {
    showCustomDialog(
      (<PlanDetails plan={plan} />), 
      `PLAN ${plan.name}`, 
      "ADQUIRIR",
      "CERRAR",
      handleClickSelectPlan,
      null,
      dialogWidth);
  }

  return (
    <Box mt={1} style={{ height: "100%" }}>
      <CardView color="#9AABC8" 
        elevation={2} 
        radius={12}
        height="100%"
      >
        <CardActionArea 
          onClick={handleClickViewDetails}
          style={{ height: "100%" }}
        >
          <Box p={3} width={1} height={1}>
            <Grid item container xs={12}
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >

              <Grid container xs={12}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item container xs={3} justifyContent="center" alignItems="center">
                  <Grid item style={{ marginLeft: "auto", marginRight: "auto"}}>
                    <img 
                      src={plan.logo} 
                      alt="" 
                      width="80%"
                      style={{ 
                        maxWidth: 72, 
                        maxHeight: 72,
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto"
                      }}/>
                  </Grid>
                </Grid>
                <Grid item container xs={9}
                  direction="column"
                  alignItems="flex-start"
                >
                  <Grid item container xs={12}>
                    <TextView variant="h6" textColor="#fff" mb={1} fullWidth>
                      {plan.name}
                    </TextView>
                  </Grid>

                  <Grid item container xs={12}>
                    {plan.summary.map((option, key) => (
                      <TextView textColor="#eee" fullWidth>
                        {`- ${option}`}
                      </TextView>
                    ))}
                    <TextView textColor="#eee" align="right" fontWeight={700} fullWidth mt={1} mr={2.5}>
                      Más información
                    </TextView>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container xs={12}
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                style={{ 
                  marginTop: 32
                }}
              >
                <Grid item container xs={12}>
                  <TextView 
                    variant="h6" 
                    textColor="#fff" 
                    align="right" 
                    style={{ 
                      position: "absolute",
                      bottom: 16, 
                      right: 16
                    }}
                  >
                    Desde {money.format(plan.pagosConAnticipo[plan.pagosConAnticipo.length - 1].quincenal.pago)}
                  </TextView>
                </Grid>
              </Grid>

            </Grid>
          </Box>
        </CardActionArea>
      </CardView>
    </Box>
  );
}

export default PlanItem;