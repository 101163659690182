import React from 'react';

// @material-ui/core components
import Box from "@material-ui/core/Box";

function AvisoPrivacidad() {
  return (
    <Box>
      <p style={{ textAlign: "justify" }}>
        Su privacidad y confianza son muy importantes para nosotros. Por ello, queremos asegurarnos de que conozca como salvaguardamos 
        la integridad, privacidad y protección de sus datos personales.
      </p>

      <p style={{ textAlign: "justify" }}>
        Consideramos que por ser una empresa socialmente responsable tenemos la obligación legal y social de cumplir con las medidas 
        legales y de seguridad suficientes para proteger aquellos datos personales que se hayan recabado para las finalidades que se 
        describen en el presente aviso de privacidad.
      </p>

      <p style={{ textAlign: "justify" }}>
        Se da a conocer el presente aviso de privacidad en estricto acatamiento a los artículos tercero transitorio, 16 y 17 de la Ley 
        Federal de Protección de Datos Personales en Posesión de los Particulares, publicada en el Diario Oficial de la Federación el 
        día 5 de julio del año 2010, ordenamiento legal que tiene por objeto la protección de los datos personales en posesión de los 
        particulares, con la finalidad de regular su tratamiento legítimo, controlado e informado, a efecto de garantizar la privacidad 
        y el derecho a la autodeterminación informativa de las personas.
      </p>

      <p style={{ textAlign: "justify" }}>
        Derivado de lo antes expuesto, usted tendrá pleno control y decisión sobre sus datos personales. Por ello, le recomendamos que 
        lea atentamente la siguiente información.
      </p>

      <p style={{ textAlign: "justify" }}>
        <ol >
          <li style={{ textAlign: "justify" }}>
            <b>Definiciones.</b>
          </li>
        </ol>
      </p>

      <p style={{ textAlign: "justify" }}>
        De acuerdo con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y con el presente aviso, se 
        entenderá por:
      </p>
      
      <p style={{ textAlign: "justify" }}>
        <ol>
          <li style={{ textAlign: "justify" }}>
            <b>Datos Personales:</b> Cualquier información concerniente a una persona física identificada o identificable.
          </li>
          <li style={{ textAlign: "justify" }}>
            <b>Datos Personales sensibles:</b> Aquellos datos personales que afecten a la esfera más íntima de su titular, o 
            cuya utilización indebida pueda dar origen a discriminación o conlleve un riesgo grave para éste. En particular, 
            se consideran sensibles aquellos que puedan revelar aspectos como origen racial, étnico, estado de salud presente 
            y futuro, información genética, creencias religiosas, filosóficas y morales, afiliación sindical, opiniones políticas, 
            preferencia sexual.
          </li>
        </ol>
      </p>
      
      <p style={{ textAlign: "justify" }}>
        <b>III. Derechos ARCO: </b>Derechos de acceso, rectificación, cancelación y oposición previstos en la Ley Federal de 
        Protección de Datos Personales en Posesión de los Particulares.
      </p>
      
      <p style={{ textAlign: "justify" }}>
        <ol>
          <li style={{ textAlign: "justify" }}>
            <b>Ley: </b>Ley Federal de Protección de Datos Personales en Posesión de los Particulares.
          </li>
          <li style={{ textAlign: "justify" }}>
            <b>Responsable: </b>En conjunto San Martín Grupo Funerario conformado por: Inhumaciones ABC, S.A. de C.V.; 
            Nueva Administradora de Planes, S.A. de C.V.; Lablue, S.A. de C.V.; Servicios Funerarios a Futuro San Martín, S.A. de C.V.; 
            San Martín Grupo Desarrollador Inmobiliario, S.A. de C.V.; que llevaran a cabo el tratamiento de sus datos personales.
          </li>
          <li style={{ textAlign: "justify" }}>
            <b>Titular: </b>La persona física a quien corresponden los datos personales.
          </li>
        </ol>
      </p>
      
      <li style={{ textAlign: "justify" }}>
        <b>Datos del Responsable.</b>
      </li>

      <p style={{ textAlign: "justify" }}>
        El Responsable, es un conjunto de sociedades legalmente constituidas bajo las Leyes Mexicanas, quien señala como domicilio convencional de todas 
        ellas para los efectos relacionados con el presente aviso el ubicado en Domingo Rubí No. 836 Sur, Colonia Guadalupe, C.P. 80220, 
        en Culiacán, Sinaloa, México, el cual se encuentra comprometido con la protección de todos aquellos Datos Personales 
        proporcionados por el Titular.
      </p>
      
      <li style={{ textAlign: "justify" }}>
        <b>Información Proporcionada por el Titular.</b>
      </li>

      <p style={{ textAlign: "justify" }}>
        El Titular proporcionará al Responsable Datos Personales como: nombre completo, fecha de 
        nacimiento, registro federal de contribuyentes, nacionalidad, sexo, estado civil, correo electrónico, domicilio, número de 
        teléfono, escolaridad, empleo, salario, entre otros.
      </p>
      
      <p style={{ textAlign: "justify" }}>
        Asimismo el Titular podrá proporcionar al Responsable Datos Sensibles y patrimoniales, los cuales serán tratados bajo 
        medidas de seguridad previstas por la Ley u otras Leyes especiales, garantizando su confidencialidad en todo momento.
      </p>

      <li style={{ textAlign: "justify" }}>
        <b>Finalidad del Tratamiento de Datos.</b>
      </li>
      
      <p style={{ textAlign: "justify" }}>
        Los Datos Personales que el Titular proporcione al Responsable tienen como finalidad la (posible en su caso) relación 
        contractual a generarse entre el Titular con el Responsable y acotarse en virtud de las condiciones generales aceptadas, 
        condiciones particulares descritas en cada caso, la gestión, la administración, prestación, ampliación y mejora de los 
        productos y servicios que el Titular desee contratar, así como por las actividades propias del Responsable en su objeto.
      </p>
      
      <p style={{ textAlign: "justify" }}>
        Asimismo, los Datos Personales proporcionados por el Titular, con excepción de Datos 
        Personales sensibles podrán ser utilizados para fines comerciales y promocionales, para el envío de información comercial y 
        publicitaria, incluidos los envíos por correo electrónico, telefonía celular (mensaje SMS, MMS, entre otros) o todo medio de 
        comunicación electrónica similar o que pueda llegar a desarrollarse, así como para encuestas de calidad y satisfacción, 
        análisis de uso de productos, estadísticas para el envío de avisos acerca de productos y servicios operados por el Responsable 
        y cuando otras Leyes lo requieran.
      </p>
      
      <p style={{ textAlign: "justify" }}>
        El tratamiento de los Datos Personales 
        proporcionados por el Titular al Responsable se limitará al cumplimiento de las finalidades previstas en el presente 
        aviso de privacidad y a fines distintos que resulten compatibles o análogos a los establecidos en dicho aviso, sin que 
        para ello se requiera obtener nuevamente el consentimiento del Titular.
      </p>
      
      <p style={{ textAlign: "justify" }}>
        Al ponerse a disposición del Titular el presente aviso de privacidad y no manifestar éste oposición alguna, se entenderá que el 
        Titular otorga al Responsable su consentimiento para llevar a cabo el tratamiento de los Datos Personales que hubieran 
        sido proporcionados y/o los que con motivo de alguna de las finalidades establecidas en el presente aviso proporcione en 
        lo futuro, ya sea personalmente o a través de agentes, promotores, comisionistas o socios comerciales, así como de 
        cualquier medio electrónico, óptico, sonoro, audiovisual o a través de cualquier otra tecnología o medio con el que llegue 
        a contar el Responsable.
      </p>
      
      <p style={{ textAlign: "justify" }}>
        No será necesario el consentimiento expreso para el 
        tratamiento de Datos Personales cuando su manejo tenga el propósito de cumplir obligaciones derivadas de una relación 
        jurídica entre el Titular y el Responsable, ni en los casos que contempla el artículo 10 de la Ley. Lo anterior sin 
        perjuicio de la facultad que posee el Titular para el ejercicio de los Derechos ARCO en términos de la Ley.
      </p>

      <p style={{ textAlign: "justify" }}>
        El Responsable informa que toda comunicación por correo electrónico no protegida 
        realizada a través de Internet puede ser objeto de intercepción, pérdida o posibles alteraciones en cuyo caso, el Titular 
        no podrá exigir al Responsable alguna indemnización por cualquier daño resultante por la intercepción, sustracción, 
        pérdida o alteración relacionada con un mensaje de correo electrónico entre las partes.
      </p>
      
      <p style={{ textAlign: "justify" }}>
        El Titular manifiesta que los Datos Personales proporcionados al Responsable son veraces y se hace responsable de 
        comunicar a éste cualquier modificación a los mismos a través de los formatos designados para ello que se encuentran 
        en toda la red de sucursales de Grupo San Martin conformado por: Inhumaciones ABC, S.A. de C.V.; Nueva Administradora 
        de Planes, S.A. de C.V.; Lablue, S.A. de C.V.; Servicios Funerarios a Futuro San Martín, S.A. de C.V.; San Martín Grupo
        Desarrollador Inmobiliario, S.A. de C.V.
      </p>
      
      <p style={{ textAlign: "justify" }}>
        El Titular faculta al Responsable 
        para allegarse de Datos Personales a través de terceros que el propio Titular haya autorizado frente al Responsable en 
        calidad de referencias. Será responsabilidad del Titular informar a esos terceros respecto de la información que proporcionó 
        al Responsable y los fines de la misma, no siendo obligación del Responsable informar a esos terceros respecto del 
        contenido de este aviso de privacidad.
      </p>
      
      <p style={{ textAlign: "justify" }}>
        Los datos personales que recaba SAN MARTÍN GRUPO FUNERARIO son utilizados para:
      </p>
      
      <p style={{ textAlign: "justify" }}>
        <ol>
          <li>
            Proporcionar información respecto a los diversos productos y servicios que comercializamos.
          </li>
          <li>
            Proveer el bien o servicio contratado.
          </li>
          <li>
            Seguimiento a la relación contractual y en su caso a algún problema 
            que haya surgido respecto a servicios prestados o bienes adquiridos.
          </li>
          <li>
            Hacer de su conocimiento nuestras promociones.
          </li>
          <li>
            Atender requerimientos legales de autoridades competentes.
          </li>
          <li>
            Cumplimiento de términos y condiciones estipulado al momento de la compra y/o firma del contrato.
          </li>
        </ol>
      </p>
      
      <li style={{ textAlign: "justify" }}>
        <b>limitación del Uso y Divulgación de los datos.</b>
      </li>
      
      <p style={{ textAlign: "justify" }}>
        El Responsable ha adoptado los niveles de seguridad y de protección de Datos Personales requeridos por Ley, adicionalmente, 
        podrá implementar otros medios y medidas técnicas que se encuentren a su alcance para evitar la pérdida, mal uso, alteración, 
        acceso no autorizado y robo de los Datos Personales proporcionados por el Titular.
      </p>

      <li style={{ textAlign: "justify" }}>
        <b>Medios para ejercer los Derechos ARCO.</b>
      </li>
      
      <p style={{ textAlign: "justify" }}>
        El Titular, por sí o mediante representante 
        legal debidamente acreditado, tiene reconocidos y podrá ejercitar los derechos de acceso, rectificación, cancelación y 
        oposición frente al Responsable.
      </p>
      
      <p style={{ textAlign: "justify" }}>
        Para ello el Titular o su representante legal, 
        podrán acudir a cualquiera de las sucursales de SAN MARTÍN GRUPO FUNERARIO a solicitar un formato de ejercicio de Derechos 
        ARCO, el cual deberán llenar y entregar al Gerente de la sucursal, acompañándolo de la siguiente documentación:
      </p>

      <p style={{ textAlign: "justify" }}>
        <ol>
          <li style={{ textAlign: "justify" }}>
            Identificación oficial vigente del Titular 
            (Credencial del Instituto Federal Electoral, Pasaporte emitido por la Secretaría de Relaciones Exteriores, Cartilla del 
            servicio Militar Nacional, o Cédula Profesional).
          </li>
          <li style={{ textAlign: "justify" }}>
            En los casos en que el ejercicio de 
            los Derechos ARCO se realice a través del representante legal del Titular, además de la acreditación de la identidad de ambos, 
            se deberá entregar el poder notarial correspondiente o carta poder firmada ante dos testigos.
          </li>
          <li style={{ textAlign: "justify" }}>
            Cuando se quiera ejercer el derecho de rectificación, se tendrá que entregar la documentación que acredite el cambio solicitado 
            de acuerdo a los datos personales a rectificar.
          </li>
        </ol>
      </p>
      
      <p style={{ textAlign: "justify" }}>
        La respuesta a dicho formato, se 
        llevará a cabo por el Responsable después de 20 días hábiles contados a partir de la fecha en que fue recibido el mismo en la 
        sucursal. El Responsable podrá ampliar éste plazo hasta por 20 días hábiles más, cuando el caso lo amerite, previa notificación 
        de esto al Titular.
      </p>
      
      <p style={{ textAlign: "justify" }}>
        La resolución adoptada por el Representante, será comunicada al 
        Titular a través de las opciones elegidas por éste, las cuales se encuentran establecidas en el formato de ejercicio de Derechos 
        ARCO ubicados en las sucursales de SAN MARTÍN GRUPO FUNERARIO.
      </p>
      
      <p style={{ textAlign: "justify" }}>
        En los casos en que el 
        Titular desee revocar la autorización otorgada al Responsable respecto de los Datos Personales proporcionados, deberá realizar 
        la solicitud correspondiente en los mismos términos establecidos en el presente aviso de privacidad para ejercer los derechos 
        ARCO.
      </p>
      
      <p style={{ textAlign: "justify" }}>
        La entrega de los Datos Personales será gratuita, debiendo cubrir el Titular únicamente 
        los gastos justificados de envío o con el costo de reproducción en copias u otros formatos. No obstante, si el Titular reitera su 
        solicitud en un periodo menor a doce meses, los costos no serán mayores a tres días de Salario Mínimo General Vigente en el Distrito 
        Federal a menos que existan modificaciones sustanciales al aviso de privacidad que motiven nuevas consultas.
      </p>

      <li style={{ textAlign: "justify" }}>
        <b>Transferencia de Datos.</b>
      </li>
      
      <p style={{ textAlign: "justify" }}>
        El Responsable se compromete a 
        cuidar el cumplimiento de todos los principios legales de protección en torno a la transferencia de sus datos personales. 
        De igual forma, manifiesta su compromiso para que se respete en todo momento, el presente aviso de privacidad, por las personas 
        físicas o morales a las que se pudiera transferir la información proporcionada.
      </p>

      <p style={{ textAlign: "justify" }}>
        El Responsable podrá transferir los datos personales entre las propias Entidades integrantes de San Martín Grupo Funerario y 
        todas sus empresas filiales así como a terceros nacionales o extranjeros con las finalidades que han quedado señaladas 
        anteriormente.
      </p>
      
      <p style={{ textAlign: "justify" }}>
        Las transferencias nacionales o internacionales de datos podrán llevarse 
        a cabo sin el consentimiento del Titular, entre otros casos establecidos por la Ley, siempre que la transferencia sea efectuada 
        a sociedades controladoras, subsidiarias o afiliadas bajo el control común del Responsable, o una sociedad matriz o a cualquier 
        sociedad del mismo grupo del Responsable que opere bajo los mismos procesos y políticas internas; sea necesaria en virtud de un 
        contrato celebrado o por celebrar en interés del Titular, por el responsable y un tercero; y cuando la transferencia sea precisa 
        para el mantenimiento o cumplimiento de una relación jurídica entre el Responsable y el Titular.
      </p>

      <li style={{ textAlign: "justify" }}>
        <b>Uso de cookies.</b>
      </li>
      
      <p style={{ textAlign: "justify" }}>
      Las cookies son fragmentos de
      información que se almacenan en el disco duro del visitante de una página Web a través de su navegador. Se utilizan para
      determinar las preferencias de los usuarios que acceden al portal electrónico (www.sanmartin.com.mx), así como para rastrear 
      determinados comportamientos o actividades.
      </p>
      
      <p style={{ textAlign: "justify" }}>
        Las cookies permiten reconocer a los usuarios, detectar el ancho de banda que han seleccionado, identificar la información 
        más destacada, calcular el tamaño de la audiencia y medir algunos parámetros de tráfico.
      </p>
      
      <p style={{ textAlign: "justify" }}>
        Es posible que usted elija que no desea almacenar una 
        cookie en su computadora configurando su navegador para que las elimine, o le avise en el momento en que se vaya a depositar un 
        cookie, o bien que las bloquee de manera automática.
      </p>
      
      <li style={{ textAlign: "justify" }}>
        <b>Aceptación.</b>
      </li>

      <p style={{ textAlign: "justify" }}>
        Si usted no manifiesta su oposición para que el tratamiento y/o transferencia de sus datos 
        personales, se entenderá que ha otorgado su consentimiento para ello.
      </p>
      
      <li style={{ textAlign: "justify" }}>
        <b>Cambios al Aviso de privacidad.</b>
      </li>
      
      <p style={{ textAlign: "justify" }}>
        El Responsable se reserva su derecho a realizar cambios en el presente aviso 
        de privacidad, los cuales serán dados a conocer a través de la página www.sanmartin.com.mx o aquella que la llegara a sustituir.
      </p>
      
      <p style={{ textAlign: "justify" }}>
        Asimismo, se informa al Titular que cuenta con el derecho a oponerse al tratamiento de sus 
        datos personales en caso de no estar de acuerdo con las modificaciones que pudiera presentar este aviso de privacidad, para ello, 
        deberá enviar una solicitud a la dirección de correo electrónico opinion@sanmartin.com.mx
      </p>
    </Box>
  );
}

export default AvisoPrivacidad;