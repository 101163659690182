import React from 'react';

// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Hidden from '@material-ui/core/Hidden';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';

// Components
import ParqueDetails from './ParqueDetails';
import CardView from '../../../components/CardView';
import TextView from '../../../components/TextView';
import { planes, parque } from '../../../ArraysDB';
import { getScreenRange } from '../../../App';

const money = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
});

const ParqueItem = ({ parqueItem, showCustomDialog }) => {
  let range = getScreenRange();
  let dialogWidth = 30;

  if (range === "xs") {
    dialogWidth = 100;
  }
  if (range === "sm") {
    dialogWidth = 65;
  }
  if (range === "md") {
    dialogWidth = 50;
  }
  if (range === "lg") {
    dialogWidth = 35;
  }

  const [minPrice, setMinPrice] = React.useState(0);
  const [maxPrice, setMaxPrice] = React.useState(0);

  React.useEffect(() => {
    let tmpMinPrice = 1000000;
    let tmpMaxPrice = 0;

    parqueItem.types.forEach(obj => {
      if (tmpMinPrice > obj.firstPayment) {
        tmpMinPrice = obj.firstPayment;
      }
      if (tmpMaxPrice < obj.firstPayment) {
        tmpMaxPrice = obj.firstPayment;
      }
    });

    setMinPrice(tmpMinPrice);
    setMaxPrice(tmpMaxPrice);
  }, []);

  const handleClickSelectPlan = event => {
    let selectIndex = localStorage.getItem(`${parqueItem.name}Index`);
    let selectedPlan = {
      name: parqueItem.types[selectIndex].name,
      price: parqueItem.types[selectIndex].price,
      firstPayment: parqueItem.types[selectIndex].firstPayment,
      logoPrimary: parqueItem.image
    };
    
    localStorage.setItem('tipoDePlan', JSON.stringify(selectedPlan));
    localStorage.removeItem(`${parqueItem.name}Index`);
    window.location.href='/comprar-plan';
  }
  const handleClickViewDetails = event => {
    showCustomDialog(
      (<ParqueDetails parqueItem={parqueItem} />), 
      `${parqueItem.name}`, 
      "ADQUIRIR",
      "CERRAR",
      handleClickSelectPlan,
      null,
      dialogWidth);
  }

  return (
    <Box mt={1} style={{ height: "100%" }}>
      <CardView
        elevation={2} 
        radius={12}
        image={parqueItem.image}
        height="100%"
      >
        <CardActionArea 
          onClick={handleClickViewDetails}
          style={{ height: "100%" }}
        >
          <Box m={2}>
            <Grid item container xs={12}
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >

              <Grid container xs={12}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item container xs={12}
                  direction="column"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <TextView variant="h6" textColor="#fff" mb={1} ml={1}>
                      {parqueItem.name}
                    </TextView>
                  </Grid>

                  <Grid item>
                    {parqueItem.types.map((option, key) => (
                      <TextView key={key} textColor="#eee"  ml={3}>
                        {`${option.name}`}
                      </TextView>
                    ))}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container xs={12}
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                style={{ 
                  marginTop: 42
                }}
              >
                <Grid item xs={12}>
                  <TextView 
                    variant="h6" 
                    textColor="#fff" 
                    align="right" 
                    style={{ 
                      position: "absolute",
                      bottom: 16, 
                      right: 16
                    }}
                  >
                    {`${money.format(minPrice)} - ${money.format(maxPrice)}`}
                  </TextView>
                </Grid>
              </Grid>

            </Grid>
          </Box>
        </CardActionArea>
      </CardView>
    </Box>
  );
}

export default ParqueItem;