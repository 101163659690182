import React from 'react';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

// Sections
import BannerSection from './sections/BannerSection';
import DescriptionSection from './sections/DescriptionSection';

// Components
import FabButton from '../../views/FabButton';
import Footer from '../../views/Footer';

const useStyles = makeStyles({
  cardRoot: {
    position: "relative",
    background: "#fff"
  },
  contentNormal: {
    width: "100vw",
    height: "100vh",
    overflow: 'auto',
    flex: "1 1 auto"
  },
  contentBottomBar: {
    width: "100vw",
    height: "94vh",
    overflow: 'auto',
    flex: "1 1 auto"
  }
});

function PlanesPage({ showCustomDialog }) {
    const classes = useStyles();
    const [backToCheckout, setBackToCheckout] = React.useState(null);

    React.useEffect(() => {
      setBackToCheckout(localStorage.getItem('backToCheckout'));
      localStorage.removeItem('backToCheckout');
    }, []);

    const handleClickBack = event => {
      window.location.href='/comprar-plan';
    }
  
    return (
      <React.Fragment>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          className={backToCheckout ? classes.contentBottomBar : classes.contentNormal}
        >
          <BannerSection/>
          <Box className={classes.cardRoot}>
            <DescriptionSection 
              showCustomDialog={showCustomDialog}/>
          </Box>
          <Footer />
        </Box>
        { backToCheckout ? 
          <Grid item container
            direction="row"
            justify="center"
            alignItems="center"
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              background: "#ffffff",
              zIndex: 28
            }}
          >
            <Box width={1} mb={2}>
              <Divider/>
            </Box>
            <Grid item container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={4}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickBack}
                >
                  Regresar
                </Button>
              </Grid>
            </Grid>
            <Box width={1} mt={2}>
              <Divider/>
            </Box>
          </Grid>
        :
          <FabButton />
        }
      </React.Fragment>
    );
  }
  
  export default PlanesPage;
  