import React from 'react';

// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";

// Components
import TextView from '../../../components/TextView';
import { getScreenRange } from '../../../App';

function HiringExampleSection() {
  const range = getScreenRange();
  const secondStep = {
    title: range !== "xs" ? "Paso 3" : "Paso 2",
    description: range !== "xs" ? 
      "Recibirá el apoyo personalizado y adecuado para realizar los trámites pertinentes." : 
      "Nuestro personal de Grupo San Martín le acompañará asesorandole sobre el proceso a llevar a cabo.",
    icon: range !== "xs" ? 
      "/image/ic_hiring_3.png" : 
      "/image/ic_hiring_2.png"
  };
  const thirdStep = {
    title: range === "xs" ? "Paso 3" : "Paso 2",
    description: range === "xs" ? 
      "Recibirá el apoyo personalizado y adecuado para realizar los trámites pertinentes." : 
      "Nuestro personal de Grupo San Martín le acompañará asesorandole sobre el proceso a llevar a cabo.",
    icon: range === "xs" ? 
      "/image/ic_hiring_3.png" : 
      "/image/ic_hiring_2.png"
  };

  return (
    <Box mb={12} mx={4}>
      <Grid container justify="center">

        <Grid item container xs={12}
          direction="column"
          justify="center"
        >
          <TextView variant="h4" align="center" mb={2} textColor="#326295" fontWeight={500}>
            ¿Necesitas un servicio?
          </TextView>
          <TextView align="center" mb={6} mx={2}>
            Recibe asistencia personalizada, resuelve tus dudas y adquiere el servicio funerario que requieras.
          </TextView>
        </Grid>

        <Grid item container xs={12} sm={6} spacing={2}
          direction="row"
        >
          <Grid item container xs={12} spacing={2}
            direction="row"
            justify="flex-end"
            alignItems="flex-start"
          >
            <Grid item container xs={11} sm={8} md={7}
              direction="column"
              justify="flex-start"
              alignItems="flex-end"
            >
              <TextView variant="h5" align="right">
                Paso 1
              </TextView>
              <TextView variant="body2" align="right">
                <p>
                  Al ocurrir un deceso, llamar a nuestra línea de atención inmediata 24/7 (667) 712 8000.
                </p>
              </TextView>
            </Grid>
            <Grid item container xs={1} sm={3} md={2}
              direction="column"
              justify="center"
              alignItems="flex-start"
            >
              <img src="/image/ic_hiring_1.png" height={32} alt="" />
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={2}
            direction="row"
            justify="flex-end"
            alignItems="flex-start"
          >
              <Grid item container xs={11} sm={8} md={7}
                direction="column"
                justify="flex-start"
                alignItems="flex-end"
              >
                <TextView variant="h5" align="right">
                  {secondStep.title}
                </TextView>
                <TextView variant="body2" align="right">
                  <p>
                    {secondStep.description}
                  </p>
                </TextView>
              </Grid>
              <Grid item container xs={1} sm={3} md={2}
                direction="column"
                justify="center"
                alignItems="flex-start"
              >
                <img src={secondStep.icon} height={32} alt="" />
              </Grid>
          </Grid>
        </Grid>

        <Hidden smUp>
          <Box my={3} width={1}></Box>
        </Hidden>

        <Grid item container xs={12} sm={6} spacing={2}
          direction="row"
        >
          
          <Grid item container xs={12}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid item container xs={1} sm={3} md={2}
              direction="column"
              justify="center"
              alignItems="flex-end"
            >
              <img src={thirdStep.icon} height={32} alt="" />
            </Grid>
            <Grid item container xs={11} sm={8} md={7}
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <TextView variant="h5" ml={3}>
                {thirdStep.title}
              </TextView>
              <TextView variant="body2" ml={3} align="left">
                <p>
                  {thirdStep.description}
                </p>
              </TextView>
            </Grid>
          </Grid>

          <Grid item container xs={12}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid item container xs={1} sm={3} md={2}
              direction="column"
              justify="center"
              alignItems="flex-end"
            >
              <img src="/image/ic_hiring_4.png" height={32} alt="" />
            </Grid>
            <Grid item container xs={11} sm={8} md={7}
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <TextView variant="h5" ml={3}>
                Paso 4
              </TextView>
              <TextView variant="body2" ml={3} align="left">
                <p>
                  Grupo San Martín se encargará de otorgarle el servicio solicitado con el respeto, calidez y profesionalismo que su familia merece.
                </p>
              </TextView>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </Box>
  );
}

export default HiringExampleSection;
