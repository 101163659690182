import React from 'react';

// @material-ui/core components
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons
import LensIcon from '@material-ui/icons/Lens';
import LensOutlinedIcon from '@material-ui/icons/LensOutlined';

function Selector({ position=0, selected=false, onClick }) {
  return (
    <IconButton color="primary" onClick={(e) => onClick(position)}>
      {selected ? 
        <LensIcon fontSize="small"/>
      :
        <LensOutlinedIcon fontSize="small"/>
      }
    </IconButton>
  );
}

function CarouselView({ 
                        delay, 
                        fadeDuration, 
                        spacingTop, 
                        spacingBottom, 
                        showSelectors, 
                        backButton,
                        nextButton, 
                        children }) {
  let carouselIndex = 0;
  const stopTransition = backButton != null && nextButton != null;
  const [showAt, setShowAt] = React.useState(carouselIndex);
  const [fade, setFade] = React.useState(true);

  const updateShowAt = (position) => {
    carouselIndex = position;
    startTransition(position);
  }

  const backPosition = e => {
    if (showAt <= 0) return;
    updateShowAt(showAt - 1);
  }

  const nextPosition = e => {
    if (showAt >= children.length - 1) return;
    updateShowAt(showAt + 1);
  }

  const startTransition = () => {
    setFade(false);
    setTimeout(() => {
      setShowAt(carouselIndex);
      setFade(true);
    }, fadeDuration);
  }

  React.useEffect(() => {
    if (delay > 0) {
      setInterval(() => {
        carouselIndex++;
        if (carouselIndex >= children.length) {
          carouselIndex = 0;
        }
        if (!stopTransition) {
          startTransition();
        }
      }, delay);  
    }
  }, []);

  return (
    <Grid row container justify="center">
      <Fade in={fade}>
        <Grid item container justify="center">
          { children[showAt] }
        </Grid>
      </Fade>

      { showSelectors ? 
        <Box mt={spacingTop} mb={spacingBottom}>
          <Grid item container justify="center">
            { 
              children.map((element, i) =>
                <Selector key={i} selected={i === showAt} position={i} onClick={updateShowAt}/>
              )
            }
          </Grid>
        </Box>
        :
        null
      }

      { backButton != null && nextButton != null ? 
        <Grid container 
          direction="row" 
          justify="center"
          spacing={8}
        >
          <Grid item>
            <Box mt={2}>
              { React.cloneElement(backButton, { onClick: backPosition }) }
            </Box>
          </Grid>
          <Grid item>
            <Box mt={2}>
              { React.cloneElement(nextButton, { onClick: nextPosition }) }
            </Box>
          </Grid>
        </Grid>
        :
        null
      }

    </Grid>
  );
}

function Carousel({ 
                    delay=5000, 
                    fadeDuration=300, 
                    spacingTop=0, 
                    spacingBottom=0, 
                    showSelectors=true, 
                    backButton, 
                    nextButton, 
                    children}) {
  return (
    <Grid row container justify="center">
      { children.length > 1 ?
        <CarouselView 
          delay={delay} 
          fadeDuration={fadeDuration}
          spacingTop={spacingTop}
          spacingBottom={spacingBottom}
          showSelectors={showSelectors}
          backButton={backButton}
          nextButton={nextButton}
        >
          {children}
        </CarouselView>
      : 
        children
      }
    </Grid>
  );
}

export default Carousel;