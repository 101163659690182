import React, { useState } from 'react';

import { Container, Typography, Box, Button } from '@material-ui/core';
import {  HorariosPagoEnLinea, ValidaHorarioPago } from "../../../Api";
import BuildIcon from '@material-ui/icons/Build';


function FueraDeHorarioPage() {

    const [infoHorario, setInfoHorario] = React.useState({});

    React.useEffect(() => {

        const element = document.getElementById('Menu');
        if (element) {
            element.style.display = 'none';
        }

        // Ejecutar obtenerObituarios cada minuto
        const interval = setInterval(() => {
            ValidaHorarioPago().then(data => {
                if(data.d.Data==1){
                    window.location.href = "/"
                }
            });
        }, 5000); // 60000ms = 1 minuto

        HorariosPagoEnLinea().then(response => {
            setInfoHorario(response.d.Data)
        });
        // Limpiar el intervalo al desmontar el componente
        return () => clearInterval(interval);
    }, [])
    return (
        <Container style={{ textAlign: 'center', marginTop: '50px' }}>
            <br />
            <br />

            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <br />
                <img src="image/Clock.png" alt="Icono de mantenimiento" style={{ width: '240px', marginBottom: '20px' }} />
                <Typography variant="h4" style={{ marginTop: '20px', marginBottom: '20px' }}>
                    Página en fuera de horario de pago
                </Typography>
                <Typography variant="body1" style={{ marginBottom: '20px' }}>
                    Nuestro sistema de pagos en línea no está disponible en este momento, ya que se encuentra fuera del horario de operación.
                    <br /><br />
                    <b>Horarios fuera de servicio:</b>
                    <br />
                    De {infoHorario.hr_inicial} a {infoHorario.hr_final}

                </Typography>
            </Box>
        </Container>
    );
}

export default FueraDeHorarioPage;