// ROLES FAMILIARES
export const roles = [
  {
    id: 1,
    name: "Papá"
  },
  {
    id: 2,
    name: "Mamá"
  },
  {
    id: 3,
    name: "Abuelo/a"
  },
  {
    id: 4,
    name: "Hijo/a"
  },
  {
    id: 5,
    name: "Nieto/a"
  },
  {
    id: 6,
    name: "Otro"
  }
];

// TIPO DE ESQUELAS
export const esquelas = [
  {
    id: 0,
    name: "Ninguno"
  },
  {
    id: 1,
    name: "Digital"
  },
  {
    id: 2,
    name: "Periódico"
  },
  {
    id: 3,
    name: "Ambas"
  }
];

// CIUDADES
export const cities = [
  {
    id: 1,
    name: "Culiacán"
  },
  {
    id: 2,
    name: "Los Mochis"
  },
  {
    id: 3,
    name: "Guasave"
  },
  {
    id: 4,
    name: "Navolato"
  }
];

// LUGARES
export const places = [
  {
    id: 0,
    name: ""
  },
  {
    id: 1,
    name: "Domicilio"
  },
  {
    id: 2,
    name: "Funeraria"
  },
  {
    id: 3,
    name: "Montebello"
  },
  {
    id: 4,
    name: "Zapata"
  }
];

// CANTIDAD DE PERSONAS
export const people = [
  {
    id: 0,
    name: ""
  },
  {
    id: 1,
    name: "50"
  },
  {
    id: 2,
    name: "51 - 100"
  },
  {
    id: 3,
    name: "101 - 150"
  }
];

// ATAUDES
export const ataudes = [
  {
    id: 1,
    name: "Madera básico"
  },
  {
    id: 2,
    name: "Madera"
  },
  {
    id: 3,
    name: "Metálico básico"
  },
  {
    id: 4,
    name: "Metálico"
  },
  {
    id: 5,
    name: "Metálico con herrajes"
  }
];

// AUTOS
export const autos = [
  {
    id: 1,
    name: "Cadillac Escalade"
  },
  {
    id: 2,
    name: "Cadillac ATS"
  },
  {
    id: 3,
    name: "Cadillac CTS"
  },
  {
    id: 4,
    name: "Chrysler 300"
  },
  {
    id: 5,
    name: "Chrysler 200"
  },
  {
    id: 6,
    name: "Dodge Charger"
  },
  {
    id: 7,
    name: "Ford Expedition"
  },
  {
    id: 8,
    name: "Lincoln Navigator"
  },
  {
    id: 9,
    name: "Lincoln Mark LT"
  },
  {
    id: 10,
    name: "Nissan Altima"
  },
  {
    id: 11,
    name: "Nissan Sentra"
  }
];

export const peoplePlan = [
  // 50
  {
    peopleId: 1,
    planId: 6
  },
  {
    peopleId: 1,
    planId: 7
  },
  {
    peopleId: 1,
    planId: 8
  },
  // 51 - 100
  {
    peopleId: 2,
    planId: 3
  },
  {
    peopleId: 2,
    planId: 4
  },
  {
    peopleId: 2,
    planId: 5
  },
  // 101 - 150
  {
    peopleId: 3,
    planId: 1
  },
  {
    peopleId: 3,
    planId: 2
  },
  // Domicilio
  {
    peopleId: 0,
    planId: 9
  }
];

export const planAtaud = [
  {
    planId: 1,
    ataudId: 4
  },
  {
    planId: 2,
    ataudId: 2
  },
  {
    planId: 3,
    ataudId: 2
  },
  {
    planId: 3,
    ataudId: 4
  },
  {
    planId: 4,
    ataudId: 2
  },
  {
    planId: 4,
    ataudId: 4
  },
  {
    planId: 5,
    ataudId: 4
  },
  {
    planId: 5,
    ataudId: 1
  },
  {
    planId: 6,
    ataudId: 4
  },
  {
    planId: 7,
    ataudId: 3
  },
  {
    planId: 8,
    ataudId: 5
  },
  {
    planId: 9,
    ataudId: 3
  }
];

// PLANES
export const planes = [
  {
    id: 1,
    name: "REVELACIÓN",
    alternativeName: "",
    logo: "/image/planes/ic_revelacion_white.png",
    logoPrimary: "/image/planes/ic_revelacion_primary.png",
    urna: true,
    cremacion: true,
    precioDeLista: 92528,
    description: [
      "Transporte del finado (de hospital o domicilio local a funeraria).",
      "Asesoría de trámites (no incluye costos).",
      "Embalsamamiento (preparación del finado y arreglo estético).",
      "Servicio de Cafetería (café, vasos térmicos, azúcar, crema y revolvedores).",
      "Servicio de Carroza Luxor (Traslado del domicilio - iglesia - panteón local).",
      "Servicio de agua embotellada (para sepelio).",
      "Ataúd de metal o madera.",
      "Servicio de cremación (opcional).",
      "Urna de madera clásica o a elegir (sujeto a disponibilidad)."
    ],
    summary: [
      "Ataúd: Metal o madera",
      "Carroza: Luxor",
      "Misa solemne: Opcional",
      "Cremación: Opcional",
      "Urna: Madera o a elegir"
    ],
    moreInformation: true,
    descripciones: [
      "",
      "",
      ""
    ],
    pagosConAnticipo: [
      {
        plazo: 12,
        mensual: {
          primerPago: 13880,
          pago: 7341,
          total: 88092
        },
        quincenal: {
          primerPago: 13880,
          pago: 3671,
          total: 88104
        }
      },
      {
        plazo: 18,
        mensual: {
          primerPago: 13880,
          pago: 5156,
          total: 92808
        },
        quincenal: {
          primerPago: 13880,
          pago: 2578,
          total: 92808
        }
      },
      {
        plazo: 24,
        mensual: {
          primerPago: 13880,
          pago: 4064,
          total: 97536
        },
        quincenal: {
          primerPago: 13880,
          pago: 2032,
          total: 97536
        }
      },
      {
        plazo: 30,
        mensual: {
          primerPago: 13880,
          pago: 3409,
          total: 102270
        },
        quincenal: {
          primerPago: 13880,
          pago: 1705,
          total: 102300
        }
      },
      {
        plazo: 36,
        mensual: {
          primerPago: 13880,
          pago: 2972,
          total: 106992
        },
        quincenal: {
          primerPago: 13880,
          pago: 1486,
          total: 106992
        }
      },
      {
        plazo: 48,
        mensual: {
          primerPago: 13880,
          pago: 2425,
          total: 116400
        },
        quincenal: {
          primerPago: 13880,
          pago: 1213,
          total: 116448
        }
      },
      {
        plazo: 54,
        mensual: {
          primerPago: 13880,
          pago: 2243,
          total: 121122
        },
        quincenal: {
          primerPago: 13880,
          pago: 1122,
          total: 121176
        }
      },
      {
        plazo: 60,
        mensual: {
          primerPago: 13880,
          pago: 2098,
          total: 125880
        },
        quincenal: {
          primerPago: 13880,
          pago: 1049,
          total: 125880
        }
      }
    ],
    pagosSinAnticipo: [
      {
        plazo: 12,
        mensual: {
          primerPago: 8636,
          pago: 8636,
          total: 103632
        },
        quincenal: {
          primerPago: 8636,
          pago: 4318,
          total: 103632
        }
      },
      {
        plazo: 18,
        mensual: {
          primerPago: 6066,
          pago: 6066,
          total: 109188
        },
        quincenal: {
          primerPago: 6066,
          pago: 3033,
          total: 109188
        }
      },
      {
        plazo: 24,
        mensual: {
          primerPago: 4781,
          pago: 4781,
          total: 114744
        },
        quincenal: {
          primerPago: 4782,
          pago: 2391,
          total: 114768
        }
      },
      {
        plazo: 30,
        mensual: {
          primerPago: 4010,
          pago: 4010,
          total: 120300
        },
        quincenal: {
          primerPago: 4010,
          pago: 2005,
          total: 120300
        }
      },
      {
        plazo: 36,
        mensual: {
          primerPago: 3496,
          pago: 3496,
          total: 125856
        },
        quincenal: {
          primerPago: 3496,
          pago: 1748,
          total: 125856
        }
      },
      {
        plazo: 48,
        mensual: {
          primerPago: 2853,
          pago: 2853,
          total: 136944
        },
        quincenal: {
          primerPago: 2854,
          pago: 1427,
          total: 136992
        }
      },
      {
        plazo: 54,
        mensual: {
          primerPago: 2639,
          pago: 2639,
          total: 142506
        },
        quincenal: {
          primerPago: 2640,
          pago: 1320,
          total: 142560
        }
      },
      {
        plazo: 60,
        mensual: {
          primerPago: 2468,
          pago: 2468,
          total: 148080
        },
        quincenal: {
          primerPago: 2468,
          pago: 1234,
          total: 148080
        }
      }
    ]
  },
  {
    id: 2,
    name: "MEDITERRÁNEO",
    alternativeName: "",
    logo: "/image/planes/ic_mediterraneo_white.png",
    logoPrimary: "/image/planes/ic_mediterraneo_primary.png",
    urna: true,
    cremacion: true,
    precioDeLista: 78657,
    description: [
      "Transporte del finado (de hospital o domicilio local a funeraria).",
      "Asesoría de trámites (no incluye costos).",
      "Embalsamamiento (preparación del finado y arreglo estético).",
      "Servicio de Cafetería (café, vasos térmicos, azúcar, crema y revolvedores).",
      "Servicio de Carroza Luxor (Traslado del domicilio - iglesia - panteón local).",
      "Servicio de agua embotellada (para sepelio).",
      "Ataúd de madera.",
      "Servicio de cremación (opcional).",
      "Urna de madera clásica o a elegir (sujeto a disponibilidad)."
    ],
    summary: [
      "Ataúd: Madera",
      "Carroza: Luxor",
      "Misa solemne: Opcional",
      "Cremación: Opcional",
      "Urna: Madera o a elegir"
    ],
    moreInformation: true,
    descripciones: [
      "",
      "",
      ""
    ],
    pagosConAnticipo: [
      {
        plazo: 12,
        mensual: {
          primerPago: 11800,
          pago: 6240,
          total: 74880
        },
        quincenal: {
          primerPago: 11800,
          pago: 3120,
          total: 74880
        }
      },
      {
        plazo: 18,
        mensual: {
          primerPago: 11800,
          pago: 4383,
          total: 78894
        },
        quincenal: {
          primerPago: 11800,
          pago: 2192,
          total: 78912
        }
      },
      {
        plazo: 24,
        mensual: {
          primerPago: 11800,
          pago: 3455,
          total: 82920
        },
        quincenal: {
          primerPago: 11800,
          pago: 1728,
          total: 82944
        }
      },
      {
        plazo: 30,
        mensual: {
          primerPago: 11800,
          pago: 2898,
          total: 86940
        },
        quincenal: {
          primerPago: 11800,
          pago: 1449,
          total: 86940
        }
      },
      {
        plazo: 36,
        mensual: {
          primerPago: 11800,
          pago: 2526,
          total: 90936
        },
        quincenal: {
          primerPago: 11800,
          pago: 1263,
          total: 90936
        }
      },
      {
        plazo: 48,
        mensual: {
          primerPago: 11800,
          pago: 2062,
          total: 98976
        },
        quincenal: {
          primerPago: 11800,
          pago: 1031,
          total: 98976
        }
      },
      {
        plazo: 54,
        mensual: {
          primerPago: 11800,
          pago: 1907,
          total: 102978
        },
        quincenal: {
          primerPago: 11800,
          pago: 954,
          total: 103032
        }
      },
      {
        plazo: 60,
        mensual: {
          primerPago: 11800,
          pago: 1783,
          total: 106980
        },
        quincenal: {
          primerPago: 11800,
          pago: 892,
          total: 107040
        }
      }
    ],
    pagosSinAnticipo: [
      {
        plazo: 12,
        mensual: {
          primerPago: 7342,
          pago: 7342,
          total: 88104
        },
        quincenal: {
          primerPago: 7342,
          pago: 3671,
          total: 88104
        }
      },
      {
        plazo: 18,
        mensual: {
          primerPago: 5157,
          pago: 5157,
          total: 92826
        },
        quincenal: {
          primerPago: 5158,
          pago: 2579,
          total: 92844
        }
      },
      {
        plazo: 24,
        mensual: {
          primerPago: 4064,
          pago: 4064,
          total: 97536
        },
        quincenal: {
          primerPago: 4064,
          pago: 2032,
          total: 97536
        }
      },
      {
        plazo: 30,
        mensual: {
          primerPago: 3409,
          pago: 3409,
          total: 102270
        },
        quincenal: {
          primerPago: 3410,
          pago: 1705,
          total: 102300
        }
      },
      {
        plazo: 36,
        mensual: {
          primerPago: 2972,
          pago: 2972,
          total: 106992
        },
        quincenal: {
          primerPago: 2972,
          pago: 1486,
          total: 106992
        }
      },
      {
        plazo: 48,
        mensual: {
          primerPago: 2426,
          pago: 2426,
          total: 116448
        },
        quincenal: {
          primerPago: 2426,
          pago: 1213,
          total: 116448
        }
      },
      {
        plazo: 54,
        mensual: {
          primerPago: 2244,
          pago: 2244,
          total: 121176
        },
        quincenal: {
          primerPago: 2244,
          pago: 1122,
          total: 121176
        }
      },
      {
        plazo: 60,
        mensual: {
          primerPago: 2098,
          pago: 2098,
          total: 125880
        },
        quincenal: {
          primerPago: 2098,
          pago: 1049,
          total: 125880
        }
      }
    ]
  },
  {
    id: 3,
    name: "TEMPUS",
    alternativeName: "",
    logo: "/image/planes/ic_tempus_white.png",
    logoPrimary: "/image/planes/ic_tempus_primary.png",
    urna: true,
    cremacion: true,
    precioDeLista: 49770,
    description: [
      "Transporte del finado (de hospital o domicilio local a funeraria).",
      "Asesoría de trámites (no incluye costos).",
      "Embalsamamiento (preparación del finado y arreglo estético).",
      "Servicio de Cafetería (café, vasos térmicos, azúcar, crema y revolvedores).",
      "Servicio de Carroza Luxor (Traslado del domicilio - iglesia - panteón local).",
      "Servicio de agua embotellada (para sepelio).",
      "Ataúd de madera.",
      "Servicio de cremación (opcional).",
      "Urna de madera clásica (opcional)."
    ],
    summary: [
      "Ataúd: Madera",
      "Carroza: Luxor",
      "Misa solemne: Opcional",
      "Cremación: Opcional",
      "Urna: Madera clásica"
    ],
    moreInformation: true,
    descripciones: [
      "",
      "",
      ""
    ],
    pagosConAnticipo: [
      {
        plazo: 12,
        mensual: {
          primerPago: 7468,
          pago: 3949,
          total: 47388
        },
        quincenal: {
          primerPago: 7468,
          pago: 1975,
          total: 47400
        }
      },
      {
        plazo: 18,
        mensual: {
          primerPago: 7468,
          pago: 2774,
          total: 49932
        },
        quincenal: {
          primerPago: 7468,
          pago: 1387,
          total: 49932
        }
      },
      {
        plazo: 24,
        mensual: {
          primerPago: 7468,
          pago: 2186,
          total: 52464
        },
        quincenal: {
          primerPago: 7468,
          pago: 1093,
          total: 52464
        }
      },
      {
        plazo: 30,
        mensual: {
          primerPago: 7468,
          pago: 1834,
          total: 55020
        },
        quincenal: {
          primerPago: 7468,
          pago: 917,
          total: 55020
        }
      },
      {
        plazo: 36,
        mensual: {
          primerPago: 7468,
          pago: 1599,
          total: 57564
        },
        quincenal: {
          primerPago: 7468,
          pago: 800,
          total: 57600
        }
      },
      {
        plazo: 48,
        mensual: {
          primerPago: 7468,
          pago: 1305,
          total: 62640
        },
        quincenal: {
          primerPago: 7468,
          pago: 653,
          total: 62688
        }
      },
      {
        plazo: 54,
        mensual: {
          primerPago: 7468,
          pago: 1207,
          total: 65178
        },
        quincenal: {
          primerPago: 7468,
          pago: 604,
          total: 65232
        }
      },
      {
        plazo: 60,
        mensual: {
          primerPago: 7468,
          pago: 1129,
          total: 67740
        },
        quincenal: {
          primerPago: 7468,
          pago: 565,
          total: 67800
        }
      }
    ],
    pagosSinAnticipo: [
      {
        plazo: 12,
        mensual: {
          primerPago: 4646,
          pago: 4646,
          total: 55752
        },
        quincenal: {
          primerPago: 4646,
          pago: 2323,
          total: 55752
        }
      },
      {
        plazo: 18,
        mensual: {
          primerPago: 3263,
          pago: 3263,
          total: 58734
        },
        quincenal: {
          primerPago: 3264,
          pago: 1632,
          total: 58752
        }
      },
      {
        plazo: 24,
        mensual: {
          primerPago: 2572,
          pago: 2572,
          total: 61728
        },
        quincenal: {
          primerPago: 2572,
          pago: 1286,
          total: 61728
        }
      },
      {
        plazo: 30,
        mensual: {
          primerPago: 2157,
          pago: 2157,
          total: 64710
        },
        quincenal: {
          primerPago: 2158,
          pago: 1079,
          total: 64740
        }
      },
      {
        plazo: 36,
        mensual: {
          primerPago: 1881,
          pago: 1881,
          total: 67716
        },
        quincenal: {
          primerPago: 1882,
          pago: 941,
          total: 67752
        }
      },
      {
        plazo: 48,
        mensual: {
          primerPago: 1535,
          pago: 1535,
          total: 73680
        },
        quincenal: {
          primerPago: 1536,
          pago: 768,
          total: 73728
        }
      },
      {
        plazo: 54,
        mensual: {
          primerPago: 1420,
          pago: 1420,
          total: 76680
        },
        quincenal: {
          primerPago: 1420,
          pago: 710,
          total: 76680
        }
      },
      {
        plazo: 60,
        mensual: {
          primerPago: 1328,
          pago: 1328,
          total: 79680
        },
        quincenal: {
          primerPago: 1328,
          pago: 664,
          total: 79680
        }
      }
    ]
  },
  {
    id: 4,
    name: "IMPERIAL",
    alternativeName: "",
    logo: "/image/planes/ic_imperial_white.png",
    logoPrimary: "/image/planes/ic_imperial_primary.png",
    urna: true,
    cremacion: true,
    precioDeLista: 33530,
    description: [
      "Transporte del finado (de hospital o domicilio local a funeraria).",
      "Asesoría de trámites (no incluye costos).",
      "Embalsamamiento (preparación del finado y arreglo estético).",
      "Sala de velación (24 hrs, áreas refigeradas, antesala, sala de velación y privado).",
      "Servicio de Cafetería (café, vasos térmicos, azúcar, crema y revolvedores).",
      "Servicio de Carroza (Traslado del domicilio - iglesia - panteón local).",
      "Servicio de agua embotellada (para sepelio).",
      "Ataúd de metal o madera.",
      "Servicio de cremación (opcional).",
      "Urna de madera clásica (opcional)."
    ],
    summary: [
      "Ataúd: Metal o madera",
      "Carroza: Medio",
      "Misa solemne: Opcional",
      "Cremación: Opcional",
      "Urna: Madera clásica"
    ],
    moreInformation: true,
    descripciones: [
      "",
      "",
      ""
    ],
    pagosConAnticipo: [
      {
        plazo: 12,
        mensual: {
          primerPago: 5032,
          pago: 2660,
          total: 31920
        },
        quincenal: {
          primerPago: 5032,
          pago: 1330,
          total: 31920
        }
      },
      {
        plazo: 18,
        mensual: {
          primerPago: 5032,
          pago: 1869,
          total: 33642
        },
        quincenal: {
          primerPago: 5032,
          pago: 935,
          total: 33660
        }
      },
      {
        plazo: 24,
        mensual: {
          primerPago: 5032,
          pago: 1473,
          total: 35352
        },
        quincenal: {
          primerPago: 5032,
          pago: 737,
          total: 35376
        }
      },
      {
        plazo: 30,
        mensual: {
          primerPago: 5032,
          pago: 1235,
          total: 37050
        },
        quincenal: {
          primerPago: 5032,
          pago: 618,
          total: 37080
        }
      },
      {
        plazo: 36,
        mensual: {
          primerPago: 5032,
          pago: 1077,
          total: 38772
        },
        quincenal: {
          primerPago: 5032,
          pago: 539,
          total: 38808
        }
      },
      {
        plazo: 48,
        mensual: {
          primerPago: 5032,
          pago: 879,
          total: 42192
        },
        quincenal: {
          primerPago: 5032,
          pago: 440,
          total: 42240
        }
      },
      {
        plazo: 54,
        mensual: {
          primerPago: 5032,
          pago: 813,
          total: 43902
        },
        quincenal: {
          primerPago: 5032,
          pago: 407,
          total: 43956
        }
      },
      {
        plazo: 60,
        mensual: {
          primerPago: 5032,
          pago: 760,
          total: 45600
        },
        quincenal: {
          primerPago: 5032,
          pago: 380,
          total: 45600
        }
      }
    ],
    pagosSinAnticipo: [
      {
        plazo: 12,
        mensual: {
          primerPago: 3130,
          pago: 3130,
          total: 37560
        },
        quincenal: {
          primerPago: 3130,
          pago: 1565,
          total: 37560
        }
      },
      {
        plazo: 18,
        mensual: {
          primerPago: 2199,
          pago: 2199,
          total: 39582
        },
        quincenal: {
          primerPago: 2200,
          pago: 1100,
          total: 39600
        }
      },
      {
        plazo: 24,
        mensual: {
          primerPago: 1733,
          pago: 1733,
          total: 41592
        },
        quincenal: {
          primerPago: 1734,
          pago: 867,
          total: 41616
        }
      },
      {
        plazo: 30,
        mensual: {
          primerPago: 1453,
          pago: 1453,
          total: 43590
        },
        quincenal: {
          primerPago: 1454,
          pago: 727,
          total: 43620
        }
      },
      {
        plazo: 36,
        mensual: {
          primerPago: 1267,
          pago: 1267,
          total: 45612
        },
        quincenal: {
          primerPago: 1268,
          pago: 634,
          total: 45648
        }
      },
      {
        plazo: 48,
        mensual: {
          primerPago: 1034,
          pago: 1034,
          total: 49632
        },
        quincenal: {
          primerPago: 1034,
          pago: 517,
          total: 49632
        }
      },
      {
        plazo: 54,
        mensual: {
          primerPago: 957,
          pago: 957,
          total: 51678
        },
        quincenal: {
          primerPago: 958,
          pago: 479,
          total: 51732
        }
      },
      {
        plazo: 60,
        mensual: {
          primerPago: 895,
          pago: 895,
          total: 53700
        },
        quincenal: {
          primerPago: 896,
          pago: 448,
          total: 53760
        }
      }
    ]
  },
  {
    id: 5,
    name: "PARAÍSO",
    alternativeName: "TRANQUILIDAD",
    logo: "/image/planes/ic_paraiso_white.png",
    logoPrimary: "/image/planes/ic_paraiso_primary.png",
    urna: true,
    cremacion: true,
    description: [
      "Transporte del finado (de hospital o domicilio local a funeraria).",
      "Asesoría de trámites (no incluye costos).",
      "Embalsamamiento (preparación del finado y arreglo estético).",
      "Servicio de Cafetería (café, vasos térmicos, azúcar, crema y revolvedores).",
      "Servicio de Carroza (Traslado del domicilio - iglesia - panteón local).",
      "Servicio de agua embotellada (para sepelio).",
      "Ataúd metálico básico (color a elegir).",
      "Servicio de cremación (opcional).",
      "Urna de madera clásica (opcional)."
    ],
    summary: [
      "Ataúd: Metálico",
      "Carroza",
      "Misa solemne: Opcional",
      "Cremación: Opcional",
      "Urna: Madera clásica"
    ],
    moreInformation: true,
    descripciones: [
      "En la vida hay momentos que nos definen como adultos responsables: estudiar una carrera, comprar un auto, tener una casa, entre otros.",
      "Cada uno de estos logros nos van asegurando un mejor futuro, a nosotros y a nuestra familia. Hoy más que nunca es momento de ver por ellos y pensar más allá.",
      "Asegura la tranquilidad de tu familia con un plan de previsión:"
    ],
    precioDeLista: 28500,
    precioDescuento: 27075,
    porcentajeDescuento: 5,
    ahorroPorDescuento: 1425,
    anticipo: 4064,
    pagosConAnticipo: [
      {
        plazo: 12,
        mensual: {
          primerPago: 4064,
          pago: 2148,
          total: 25776
        },
        quincenal: {
          primerPago: 4064,
          pago: 1074,
          total: 25776
        }
      },
      {
        plazo: 18,
        mensual: {
          primerPago: 4064,
          pago: 1509,
          total: 27162
        },
        quincenal: {
          primerPago: 4064,
          pago: 755,
          total: 27180
        }
      },
      {
        plazo: 24,
        mensual: {
          primerPago: 4064,
          pago: 1189,
          total: 28536
        },
        quincenal: {
          primerPago: 4064,
          pago: 595,
          total: 28560
        }
      },
      {
        plazo: 30,
        mensual: {
          primerPago: 4064,
          pago: 998,
          total: 29940
        },
        quincenal: {
          primerPago: 4064,
          pago: 499,
          total: 29940
        }
      },
      {
        plazo: 36,
        mensual: {
          primerPago: 4064,
          pago: 870,
          total: 31320
        },
        quincenal: {
          primerPago: 4064,
          pago: 435,
          total: 31320
        }
      },
      {
        plazo: 48,
        mensual: {
          primerPago: 4064,
          pago: 710,
          total: 34080
        },
        quincenal: {
          primerPago: 4064,
          pago: 355,
          total: 34080
        }
      },
      {
        plazo: 54,
        mensual: {
          primerPago: 4064,
          pago: 614,
          total: 36840
        },
        quincenal: {
          primerPago: 4064,
          pago: 329,
          total: 35532
        }
      },
      {
        plazo: 60,
        mensual: {
          primerPago: 4064,
          pago: 614,
          total: 36840
        },
        quincenal: {
          primerPago: 4064,
          pago: 307,
          total: 36840
        }
      }
    ],
    pagosSinAnticipo: [
      {
        plazo: 12,
        mensual: {
          primerPago: 2527,
          pago: 2527,
          total: 30324
        },
        quincenal: {
          primerPago: 1264,
          pago: 1264,
          total: 30336
        }
      },
      {
        plazo: 18,
        mensual: {
          primerPago: 1775,
          pago: 1775,
          total: 13950
        },
        quincenal: {
          primerPago: 888,
          pago: 888,
          total: 31968
        }
      },
      {
        plazo: 24,
        mensual: {
          primerPago: 1399,
          pago: 1399,
          total: 33576
        },
        quincenal: {
          primerPago: 700,
          pago: 700,
          total: 33600
        }
      },
      {
        plazo: 30,
        mensual: {
          primerPago: 1174,
          pago: 1174,
          total: 35220
        },
        quincenal: {
          primerPago: 587,
          pago: 587,
          total: 35220
        }
      },
      {
        plazo: 36,
        mensual: {
          primerPago: 1023,
          pago: 1023,
          total: 36828
        },
        quincenal: {
          primerPago: 512,
          pago: 512,
          total: 36864
        }
      },
      {
        plazo: 48,
        mensual: {
          primerPago: 835,
          pago: 835,
          total: 40080
        },
        quincenal: {
          primerPago: 418,
          pago: 418,
          total: 40128
        }
      },
      {
        plazo: 54,
        mensual: {
          primerPago: 773,
          pago: 773,
          total: 41742
        },
        quincenal: {
          primerPago: 387,
          pago: 387,
          total: 41796
        }
      },
      {
        plazo: 60,
        mensual: {
          primerPago: 722,
          pago: 722,
          total: 43320
        },
        quincenal: {
          primerPago: 361,
          pago: 361,
          total: 43320
        }
      }
    ]
  },
  {
    id: 6,
    name: "TRADICIÓN",
    alternativeName: "",
    logo: "/image/planes/ic_tradicion_white.png",
    logoPrimary: "/image/planes/ic_tradicion_primary.png",
    urna: false,
    cremacion: false,
    precioDeLista: 21000,
    description: [
      "Transporte del finado (de hospital o domicilio local a funeraria).",
      "Asesoría de trámites (no incluye costos).",
      "Embalsamamiento (preparación del finado y arreglo estético).",
      "Sala de velación (24 hrs, áreas refigeradas, antesala, sala de velación y privado).",
      "Servicio de Cafetería (café, vasos térmicos, azúcar, crema y revolvedores).",
      "Servicio de Carroza (Traslado del domicilio - iglesia - panteón local).",
      "Servicio de agua embotellada (para sepelio).",
      "Ataúd metálico básico (color a elegir)."
    ],
    summary: [
      "Ataúd: Metálico",
      "Carroza",
      "Misa solemne: Opcional",
      "Agua embotellada (opcional)",
      "Transporte del finado"
    ],
    moreInformation: false,
    descripciones: [
      "",
      "",
      ""
    ],
    pagosConAnticipo: [
      {
        plazo: 12,
        mensual: {
          primerPago: 3152,
          pago: 1666,
          total: 19992
        },
        quincenal: {
          primerPago: 3152,
          pago: 833,
          total: 19992
        }
      },
      {
        plazo: 18,
        mensual: {
          primerPago: 3152,
          pago: 1171,
          total: 21078
        },
        quincenal: {
          primerPago: 3152,
          pago: 586,
          total: 21096
        }
      },
      {
        plazo: 24,
        mensual: {
          primerPago: 3152,
          pago: 923,
          total: 22152
        },
        quincenal: {
          primerPago: 3152,
          pago: 462,
          total: 22176
        }
      },
      {
        plazo: 30,
        mensual: {
          primerPago: 3152,
          pago: 774,
          total: 23220
        },
        quincenal: {
          primerPago: 3152,
          pago: 387,
          total: 23220
        }
      },
      {
        plazo: 36,
        mensual: {
          primerPago: 3152,
          pago: 675,
          total: 24300
        },
        quincenal: {
          primerPago: 3152,
          pago: 338,
          total: 24336
        }
      },
      {
        plazo: 48,
        mensual: {
          primerPago: 3152,
          pago: 551,
          total: 26448
        },
        quincenal: {
          primerPago: 3152,
          pago: 276,
          total: 26496
        }
      },
      {
        plazo: 54,
        mensual: {
          primerPago: 3152,
          pago: 509,
          total: 27486
        },
        quincenal: {
          primerPago: 3152,
          pago: 255,
          total: 27540
        }
      },
      {
        plazo: 60,
        mensual: {
          primerPago: 3152,
          pago: 476,
          total: 28560
        },
        quincenal: {
          primerPago: 3152,
          pago: 238,
          total: 28560
        }
      }
    ],
    pagosSinAnticipo: [
      {
        plazo: 12,
        mensual: {
          primerPago: 1960,
          pago: 1960,
          total: 23520
        },
        quincenal: {
          primerPago: 1960,
          pago: 980,
          total: 23520
        }
      },
      {
        plazo: 18,
        mensual: {
          primerPago: 1377,
          pago: 1377,
          total: 24786
        },
        quincenal: {
          primerPago: 1378,
          pago: 689,
          total: 24804
        }
      },
      {
        plazo: 24,
        mensual: {
          primerPago: 1085,
          pago: 1085,
          total: 26040
        },
        quincenal: {
          primerPago: 1086,
          pago: 543,
          total: 26064
        }
      },
      {
        plazo: 30,
        mensual: {
          primerPago: 910,
          pago: 910,
          total: 27300
        },
        quincenal: {
          primerPago: 910,
          pago: 455,
          total: 27300
        }
      },
      {
        plazo: 36,
        mensual: {
          primerPago: 794,
          pago: 794,
          total: 28584
        },
        quincenal: {
          primerPago: 794,
          pago: 397,
          total: 28584
        }
      },
      {
        plazo: 48,
        mensual: {
          primerPago: 648,
          pago: 648,
          total: 31104
        },
        quincenal: {
          primerPago: 648,
          pago: 324,
          total: 31104
        }
      },
      {
        plazo: 54,
        mensual: {
          primerPago: 599,
          pago: 599,
          total: 32346
        },
        quincenal: {
          primerPago: 600,
          pago: 300,
          total: 32400
        }
      },
      {
        plazo: 60,
        mensual: {
          primerPago: 560,
          pago: 560,
          total: 33600
        },
        quincenal: {
          primerPago: 560,
          pago: 280,
          total: 33600
        }
      }
    ]
  },
  {
    id: 7,
    name: "SET FUNERARIA",
    alternativeName: "",
    logo: "/image/planes/ic_set_funeraria_white.png",
    logoPrimary: "/image/planes/ic_set_funeraria_primary.png",
    urna: false,
    cremacion: false,
    precioDeLista: 17890,
    description: [
      "Transporte del finado (de hospital o domicilio local a funeraria).",
      "Asesoría de trámites (no incluye costos).",
      "Embalsamamiento (preparación del finado y arreglo estético).",
      "Sala de velación (24 hrs, áreas refigeradas, antesala, sala de velación y privado).",
      "Servicio de Cafetería (café, vasos térmicos, azúcar, crema y revolvedores).",
      "Servicio de Carroza (Traslado del domicilio - iglesia - panteón local).",
      "Servicio de agua embotellada (para sepelio).",
      "Ataúd metálico básico (color único)."
    ],
    summary: [
      "Ataúd: Metálico básico",
      "Carroza",
      "Misa solemne: Opcional",
      "Agua embotellada (opcional)",
      "Transporte del finado"
    ],
    moreInformation: false,
    descripciones: [
      "",
      "",
      ""
    ],
    pagosConAnticipo: [
      {
        plazo: 12,
        mensual: {
          primerPago: 2684,
          pago: 1420,
          total: 17040
        },
        quincenal: {
          primerPago: 2684,
          pago: 710,
          total: 17040
        }
      },
      {
        plazo: 18,
        mensual: {
          primerPago: 2684,
          pago: 997,
          total: 17946
        },
        quincenal: {
          primerPago: 2684,
          pago: 499,
          total: 17964
        }
      },
      {
        plazo: 24,
        mensual: {
          primerPago: 2684,
          pago: 786,
          total: 18864
        },
        quincenal: {
          primerPago: 2684,
          pago: 393,
          total: 18864
        }
      },
      {
        plazo: 30,
        mensual: {
          primerPago: 2684,
          pago: 659,
          total: 19770
        },
        quincenal: {
          primerPago: 2684,
          pago: 330,
          total: 19800
        }
      },
      {
        plazo: 36,
        mensual: {
          primerPago: 2684,
          pago: 575,
          total: 20700
        },
        quincenal: {
          primerPago: 2684,
          pago: 288,
          total: 20736
        }
      },
      {
        plazo: 48,
        mensual: {
          primerPago: 2684,
          pago: 469,
          total: 22512
        },
        quincenal: {
          primerPago: 2684,
          pago: 235,
          total: 22560
        }
      }
    ],
    pagosSinAnticipo: [
      {
        plazo: 12,
        mensual: {
          primerPago: 1670,
          pago: 1670,
          total: 20040
        },
        quincenal: {
          primerPago: 1670,
          pago: 835,
          total: 20040
        }
      },
      {
        plazo: 18,
        mensual: {
          primerPago: 1173,
          pago: 1173,
          total: 21114
        },
        quincenal: {
          primerPago: 1174,
          pago: 587,
          total: 21132
        }
      },
      {
        plazo: 24,
        mensual: {
          primerPago: 925,
          pago: 925,
          total: 22200
        },
        quincenal: {
          primerPago: 926,
          pago: 463,
          total: 22224
        }
      },
      {
        plazo: 30,
        mensual: {
          primerPago: 776,
          pago: 776,
          total: 23280
        },
        quincenal: {
          primerPago: 776,
          pago: 388,
          total: 23280
        }
      },
      {
        plazo: 36,
        mensual: {
          primerPago: 676,
          pago: 676,
          total: 24336
        },
        quincenal: {
          primerPago: 676,
          pago: 338,
          total: 24336
        }
      },
      {
        plazo: 48,
        mensual: {
          primerPago: 552,
          pago: 552,
          total: 26496
        },
        quincenal: {
          primerPago: 552,
          pago: 276,
          total: 26496
        }
      }
    ]
  },
  {
    id: 8,
    name: "CONMEMORATIVO",
    alternativeName: "",
    logo: "/image/planes/ic_conmemorativo_white.png",
    logoPrimary: "/image/planes/ic_conmemorativo_primary.png",
    urna: false,
    cremacion: false,
    precioDeLista: 21350,
    description: [
      "Ataúd metálico con herrajes.",
      "Sala de velación (24 hrs, áreas refigeradas, antesala, sala de velación y privado).",
      "Transporte del finado (de hospital o domicilio local a funeraria, 100 km a la redonda).",
      "Asesoría de trámites y gestoría con instituciones (no incluye costos).",
      "Embalsamamiento (preparación del finado y arreglo estético).",
      "Misa solemne de cuerpo presente (opcional).",
      "Cafetería Básica."
    ],
    summary: [
      "Ataúd: Metálico con herrajes",
      "Carroza",
      "Misa solemne: Opcional",
      "Agua embotellada (opcional)",
      "Transporte del finado"
    ],
    moreInformation: false,
    descripciones: [
      "",
      "",
      ""
    ],
    pagosConAnticipo: [
      {
        plazo: 65,
        mensual: {
          primerPago: 550,
          pago: 320,
          total: 20800
        },
        quincenal: {
          primerPago: 550,
          pago: 160,
          total: 20800
        }
      }
    ],
    pagosSinAnticipo: [
      {
        plazo: 65,
        mensual: {
          primerPago: 550,
          pago: 320,
          total: 20800
        },
        quincenal: {
          primerPago: 320,
          pago: 160,
          total: 20800
        }
      }
    ]
  },
  {
    id: 9,
    name: "SET DOMICILIO",
    alternativeName: "",
    logo: "/image/planes/ic_set_domicilio_white.png",
    logoPrimary: "/image/planes/ic_set_domicilio_primary.png",
    urna: false,
    cremacion: false,
    precioDeLista: 10728,
    description: [
      "Transporte del finado (de hospital o domicilio local a funeraria).",
      "Asesoría de trámites (no incluye costos).",
      "Embalsamamiento (preparación del finado y arreglo estético).",
      "Equipo de velación (1 carpa, 60 sillas, 4 candeleros, 2 floreros, 1 Cristo, 1 biombo, 1 pedestal, 1 cafetera eléctrica).",
      "Cirios",
      "Servicio de Cafetería (café, vasos térmicos, azúcar, crema y revolvedores).",
      "Servicio de Carroza (Traslado del domicilio - iglesia - panteón local).",
      "Servicio de agua embotellada (para sepelio).",
      "Ataúd metálico básico (color único)."
    ],
    summary: [
      "Equipo de velación",
      "Ataúd: Metálico",
      "Carroza",
      "Cirios",
      "Agua embotellada (opcional)",
      "Transporte del finado"
    ],
    moreInformation: false,
    descripciones: [
      "",
      "",
      ""
    ],
    pagosConAnticipo: [
      {
        plazo: 12,
        mensual: {
          primerPago: 1002,
          pago: 1002,
          total: 12024
        },
        quincenal: {
          primerPago: 501,
          pago: 501,
          total: 12024
        }
      },
      {
        plazo: 18,
        mensual: {
          primerPago: 704,
          pago: 704,
          total: 12672
        },
        quincenal: {
          primerPago: 352,
          pago: 352,
          total: 12672
        }
      },
      {
        plazo: 24,
        mensual: {
          primerPago: 555,
          pago: 555,
          total: 13320
        },
        quincenal: {
          primerPago: 278,
          pago: 278,
          total: 13344
        }
      },
      {
        plazo: 30,
        mensual: {
          primerPago: 465,
          pago: 465,
          total: 13950
        },
        quincenal: {
          primerPago: 233,
          pago: 233,
          total: 13980
        }
      },
      {
        plazo: 36,
        mensual: {
          primerPago: 406,
          pago: 406,
          total: 14616
        },
        quincenal: {
          primerPago: 203,
          pago: 203,
          total: 14616
        }
      },
      {
        plazo: 48,
        mensual: {
          primerPago: 331,
          pago: 331,
          total: 15888
        },
        quincenal: {
          primerPago: 166,
          pago: 166,
          total: 15936
        }
      }
    ],
    pagosSinAnticipo: [
      {
        plazo: 12,
        mensual: {
          primerPago: 1002,
          pago: 1002,
          total: 12024
        },
        quincenal: {
          primerPago: 1002,
          pago: 501,
          total: 12024
        }
      },
      {
        plazo: 18,
        mensual: {
          primerPago: 704,
          pago: 704,
          total: 12672
        },
        quincenal: {
          primerPago: 704,
          pago: 352,
          total: 12672
        }
      },
      {
        plazo: 24,
        mensual: {
          primerPago: 555,
          pago: 555,
          total: 13320
        },
        quincenal: {
          primerPago: 556,
          pago: 278,
          total: 13344
        }
      },
      {
        plazo: 30,
        mensual: {
          primerPago: 465,
          pago: 465,
          total: 13950
        },
        quincenal: {
          primerPago: 466,
          pago: 233,
          total: 13980
        }
      },
      {
        plazo: 36,
        mensual: {
          primerPago: 406,
          pago: 406,
          total: 14616
        },
        quincenal: {
          primerPago: 406,
          pago: 203,
          total: 14616
        }
      },
      {
        plazo: 48,
        mensual: {
          primerPago: 331,
          pago: 331,
          total: 15888
        },
        quincenal: {
          primerPago: 332,
          pago: 166,
          total: 15936
        }
      }
    ]
  }
];

// PARQUE
export const parque = [
  {
    id: 10,
    name: "GAVETAS",
    image: "/image/parque/cripta_bg.jpg",
    imageFullSize: "/image/parque/cripta_bg_original.jpg",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam a est sit amet velit faucibus fermentum.",
    price: 35342,
    firstPayment: 5304,
    types: [
      {
        name: "2 GAVETAS Sin Asignacion en Cedrón",
        price: 35342,
        firstPayment: 5304,
      },
      {
        name: "2 GAVETAS Preasignada en Cedrón",
        price: 44174,
        firstPayment: 6628,
      },
      {
        name: "3 GAVETAS Sin asignación Cedrón",
        price: 43470,
        firstPayment: 6524,
      },
      {
        name: "3 GAVETAS Preasignada Cedrón",
        price: 54337,
        firstPayment: 8152,
      },
      {
        name: "3 GAVETAS Preasignadas en Moisés",
        price: 70000,
        firstPayment: 10500,
      },
      {
        name: "4 GAVETAS Preasignadas en Cedrón",
        price: 70196,
        firstPayment: 10532,
      }
    ]
  },
  {
    id: 11,
    name: "NICHOS",
    image: "/image/parque/nicho_bg.jpg",
    imageFullSize: "/image/parque/nicho_bg_original.jpg",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam a est sit amet velit faucibus fermentum.",
    price: 70000,
    firstPayment: 10500,
    types: [
      {
        name: "Nicho 1 Urna",
        price: 9349,
        firstPayment: 1404,
      },
      {
        name: "Nicho 2 Urna",
        price: 14499,
        firstPayment: 2176,
      },
      {
        name: "Nicho 3 Urna",
        price: 19057,
        firstPayment: 2860,
      },
      {
        name: "Nicho 4 Urna",
        price: 24139,
        firstPayment: 3624,
      }
    ]
  },
  {
    id: 12,
    name: "MAUSOLEO",
    image: "/image/parque/mausoleo_bg.jpg",
    imageFullSize: "/image/parque/mausoleo_bg_original.jpg",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam a est sit amet velit faucibus fermentum.",
    price: 35342,
    firstPayment: 5304,
    types: [
      {
        name: "Mausoleo Rey David",
        price: 35557,
        firstPayment: 5336,
      },
      {
        name: "Mausoleo Betania",
        price: 35557,
        firstPayment: 5336,
      },
      {
        name: "Mausoleo Doble Rey David",
        price: 47563,
        firstPayment: 7136,
      }
    ]
  },
  {
    id: 13,
    name: "PRIVADA",
    image: "/image/parque/privada_bg.jpg",
    imageFullSize: "/image/parque/privada_bg_original.jpg",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam a est sit amet velit faucibus fermentum.",
    price: 35342,
    firstPayment: 5304,
    types: [
      {
        name: "Privada 6 Gavetas Moisés",
        price: 820258,
        firstPayment: 123040,
      },
      {
        name: "Privada 8 Gavetas Moisés",
        price: 1010640,
        firstPayment: 151596,
      }
    ]
  }
];

// CIUDADES-LUGARES
export const cityPlace = [
  // Culiacán
  {
    cityId: 1,
    placeId: 3
  },
  {
    cityId: 1,
    placeId: 4
  },
  {
    cityId: 1,
    placeId: 1
  },
  // Los Mochis
  {
    cityId: 2,
    placeId: 2
  },
  {
    cityId: 2,
    placeId: 1
  },
  // Guasave
  {
    cityId: 3,
    placeId: 2
  },
  {
    cityId: 3,
    placeId: 1
  },
  // Navolato
  {
    cityId: 4,
    placeId: 2
  },
  {
    cityId: 4,
    placeId: 1
  },
];

// LUGARES-PERSONAS
export const peoplePlace = [
  // Domicilio
  {
    placeId: 1,
    peopleId: 0
  },
  // Funeraria
  {
    placeId: 2,
    peopleId: 1
  },
  {
    placeId: 2,
    peopleId: 2
  },
  {
    placeId: 2,
    peopleId: 3
  },
  // Montebello
  {
    placeId: 3,
    peopleId: 3
  },
  // Zapata
  {
    placeId: 4,
    peopleId: 1
  },
  {
    placeId: 4,
    peopleId: 2
  }
];

// CIUDADES-CARROZAS
export const cityAuto = [
  // Culiacán
  {
    cityId: 1,
    autoId: 1
  },
  {
    cityId: 1,
    autoId: 2
  },
  {
    cityId: 1,
    autoId: 3
  },
  {
    cityId: 1,
    autoId: 4
  },
  {
    cityId: 1,
    autoId: 5
  },
  {
    cityId: 1,
    autoId: 6
  },
  {
    cityId: 1,
    autoId: 7
  },
  {
    cityId: 1,
    autoId: 8
  },
  {
    cityId: 1,
    autoId: 9
  },
  {
    cityId: 1,
    autoId: 10
  },
  {
    cityId: 1,
    autoId: 11
  },
  // Los Mochis
  {
    cityId: 2,
    autoId: 7
  },
  {
    cityId: 2,
    autoId: 10
  },
  {
    cityId: 2,
    autoId: 11
  },
  // Guasave
  {
    cityId: 3,
    autoId: 7
  },
  {
    cityId: 3,
    autoId: 10
  },
  {
    cityId: 3,
    autoId: 11
  },
  // Navolato
  {
    cityId: 4,
    autoId: 7
  },
  {
    cityId: 4,
    autoId: 10
  },
  {
    cityId: 4,
    autoId: 11
  },
];

// CIUDADES-CARROZAS
export const cityAutoPlan = [
  // Culiacán
  //   - Revelación
  {
    cityId: 1,
    planId: 1,
    autoId: 1
  },
  {
    cityId: 1,
    planId: 1,
    autoId: 2
  },
  {
    cityId: 1,
    planId: 1,
    autoId: 8
  },
  //   - Mediterráneo
  {
    cityId: 1,
    planId: 2,
    autoId: 1
  },
  {
    cityId: 1,
    planId: 2,
    autoId: 2
  },
  {
    cityId: 1,
    planId: 2,
    autoId: 8
  },
  //   - Tempus
  {
    cityId: 1,
    planId: 3,
    autoId: 1
  },
  {
    cityId: 1,
    planId: 3,
    autoId: 2
  },
  {
    cityId: 1,
    planId: 3,
    autoId: 8
  },
  //   - Imperial
  {
    cityId: 1,
    planId: 4,
    autoId: 3
  },
  {
    cityId: 1,
    planId: 4,
    autoId: 4
  },
  {
    cityId: 1,
    planId: 4,
    autoId: 5
  },
  {
    cityId: 1,
    planId: 4,
    autoId: 6
  },
  {
    cityId: 1,
    planId: 4,
    autoId: 8
  },
  {
    cityId: 1,
    planId: 4,
    autoId: 9
  },
  //   - Paraiso
  {
    cityId: 1,
    planId: 5,
    autoId: 10
  },
  {
    cityId: 1,
    planId: 5,
    autoId: 11
  },
  //   - Tradicion
  {
    cityId: 1,
    planId: 6,
    autoId: 10
  },
  {
    cityId: 1,
    planId: 6,
    autoId: 11
  },
  //   - Set Funeraria
  {
    cityId: 1,
    planId: 7,
    autoId: 10
  },
  {
    cityId: 1,
    planId: 7,
    autoId: 11
  },
  //   - Conmemorativo
  {
    cityId: 1,
    planId: 8,
    autoId: 10
  },
  {
    cityId: 1,
    planId: 8,
    autoId: 11
  },
  //   - Domicilio
  {
    cityId: 1,
    planId: 9,
    autoId: 10
  },
  {
    cityId: 1,
    planId: 9,
    autoId: 11
  },
  // Los Mochis
  //   - Revelación
  {
    cityId: 2,
    planId: 1,
    autoId: 11
  },
  //   - Mediterráneo
  {
    cityId: 2,
    planId: 2,
    autoId: 11
  },
  //   - Tempus
  {
    cityId: 2,
    planId: 3,
    autoId: 11
  },
  //   - Imperial
  {
    cityId: 2,
    planId: 4,
    autoId: 10
  },
  //   - Paraiso
  {
    cityId: 2,
    planId: 5,
    autoId: 10
  },
  //   - Tradicion
  {
    cityId: 2,
    planId: 6,
    autoId: 10
  },
  //   - Set Funeraria
  {
    cityId: 2,
    planId: 7,
    autoId: 7
  },
  //   - Conmemorativo
  {
    cityId: 2,
    planId: 8,
    autoId: 7
  },
  //   - Domicilio
  {
    cityId: 2,
    planId: 9,
    autoId: 7
  },
  // Guasave
  //   - Revelación
  {
    cityId: 3,
    planId: 1,
    autoId: 11
  },
  //   - Mediterráneo
  {
    cityId: 3,
    planId: 2,
    autoId: 11
  },
  //   - Tempus
  {
    cityId: 3,
    planId: 3,
    autoId: 11
  },
  //   - Imperial
  {
    cityId: 3,
    planId: 4,
    autoId: 10
  },
  //   - Paraiso
  {
    cityId: 3,
    planId: 5,
    autoId: 10
  },
  //   - Tradicion
  {
    cityId: 3,
    planId: 6,
    autoId: 10
  },
  //   - Set Funeraria
  {
    cityId: 3,
    planId: 7,
    autoId: 7
  },
  //   - Conmemorativo
  {
    cityId: 3,
    planId: 8,
    autoId: 7
  },
  //   - Domicilio
  {
    cityId: 3,
    planId: 9,
    autoId: 7
  },
  // Navolato
  //   - Revelación
  {
    cityId: 4,
    planId: 1,
    autoId: 11
  },
  //   - Mediterráneo
  {
    cityId: 4,
    planId: 2,
    autoId: 11
  },
  //   - Tempus
  {
    cityId: 4,
    planId: 3,
    autoId: 11
  },
  //   - Imperial
  {
    cityId: 4,
    planId: 4,
    autoId: 10
  },
  //   - Paraiso
  {
    cityId: 4,
    planId: 5,
    autoId: 10
  },
  //   - Tradicion
  {
    cityId: 4,
    planId: 6,
    autoId: 10
  },
  //   - Set Funeraria
  {
    cityId: 4,
    planId: 7,
    autoId: 7
  },
  //   - Conmemorativo
  {
    cityId: 4,
    planId: 8,
    autoId: 7
  },
  //   - Domicilio
  {
    cityId: 4,
    planId: 9,
    autoId: 7
  },
];

export function placesByCity(cityId) {
  let city = cities.find(c => c.id === cityId);
  let placesInCity = cityPlace.filter(obj => obj.cityId === cityId);
  let placesWithName = placesInCity.map(obj => {
    return places.find(p => p.id === obj.placeId);
  });
  city.places = placesWithName;
  return city;
}

export function peopleByPlace(placeId) {
  let place = places.find(p => p.id === placeId);
  let capacityInPlace = peoplePlace.filter(pp => pp.placeId === placeId);
  let peopleLabel = capacityInPlace.map(obj => {
    return people.find(p => p.id === obj.peopleId);
  });
  place.people = peopleLabel;
  return place;
}

export function autosByCity(cityId) {
  let city = cities.find(c => c.id === cityId);
  let autosInCity = cityAuto.filter(ca => ca.cityId === cityId);
  let autosWithName = autosInCity.map(aic => {
    return autos.find(a => a.id === aic.autoId);
  });
  city.autos = autosWithName;
  return city;
}

export function planesByPeople(peopleId) {
  if (peopleId === "") {
    peopleId = 0;
  }
  let peopleObj = people.find(p => p.id === peopleId);
  let planesInPeople = peoplePlan.filter(pp => pp.peopleId === peopleId);
  let planesWithName = planesInPeople.map(pip => {
    return planes.find(p => p.id === pip.planId);
  });
  peopleObj.planes = planesWithName;
  return peopleObj;
}

export function ataudesFilter(peopleId) {
  let planesObjs = planesByPeople(peopleId).planes;
  let ataudesIds = [];
  planesObjs.forEach(plan => {
    let planAtaudObj = planAtaud.filter(pa => pa.planId === plan.id);
    planAtaudObj.forEach(pao => {
      ataudesIds.push(pao.ataudId);
    });
  });
  ataudesIds = ataudesIds.filter((value, index, self) => self.indexOf(value) === index);
  return ataudesIds.map(a => ataudes.find(at => at.id === a));;
}

export function autosByCityAndPlanes(cityId, peopleId) {
  let planesObjs = planesByPeople(peopleId).planes;
  let autoIds = [];
  planesObjs.forEach(p => {
    cityAutoPlan
        .filter(c => c.cityId === cityId && c.planId === p.id)
        .forEach(join => autoIds.push(join.autoId));
  });
  autoIds = autoIds.filter((value, index, self) => self.indexOf(value) === index);
  return autoIds.map(a => autos.find(ar => ar.id === a));
}

export function findMyPlan(cityId, peopleId, autoId, ataudId) {
  let planesObjs = planesByPeople(peopleId).planes;
  let planesWithAuto = [];
  let planesWithAutoAndAtaud = [];

  planesObjs.forEach(plan => {
    let cityAutoObj = cityAutoPlan.filter(c => c.cityId === cityId && c.planId === plan.id);
    cityAutoObj.forEach(ca => {
      let planCopy = { ...plan };
      planCopy.cityId = ca.cityId;
      planCopy.autoId = ca.autoId;
      planesWithAuto.push(planCopy);
    });
  });

  planesWithAuto = planesWithAuto.filter(pa => pa.autoId === autoId);

  planesWithAuto.forEach(plan => {
    let planAtaudObj = planAtaud.filter(pa => pa.planId === plan.id);
    planAtaudObj.forEach(pao => {
      let planCopy = { ...plan };
      planCopy.ataudId = pao.ataudId;
      planesWithAutoAndAtaud.push(planCopy);
    });
  });

  return planesWithAutoAndAtaud.find(paa => paa.ataudId === ataudId);
}

export function hasCremacion(peopleId) {
  if (peopleId === "") {
    peopleId = 0;
  }
  let planesInPeople = peoplePlan.filter(pp => pp.peopleId === peopleId);
  let planesWithName = planesInPeople.map(pip => {
    return planes.find(p => p.id === pip.planId);
  });
  
  return planesWithName[0].cremacion;
}