import React from 'react';

// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Hidden from '@material-ui/core/Hidden';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import SwipeableViews from 'react-swipeable-views';
import Divider from '@material-ui/core/Divider';

// Components
import CardView from '../../components/CardView';
import TextView from '../../components/TextView';
import { getScreenRange } from '../../App';
import { fireAnalytics } from '../../Firebase';

const styles = {
  buttonTextWhite: {
    color: 'white',
    textTransform: 'capitalize',
    fontSize: '3rem'
  },
  buttonTextPrimary: {
    color: '#326295',
    textTransform: 'capitalize',
    fontSize: '1rem'
  }
};

function CardMessage() {
  return (
    <Grid container
      direction="column"
      justify="flex-start"
      alignItems="center"
    >
      <CardView
        elevation={8} 
        radius={12}
      >
        <Box m={3}>
          <Grid container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <TextView variant="h5" fontWeight={650} mb={1}>
                Escríbenos por WhatsApp
              </TextView>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box mt={2}>
                <Grid container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={1}>
                    <Box component="span" mt={4}><img src="/image/whatsapp_double_check.png" alt="check"/></Box>
                  </Grid>
                  <Grid item xs={11}>
                    <TextView variant="h6" fontWeight={400} ml={3} mr={3}>
                      Información de planes y servicios
                    </TextView>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box mt={2}>
                <Grid container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={1}>
                    <Box component="span"><img src="/image/whatsapp_double_check.png" alt="check"/></Box>
                  </Grid>
                  <Grid item xs={11}>
                    <TextView variant="h6" fontWeight={400} ml={3} mr={3}>
                      Atención personalizada
                    </TextView>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box mt={2}>
                <Grid container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={1}>
                    <Box component="span"><img src="/image/whatsapp_double_check.png" alt="check"/></Box>
                  </Grid>
                  <Grid item xs={11}>
                    <TextView variant="h6" fontWeight={400} ml={3} mr={3}>
                      Resuelve tus dudas
                    </TextView>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CardView>
    </Grid>
  );
}

function SmallScreen() {
  const range = getScreenRange();
  let titleVariant = (range === "sm") ? "h6" : "h5";
  let messageVariant = (range === "sm") ? "body1" : "h6";
  let buttonSize = (range === "sm") ? "medium" : "large";
  let textSpacing = (range === "sm") ? 4 : 6;

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
    >
      <Grid item xs={9}>
        <TextView variant={titleVariant} textColor="#ffffff" textAlign="center" fontWeight={700}>
          Ponte en contacto con uno de nuestros Asesores
        </TextView>
      </Grid>
      <Grid item xs={9}>
        <TextView variant={messageVariant} textColor="#ffffff" textAlign="center"  fontWeight={400} mt={2} mb={textSpacing}>
          Ahora puedes ponerte en contacto por WhatsApp, cotizar y comprar tus servicios funerarios.
          <Box component="span" style={{ fontWeight: 700 }} ml={1}>Escríbenos y estaremos en contacto.</Box>
        </TextView>
      </Grid>
      <Grid item container xs={7} justify="center">
          <Button variant="contained" fullWidth size={buttonSize}
            style={{
              color: '#085D55',
              background: "#ffffff"
            }}
            onClick={(e) => {
              e.preventDefault();
              fireAnalytics.logEvent('click', { name: 'WhatsApp' });
              window.location.href='https://wa.me/+526671901950';
            }}
          >
            Iniciar Conversación
          </Button>
      </Grid>
    </Grid>
  );
}

function LargeScreen() {
  const range = getScreenRange();
  let marginBottom = (range === "sm") ? 4 : 8;

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="flex-end"
    >
      <Grid item xs={6}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-end"
        >
          <img src="/image/chat-whatsapp.png" width="75%" alt="" style={{ maxWidth: 375 }}/>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Box mb={marginBottom}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <SmallScreen/>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

function WhatsAppPage() {
  const range = getScreenRange();
  let cardMargin = (range === "xs") ? 2 : 4;
  let marginTop = (range === "sm") ? 16 : 20;
  
  return (
    <Box
      style={{
        width: "100vw",
        height: "auto",
        display: "flex",
        flexFlow: "column",
        backgroundImage: "url(/image/background_whatsapp.png)",
        backgroundRepeat: "repeat",
        backgroundSize: "70px",
        backgroundColor: "#ECE5DD"
      }}
    >
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        style={{
          background: "#085D55"
        }}
      >
        <Hidden smUp>
          <Box mt={20} mb={10}>
            <SmallScreen/>
          </Box>
        </Hidden>
        <Hidden xsDown>
          <Box mt={marginTop}>
            <LargeScreen/>
          </Box>
        </Hidden>
      </Grid>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
      >
        <Box mt={4} mb={8} mx={cardMargin}>
          <CardMessage/>
        </Box>
      </Grid>
    </Box>
  );
}

export default WhatsAppPage;
