import React from 'react';

import html2canvas from 'html2canvas';

// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

// Components
import TextView from '../../components/TextView';
import { sendPlanEmail, sendPlanEmailToClient } from '../../Api';
import { roles, autos, ataudes, cities, places, people, findMyPlan, esquelas, planes } from '../../ArraysDB';
import { fireDb } from '../../Firebase';

const NUMBER_PATTERN = /^[0-9]+$/;

const styles = {
  buttonTextWhite: {
    color: 'white',
    textTransform: 'capitalize',
    fontSize: '3rem'
  },
  buttonTextPrimary: {
    color: '#326295',
    textTransform: 'capitalize',
    fontSize: '1rem'
  }
};

function formatPhone(value) {
  let formatted = "";
  for (let i = 0; i < value.length; i++) {
    if (i === 0) {
      formatted = "(";
    }
    if (i === 6) {
      formatted += "-";
    }
    formatted += value.charAt(i);
    if (i === 2) {
      formatted += ") ";
    }
  }
  return formatted;
}

function parsePhone(value) {
  let number = "";

  if (value.charAt(value.length - 1) === ")") {
    value = value.substring(0, value.length - 2);
  }

  for (let i = 0; i < value.length; i++) {
    let car = value.charAt(i);
    if (!NUMBER_PATTERN.test(car)) {
      continue;
    }
    number += car;
  }

  return number;
}

function ServiceSection({ formData, location, tipoEsquela }) {
  return (
    <React.Fragment>
      <Grid item container xs={11}
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item>
          <TextView variant="h6" textColor="#ffffff" mt={3} mb={1} ml={2}>
            Servicio
          </TextView>
        </Grid>

        <Grid item>
          <ul style={{ marginTop: 1, marginBottom: 0 }}>
            <TextView textColor="#ffffff" mr={2}>
              <li style={{ marginBottom: 2 }}>
                Lugar: {location.city}, {location.place}
              </li>
              {location.people !== "" ? (
                <li style={{ marginBottom: 2 }}>
                  Tipo sala: Por definir {location.people}
                </li>
              ) : (
                null
              )}
              <li style={{ marginBottom: 2 }}>
                Tipo ataúd: {ataudes.find(a => a.id === formData.serviceType.ataud.value).name}
              </li>
              <li style={{ marginBottom: 2 }}>
                Tipo carroza: {autos.find(a => a.id === formData.serviceType.carroza.value).name}
              </li>
              <li style={{ marginBottom: 2 }}>
                Tipo de esquela: {tipoEsquela.name}
              </li>
              {tipoEsquela.message !== "" ? (
                <li style={{ marginBottom: 2 }}>
                  Mensaje: {tipoEsquela.message}
                </li>
              ) : (
                null
              )}
              {formData.customService.otros.value !== "" ? (
                <li style={{ marginBottom: 2 }}>
                  Otro: {formData.customService.otros.value}
                </li>
              ) : (
                null
              )}
            </TextView>
          </ul>
        </Grid>
      </Grid>
      <Grid item container xs={12}
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={5}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.serviceType.cremacion.value}
                name="cremacion"
                style ={{
                  color: "#ffffff",
                }}/>
            }
            label={<Typography style={{ color: '#ffffff' }}>Cremación</Typography>}
          />
        </Grid>
        <Grid item xs={5}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.serviceType.parque.value}
                name="parque"
                style ={{
                  color: "#ffffff",
                }}/>
            }
            label={<Typography style={{ color: '#ffffff' }}>Parque</Typography>}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function DescriptionSection({ formData, extraMarginLeft=0 }) {
  return (
    <Grid item container xs={11}
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid item>
        <TextView variant="h6" textColor="#ffffff" mt={3} mb={1} ml={2 + extraMarginLeft}>
          Características
        </TextView>
      </Grid>
      <Grid item>
        <ul style={{ marginTop: 1, marginBottom: 0 }}>
          <TextView textColor="#ffffff" mr={2} ml={extraMarginLeft}>
            {formData.plan.description.map((option, key) => (
              <li key={key} style={{ marginBottom: 2 }}>{option}</li>
            ))}
          </TextView>
        </ul>
      </Grid>
    </Grid>
  );
}

function CustomSection({ formData }) {
  return (
    <Grid item container xs={11}
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid item>
        <TextView variant="h6" textColor="#ffffff" mt={3} mb={1} ml={2}>
          Otro
        </TextView>
      </Grid>

      <Grid item container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={5}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.customService.esquela.value !== ""}
                name="esquela"
                style ={{
                  color: "#ffffff",
                }}/>
            }
            label={<Typography style={{ color: '#ffffff' }}>Esquela</Typography>}
          />
        </Grid>
        <Grid item xs={5}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.customService.flores.value}
                name="flores"
                style ={{
                  color: "#ffffff",
                }}/>
            }
            label={<Typography style={{ color: '#ffffff' }}>Flores</Typography>}
          />
        </Grid>
      </Grid>
      <Grid item container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={5}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.customService.cafeteria.value === 1}
                name="cafeteria"
                style ={{
                  color: "#ffffff",
                }}/>
            }
            label={<Typography style={{ color: '#ffffff' }}>Cafetería</Typography>}
          />
        </Grid>
        <Grid item xs={5}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.customService.desayuno.value}
                name="desayuno"
                style ={{
                  color: "#ffffff",
                }}/>
            }
            label={<Typography style={{ color: '#ffffff' }}>Desayuno</Typography>}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function WordCapName(name) {
  let words = name.split(" ");
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
  }
  return words.join(" ");
}

function ResultFormPage({ showSnackbar, showCustomDialog }) {
  const [emailData, setEmailData] = React.useState(null);
  const [formData, setFormData] = React.useState(null);
  const [location, setLocation] = React.useState({ city: "", place: "", people: "" });
  const [tipoEsquela, setTipoEsquela] = React.useState({ name: "", message: ""});

  React.useEffect(() => {
    let result = JSON.parse(localStorage.getItem('formData'));

    if (!result) {
      window.location.href='/';
      return;
    }

    let cityId = result.serviceLocation.city.value;
    let placeId = result.serviceLocation.place.value;
    let peopleId = result.serviceLocation.people.value;
    let autoId = result.serviceType.carroza.value;
    let ataudId = result.serviceType.ataud.value;
    let esquelaObj = esquelas.find(e => e.id === result.customService.esquela.value);

    if (esquelaObj.name === "Ambas") {
      esquelaObj.name += " (digital y periódico)";
    }

    setTipoEsquela({ 
      name: esquelaObj.name, 
      message: result.customService.mensajeEsquela.value
    });

    if (peopleId === "") {
      peopleId = 0;
    }

    let citySelected = cities.find(c => c.id === cityId);
    let placeSelected = places.find(p => p.id === placeId);
    let peopleSelected = people.find(pe => pe.id === peopleId);

    result.plan = findMyPlan(cityId, peopleId, autoId, ataudId);
    
    localStorage.setItem('selectedPlan', JSON.stringify(result.plan));

    setLocation({
      city: citySelected.name,
      place: placeSelected.name,
      people: (peopleId !== 4 && peopleId !== 0) ? `${peopleSelected.name} personas` : ""
    });

    setFormData(result);

    let planName = result.plan.name;
    let name = result.personalData.name.value;
    let email = result.personalData.email.value;
    let phone = result.personalData.phone.value;
    let rol = roles.find(r => r.id === result.personalData.rol.value).name;

    let ataud = ataudes.find(a => a.id === result.serviceType.ataud.value).name;
    let auto = autos.find(a => a.id === result.serviceType.carroza.value).name;
    let esquelaText = result.customService.mensajeEsquela.value;
    let otroText = result.customService.otros.value;

    let cremacion = result.serviceType.cremacion.value;
    let parque = result.serviceType.parque.value;
    let cafeteria = result.customService.cafeteria.value;
    let desayuno = result.customService.desayuno.value;
    let flores = result.customService.flores.value;
    let caracteristicas = "";

    name = WordCapName(name);
    result.personalData.name.value = name;

    result.plan.description.forEach(s => caracteristicas += `<li>${s}</li>`);

    fireDb.collection('simuladorResultados').add(result);

    let html = `
      <h2>PLAN ${planName}</h2>
      <br/>
      <h3>Datos de contacto</h3>
      <p>
        Nombre: ${name}<br/>
        Teléfono: ${phone}<br/>
        Correo: ${email}<br/>
        Rol familiar: ${rol}<br/>
      </p>
      <br/>
      <h3>Servicio</h3>
      <p>
        Lugar: ${citySelected.name}, ${placeSelected.name}<br/>
        Tipo sala: ${(peopleId !== 4 && peopleId !== 0) ? `${peopleSelected.name} personas` : ""}<br/>
        Ataúd: ${ataud}<br/>
        Carroza: ${auto}<br/>
        Esquela: ${esquelaObj.name}<br/>
        ${esquelaText !== "" ? "Mensaje: " + esquelaText + "<br/>" : ""}
        ${otroText !== "" ? "Otro: " + otroText + "<br/>" : ""}
      </p>
      <br/>
      <h3>Otros</h3>
      <p>
        <ul>
          <li>Cremación: ${cremacion ? "Si" : "No"}</li>
          <li>Parque: ${parque ? "Si" : "No"}</li>
          <li>Servicio de cafetería: ${cafeteria === 1 ? "Si" : "No"}</li>
          <li>Desayuno continental: ${desayuno ? "Si" : "No"}</li>
          <li>Escenario floral: ${flores ? "Si" : "No"}</li>
        </ul>
      </p>
      <br/>
      <h3>Características del plan</h3>
      <p>
        <ul>
          ${caracteristicas}
        </ul>
      </p>
      <br/>
      <br/>
      <p>
        Esto no es un contrato, es solo una sugerencia de un plan funerario en base a lo seleccionado anteriormente. Los planes están sujetos a disponibilidad y pueden variar de precio dependiendo de la fecha de ejecución y servicios adicionales.
      </p>
      <br/>
    `;

    sendPlanEmail(
      result.personalData.email.value, 
      result.personalData.name.value,
      result.serviceLocation.city.value,
      html
    );

    setEmailData({
      email: result.personalData.email.value, 
      name: result.personalData.name.value,
      html: html
    });
  }, []);

  const handleClickSave = event => {
    sendPlanEmailToClient(
      emailData.email, 
      emailData.name,
      formData.serviceType.parque.value,
      emailData.html
    );
    showSnackbar("Información de Plan San Martín enviada a su correo", "success");
  }

  const handleClickShare = event => {
    const container = document.getElementById('resultContainer');
    html2canvas(container).then(function(canvas) {
      let enlace = document.createElement('a');
      enlace.download = "Simulador Plan San Martin - resultado";
      enlace.href = canvas.toDataURL("image/jpeg");
      enlace.click();
    });
  }

  const handleClickBuy = event => {
    let buyer = {
      name: formData.personalData.name.value,
      email: formData.personalData.email.value,
      phone: formData.personalData.phone.value,
      city: formData.serviceLocation.city.value
    }
    localStorage.setItem('buyerData', JSON.stringify(buyer));
    window.location.href='/planes-y-productos';
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      style={{
        width: "100vw",
        height: "94vh",
        overflow: 'auto',
        flex: "1 1 auto",
        background: "#326295"
      }}
    >
      { formData ? 
        (
          <React.Fragment>
            <Fade in={formData !== null} timeout={1500}>
              <Grid item container 
                id="resultContainer"
                direction="row"
                justify="center"
                alignItems="flex-start"
                style={{
                  background: "#326295"
                }}
              >
                <Grid item container xs={12} justify="center">
                  <Box mt={4} mb={2}>
                    <img src={formData.plan.logo} alt="" style={{ maxWidth: 160, maxHeight: 160 }}/>
                  </Box>
                </Grid>

                <Grid item xs={10}>
                  <TextView textColor="#ffffff" textAlign="center">
                    {formData.personalData.name.value}
                  </TextView>
                </Grid>
                <Grid item xs={10}>
                  <TextView textColor="#ffffff" textAlign="center">
                    {formData.personalData.phone.value}
                  </TextView>
                </Grid>

                <Hidden smUp>
                  <React.Fragment>
                    <ServiceSection 
                      formData={formData}
                      location={location}
                      tipoEsquela={tipoEsquela}/>

                    <DescriptionSection formData={formData}/>

                    <CustomSection formData={formData}/>
                  </React.Fragment>
                </Hidden>

                <Hidden xsDown>
                  <Grid item container md={10} lg={7}
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                  >
                    <Grid item md={6}>
                      <ServiceSection 
                        formData={formData}
                        location={location}
                        tipoEsquela={tipoEsquela}/>
                    </Grid>
                    <Grid item md={4}>
                      <CustomSection formData={formData}/>
                    </Grid>
                  </Grid>
                  <Grid item container md={10} lg={7}
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item >
                      <DescriptionSection 
                        formData={formData}
                        extraMarginLeft={8}/>
                    </Grid>
                  </Grid>
                </Hidden>

                <Grid item xs={10} md={8} lg={6}>
                  <TextView variant="caption" textColor="#ffffff" textAlign="center" mt={8} mb={4}>
                    Esto no es un contrato, es solo una sugerencia de un plan funerario en base a lo seleccionado anteriormente. Los planes están sujetos a disponibilidad y pueden variar de precio dependiendo de la fecha de ejecución y servicios adicionales.
                  </TextView>
                </Grid>

                <Grid item xs={12}>
                  <Button
                  fullWidth
                    variant="text"
                    style={{ color: 'white' }}
                    onClick={e => window.location.href='/'}
                  >
                    Regresar
                  </Button>
                </Grid>

                <Box width={1} mt={18}>
                  <Divider/>
                </Box>
              </Grid>
            </Fade>

            <Grid item container
              direction="row"
              justify="center"
              alignItems="center"
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                background: "#ffffff"
              }}
            >
              <Box width={1} mb={2}>
                <Divider/>
              </Box>
              <Grid item container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={4}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClickSave}
                  >
                    Guardar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickShare}
                  >
                    Compartir
                  </Button>
                </Grid>
              </Grid>
              <Box width={1} mt={2}>
                <Divider/>
              </Box>
            </Grid>
          </React.Fragment>
        ) : (
          null
        )
      }
    </Box>
  );
}

export default ResultFormPage;
