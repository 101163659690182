import React from 'react';

// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Hidden from '@material-ui/core/Hidden';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';

// Components
import PlanItem from './PlanItem';
import ParqueItem from './ParqueItem';
import CardView from '../../../components/CardView';
import TextView from '../../../components/TextView';
import { planes, parque } from '../../../ArraysDB';
import { useWindowDimensions } from '../../../App';

const money = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
});

function DescriptionSection({ showCustomDialog }) {
  const [planesReverse, setPlanesReverse] = React.useState([]);

  React.useEffect(() => {
    setPlanesReverse(planes.reverse());
  }, []);

  React.useEffect(() => {
    planes.forEach(p => {
      localStorage.removeItem(`${p.name}Index`);
    });
    parque.forEach(p => {
      localStorage.removeItem(`${p.name}Index`);
    });
  }, [planesReverse]);

  return (
    <Box>
      <Grid item container 
        direction="row"
        justify="center"
        alignItems="flex-start"
      >
        <Grid item container xs={11} sm={11} md={9} lg={9}
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={2}
        >
          {planesReverse.map((plan, key) => (
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <PlanItem 
                key={key} 
                plan={plan}
                showCustomDialog={showCustomDialog}/>
            </Grid>
          ))}
        </Grid>

        <Box width={1} mt={14}>
          <Divider style={{ background: "#ffffff" }}/>
        </Box>
      </Grid>

    </Box>
  );
}

export default DescriptionSection;

/*
SECCION DE PARQUE PENDIENTE

        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item>
            <TextView
              variant="h4" 
              textColor="#326295" 
              textAlign="center"
              fontWeight={700}
              mt={10}
              mb={6}
            >
              Parque
            </TextView>
          </Grid>
        </Grid>

        <Grid item container xs={11} sm={11} md={9} lg={9}
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={2}
        >
          {parque.map((parqueItem, key) => (
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <ParqueItem 
                key={key} 
                parqueItem={parqueItem}
                showCustomDialog={showCustomDialog}/>
            </Grid>
          ))}
        </Grid>
*/