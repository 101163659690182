import React from 'react';

// @material-ui/core components
import Grid from "@material-ui/core/Grid";

// Components
import TextView from '../../../components/TextView';
import { useWindowDimensions } from '../../../App';

function BannerSection() {
  const title = "Un ambiente de paz para recordar a nuestros seres queridos.";
  const { width } = useWindowDimensions();
  
  return (
    <Grid container xs>
      <TextView
        variant="h3" 
        textColor="#fff" 
        textAlign="center"
        fontWeight={300}
        mx={width * 0.01}
      >
        {title}
      </TextView>
    </Grid>
  );
}

export default BannerSection;
