import React from 'react';

import { Container, Typography, Box, Button } from '@material-ui/core';
import { obtenerObituarios } from "../../../Api";
import BuildIcon from '@material-ui/icons/Build';


function FueraDeServicioPage() {

    const handleClick = () => {
    };

    React.useEffect(() => {

        const element = document.getElementById('Menu');
        if (element) {
            element.style.display = 'none';
        }

        // Ejecutar obtenerObituarios cada minuto
        const interval = setInterval(() => {
            obtenerObituarios().then(response => {
                if (!response.message) {
                    window.location.href = localStorage.getItem("ReturnPage")
                }
            });
        }, 5000); // 60000ms = 1 minuto

        // Limpiar el intervalo al desmontar el componente
        return () => clearInterval(interval);
    }, [])
    return (
        <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '50px' }}>
            <br />
            <br />
            
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <br/>
                <img src="image/mantenimiento.png" alt="Icono de mantenimiento" style={{ width: '240px', marginBottom: '20px' }} />
                <Typography variant="h4" style={{ marginTop: '20px', marginBottom: '20px' }}>
                    Página en Mantenimiento
                </Typography>
                <Typography variant="body1" style={{ marginBottom: '20px' }}>
                    Lo sentimos, actualmente estamos realizando tareas de mantenimiento. Por favor, inténtelo de nuevo más tarde.
                </Typography>
            </Box>
        </Container>
    );
}

export default FueraDeServicioPage;