import React from 'react';

// @material-ui/core components
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Hidden from '@material-ui/core/Hidden';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';

// Components
import HeaderBar from '../../components/HeaderBar';
import HeaderLinks from '../../components/PaymentHeaderLinks';
import TextView from '../../components/TextView';
import PayTable from './sections/PayTable';
import { loadUserData } from '../payment/sections/LoginSection'

import {
  consultarPagos,
  estadoDeCuenta,
  generarLinkDePago,
  consultarPagoRealizado,
  consultarContrato,
  ValidaPagoPendiente,
  ValidaPagoSinOrden,
  ValidaHorarioPago
} from "../../Api";
import { useWindowDimensions } from '../../App';

import { parseString } from 'xml2js';

const themeTable = createMuiTheme({
  palette: {
    primary: {
      main: '#35647D'
    },
    secondary: {
      main: '#326295',
    }
  }
});

function getFormattedDate(date) {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  month = (month < 10 ? "0" : "") + month;
  day = (day < 10 ? "0" : "") + day;

  //let formattedDate =  `${day}/${month}/${year}`;
  let formattedDate = `${year}/${month}/${day}`;

  return formattedDate;
}

async function openEstadoDeCuenta(flContrato) {
  let response = await estadoDeCuenta(flContrato);
  let url = response.data;
  window.open("https://pel.sanmartin.com.mx" + url, '_blank');
}


async function getPagos(userSession, setUser) {
  let user = { ...userSession };
  let response = await consultarPagos(user.id_Contrato, getFormattedDate(new Date()));
  let data = response.data;

  if (response.error || !response.result) {
    closeSession();
    return;
  }
  user.pagosVencidos = data.Vencidos;
  user.pagosEnganche = data.Enganches;
  user.pagosConvenios = data.Convenios;
  user.pagosTodos = data.General;
  user.pagosMinimo = [];

  user.saldoVencido = user.pagosVencidos.reduce((total, pago) => total + pago.im_Saldo, 0);
  user.saldoEnganche = user.pagosEnganche.reduce((total, pago) => total + pago.im_Saldo, 0);
  user.saldoConvenio = user.pagosConvenios.reduce((total, pago) => total + pago.im_Saldo, 0);
  user.saldoTotal = user.pagosTodos.reduce((total, pago) => total + pago.im_Saldo, 0);
  user.saldoMinimo = 0;

  if (user.saldoVencido > 0) {
    user.pagosVencidos.forEach(pago => {
      for (let i = 0; i < user.pagosTodos.length; i++) {
        if (user.pagosTodos[i].nu_Parcialidad === pago.nu_Parcialidad) {
          user.pagosTodos[i].isPagoVencido = true;
          break;
        }
      }
    });
    user.pagosMinimo = [...user.pagosVencidos];
    user.saldoMinimo = user.saldoVencido;
  } else if (user.pagosTodos.length > 0) {
    let pagoMinimo = user.pagosTodos[0];
    user.pagosMinimo = [pagoMinimo];
    user.saldoMinimo = pagoMinimo.im_Saldo;
  }

  user.hasPaymentData = true;
  setUser(user);
}

async function getLinkDePago(user, rows, setLinkDePago) {
  if (!user || !rows) {
    return "ABONO A CONTRATO";
  }

  let pagos = [];
  let importe = 0;

  if (rows.length > 0) {
    rows.forEach(keySelectedPago => {
      for (let i = 0; i < user.pagosTodos.length; i++) {
        let keyPago = `${user.pagosTodos[i].cl_TipoMovimiento}-${user.pagosTodos[i].nu_Parcialidad}`;
        if (keyPago === keySelectedPago) {
          pagos = [...pagos, user.pagosTodos[i]];
          break;
        }
      }
    });
  } else {
    pagos = [...user.pagosMinimo];
  }

  pagos.forEach(p => {
    p.im_Pagar = p.im_Saldo;
  });

  let keyPagos = pagos.length > 1 ? pagos[0].cl_TipoMovimiento + " " : pagos[0].cl_TipoMovimiento + "-";

  pagos.forEach((p, index) => {
    if (index > 0) {
      keyPagos += "-";
    }
    keyPagos += `${p.nu_Parcialidad}`;
    importe += p.im_Saldo;
  });

  let concepto = `ABONO A CONTRATO : ${user.fl_Contrato} ${keyPagos}`;
  let pagosData = {
    nombreCliente: user.nb_Cliente,
    flContrato: user.fl_Contrato,
    idContrato: user.id_Contrato,
    idCliente: user.id_Cliente,
    user: user,
    concepto: concepto,
    importe: importe,
    pagos: pagos
  }
  let jsonParcialidades = JSON.stringify(pagosData.pagos)

  let response = await generarLinkDePago(user.fl_Contrato, user.id_Contrato, user.id_Cliente, concepto, importe, jsonParcialidades);

  pagosData.link = response.data.de_ligaDeCobro;
  pagosData.id = response.data.id_Pago;
  pagosData.clave = response.data.Cl_ClavePago;
  pagosData.token = response.data.Cl_ClaveGeneradora;

  localStorage.setItem('pagosData', JSON.stringify(pagosData));
  setLinkDePago(pagosData.link);

  setTimeout(() => {
    revisarPago();
  }, 5000);
}

async function revisarPago() {
  let pagosData = JSON.parse(localStorage.getItem('pagosData'));

  let response = await consultarPagoRealizado(pagosData.id, pagosData.clave, pagosData.token, pagosData.user, pagosData.pagos);

  if (!response.result) {
    setTimeout(() => {
      revisarPago();
    }, 1000);
    return;
  }

  pagosData.response = response.data;

  parseString(response.data.xml_respuesta, function (err, result) {
    pagosData.response.xmlRespuesta = result;
  });
  parseString(response.data.xml_generado, function (err, result) {
    pagosData.response.xmlGenerado = result;
  });

  localStorage.setItem('pagosData', JSON.stringify(pagosData));
  window.location.href = '/payment/resume'
}

function PaymentDetailsPage({ showSnackbar, showCustomDialog }) {
  const { width } = useWindowDimensions();
  const cardMargin = width * 0.005;

  const userSession = JSON.parse(localStorage.getItem('user'));
  const [user, setUser] = React.useState(userSession ? userSession : {});
  const [linkDePago, setLinkDePago] = React.useState("");
  const [noPaga, setNoPaga] = React.useState(false);
  const [mensajeNoPaga, setMensajeNoPaga] = React.useState("");
  const [imagen, setImagen] = React.useState("");

  const noPermitePago = (img,msj) => {
    setNoPaga(true);
    setImagen(img)
    setMensajeNoPaga(msj)
  }
  async function verificaContrato() {
    await consultarContrato(user.fl_Contrato).then(async (dataC) => {
      if ((dataC.error) || (!dataC.result || dataC.data == null || dataC.data.length === 0) || (dataC.data.de_Estatus != "AUTORIZADO")) {
        closeSession();
      } else {
        
        let info = {}
        for (let key in dataC.data) {
          info[key] = dataC.data[key];
        }
        if (!info.hasPaymentData) {
          getPagos(info, setUser);
        }
        await ValidaPagoPendiente(user.fl_Contrato).then(async (data) => {
          if (data.d.Data != 0) {
            noPermitePago("/image/terminal 2.png","Su pago está siendo procesado. Por favor, regrese en 24 horas para revisar el estado.")
          } else {
            await ValidaHorarioPago().then(async (data) => {
              if (data.d.Data == 0) {
                const currentPath = window.location.pathname;
                localStorage.setItem("ReturnPage", currentPath)
                window.location.href='/fueradeHorario'
              } else {
                await ValidaPagoSinOrden(user.fl_Contrato).then((data) => {
                  if (data.d.Data != 0) {
                    noPermitePago("/image/OrdenPendiente.png","Le informamos que su pago tiene una orden de pago activa.\n Para más información comuníquese con nuestro servicio de atención al cliente al número (667) 716-59-39 durante el horario de oficina.")
                  } else {
                  }
                });
              }
            })
          }
        });
      }
    });
  }
  React.useEffect(() => {
    if (!user || user.fl_Contrato == null || user.fl_Contrato === '' || user.fl_Contrato === '0' || !user.sesion) {
      closeSession();
    } else {
      verificaContrato(user.fl_Contrato)
    }
  }, []);

  return (
    <Box>
      {!userSession || !userSession.fl_Contrato || !user.hasPaymentData ?
        null
        :
        <React.Fragment>
          {!linkDePago ?
            <Box >
              <PaymentAppBar username={user.nb_Cliente} />
              <Box mt={14} >
                <Grid item container direction="column" justify="center" alignItems="center">
                  <Grid item container xs={11} direction="row" justify="center" alignItems="center" spacing={2}>
                    <Grid item xs={10}>
                      <TextView variant="h6" align="center">
                        {user.nb_Cliente}
                      </TextView>
                    </Grid>
                    <Grid item xs={10}>
                      <TextView variant="h6" fontWeight={400} align="center">
                        Contrato: <Box component="span" fontSize="subtitle1.fontSize">{user.fl_Contrato}</Box>
                      </TextView>
                    </Grid>
                    <Grid item xs={10}>
                      <TextView variant="subtitle1" align="center" fontWeight={200}>
                        {user.de_PlanActual}
                      </TextView>
                    </Grid>
                  </Grid>

                  <Grid item container xs={12}>
                    <Box width={1} mx={cardMargin} mb={cardMargin} mt={cardMargin}>
                      <ThemeProvider theme={themeTable}>
                        <Card elevation={2} style={{ backgroundColor: "#ffffffcc", borderRadius: 12 }}>
                          <CardContent style={{ padding: 0 }}>
                            <br />
                            {noPaga ?

                              <Grid item container xs={11} direction="column" justifyContent="center" alignItems="center" spacing={2} style={{ width: '100%' }}>

                                <img src={imagen} alt="Icono de mantenimiento" style={{ width: '120px', marginBottom: '20px' }} />
                                <TextView variant="subtitle1" align="center" fontWeight={200}>
                                  <b>{mensajeNoPaga}</b>
                                </TextView>
                              </Grid>
                              :
                              <>
                                <PayTable user={user} openEstadoDeCuenta={openEstadoDeCuenta} getLinkDePago={getLinkDePago} setLinkDePago={setLinkDePago} />
                              </>
                            }
                            <br />
                          </CardContent>
                        </Card>
                      </ThemeProvider>
                    </Box>
                  </Grid>

                  <Hidden mdUp>
                    <Grid item container xs={12}>
                      <Box width={1} m={6} />
                    </Grid>
                  </Hidden>

                </Grid>
              </Box>
            </Box>
            :
            <Grid container direction="column" justify="flex-start" alignItems="flex-start" style={{ width: "100%" }}>
              <Grid item>
                <IconButton href="/payment/details">
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item style={{ width: "100%" }}>
                <iframe src={linkDePago} width="100%" height="1024px" frameborder="0" scrolling="no" seamless="seamless"></iframe>
              </Grid>
            </Grid>
          }
        </React.Fragment>
      }
    </Box>
  );
}

function closeSession() {
  localStorage.clear();
  window.location.href = '/payment'
}

function PaymentAppBar({ username = "¿?" }) {
  return (<HeaderBar logo="/image/SMDark.png" rightLinks={<HeaderLinks closeSession={closeSession} />} fixed color="secondary" />);
}

export default PaymentDetailsPage;
