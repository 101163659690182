import React from 'react';

// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Hidden from '@material-ui/core/Hidden';

// Sections
import TitleSection from './sections/TitleSection';
import InstructionSection from './sections/InstructionSection';
import LoginSection from './sections/LoginSection';
import AvisoPrivacidad from './sections/AvisoPrivacidad';

// Views
import Footer from '../../views/Footer'

function PaymentLoginPage({ showSnackbar, showDialog }) {
  const userSession = JSON.parse(localStorage.getItem('user'));
  const [user, setUser] = React.useState(userSession ? userSession : {});
  React.useEffect(() => {
    if (user.fl_Contrato != null && user.fl_Contrato !== '' && user.fl_Contrato !== '0') {
      window.location.href='/payment/details'
    }
  }, [user]);

  return (
    <Box>
      {userSession && userSession.fl_Contrato ?
        null
      :
        <Grid component="div">
          <Box mt={12}>
            <Grid container direction="column" justifyContent="center">
              <Box m={4}>
                <TitleSection /> 
              </Box>

              <Box m={4}>
                <Grid item container direction="row" justifyContent="center" alignItems="flex-start" spacing={8}>
                  <Hidden smDown>
                    <InstructionSection />
                  </Hidden>

                  <LoginSection showSnackbar={showSnackbar} />

                  <Hidden mdUp>
                    <InstructionSection />
                  </Hidden>
                </Grid>
              </Box>

            </Grid>

            <Box mt={6} mb={10}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Button 
                  variant="text" 
                  color="primary"
                  onClick={() => {
                    showDialog(
                      <AvisoPrivacidad/>,
                      "Aviso de privacidad",
                      "Aceptar");
                  }}
                >
                  Aviso de privacidad
                </Button>
              </Grid>
            </Box>
          
          </Box>
          <Footer/>
        </Grid>
      }
    </Box>
  );
}

export default PaymentLoginPage;