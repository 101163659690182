import React from 'react';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';

// Sections
import BannerSection from './sections/BannerSection';
import DescriptionSection from './sections/DescriptionSection';

const useStyles = makeStyles({
  cardRoot: {
    marginTop: -170,
    position: "relative",
    background: "#fff",
    zIndex: "3"
  }
});

function AboutUsPage() {
    const classes = useStyles();
  
    return (
      <Box >
        <BannerSection/>
        <Box className={classes.cardRoot}  style={{
      background: "#35647D"
    }} >
          <DescriptionSection/>
        </Box>
      </Box>
    );
  }
  
  export default AboutUsPage;
  