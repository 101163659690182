import React from 'react';

// @material-ui/core components
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

// Components
import HeaderBar from '../../components/HeaderBar';
import HeaderLinks from '../../components/PaymentHeaderLinks';
import TextView from '../../components/TextView';

// Section
import ObituarioForm from './sections/ObituarioForm';
import ObituariosTable from './sections/ObituariosTable';

import { fireDb } from '../../Firebase';
import { useWindowDimensions } from '../../App';

const themeTable = createMuiTheme({
  palette: {
    secondary: {
      main: '#aaaaaa',
    }
  }
});

export default function AdminPage({ showSnackbar, showDialog }) {
  const { width } = useWindowDimensions();
  const cardMargin = width * 0.005;
  const [ user, setUser ] = React.useState({ email: "", password: "" });
  const [ inputError, setInputError ] = React.useState({ email: false, password: false });
  const [ loginEnabled, setLoginEnabled ] = React.useState(true);
  const [ obituarios, setObituarios ] = React.useState([]);
  const [ dataSet, updateDataSet ] = React.useState(null);
  const [ isLoginVisible, setLoginVisible ] = React.useState(true);

  React.useEffect(() => {
    fireDb.collection('obituarios').orderBy('sepelioFecha', 'desc').get().then(snapshot => {
      let array = snapshot.docs.map(doc => {
        return { id: doc.id, ...doc.data() }
      });
      setObituarios(array);
    });
  }, [dataSet]);

  const newObituario = () => {
    showDialog(<ObituarioForm updateDataSet={updateDataSet} showSnackbar={showSnackbar} showDialog={showDialog}/>);
  }

  const editObituario = (obituario) => {
    showDialog(<ObituarioForm updateDataSet={updateDataSet} toEdit={obituario} showSnackbar={showSnackbar} showDialog={showDialog}/>);
  }

  const handleInputChange = event => {
    setUser({
      ...user,
      [event.target.name]: event.target.value, 
    });
    setInputError({ email: false, password: false });
  }

  const loginError = () => {
    setInputError({ email: true, password: true });
    showSnackbar("Usuario y/o contraseña incorrectos", "error");
    setLoginEnabled(true);
  }

  const handleLogin = event => {
    setLoginEnabled(false);

    fireDb.collection('users')
      .where('email', '==', user.email)
      .where('password', '==', user.password)
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          loginError();
          return;
        }

        snapshot.docs.forEach(doc => {
          setUser(doc.data());
        });

        setLoginEnabled(true);
        setLoginVisible(false);
      })
      .catch(err => {
        loginError();
    });
  }

  const closeSession = () => {
    showSnackbar("Sesión finalizada", "info");
    setUser({ email: "", password: "" });
    setInputError({ email: false, password: false });
    setLoginEnabled(true);
    setLoginVisible(true);
  }

  return (
    <Box>
      { !isLoginVisible ? 
        <React.Fragment>
          <AdminAppBar closeSession={closeSession} />
          <Box mt={10}>
            <Grid item container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item container>
                { obituarios.length > 0 ? 
                  <Box width={1} mx={cardMargin} mb={cardMargin} mt={cardMargin}>
                    <ThemeProvider theme={themeTable}>
                      <Card 
                        elevation={2}
                        style={{
                          backgroundColor: "#ffffffcc",
                          borderRadius: 12
                        }}
                      >
                        <CardContent style={{ padding: 0 }}>
                          <ObituariosTable obituarios={obituarios} newObituario={newObituario} editObituario={editObituario} />
                        </CardContent>
                      </Card>
                    </ThemeProvider>
                  </Box>
                :
                  <Grid item container xs={12}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <TextView variant="h4" align="center" my={4}>
                        Obituarios
                      </TextView>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={newObituario}
                      >
                        Agregar
                      </Button>
                    </Grid>
                  </Grid>
                }
              </Grid>
            </Grid>
          </Box>
        </React.Fragment>
      :
        <Box mt={10}>
          <Grid item container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <Card 
                elevation={2}
                style={{
                  backgroundColor: "#ffffffcc",
                  borderRadius: 12
                }}
              >
                <CardContent>
                  <Grid item container>
                    <Grid 
                      container
                      direction="column"
                      spacing={2}
                    >
                      <Grid item>
                        <Typography variant="h4">
                          SM-Admin Login
                        </Typography>
                      </Grid>
              
                      <Box width={1} my={2} />
              
                      <Grid item>
                        <TextField 
                          name="email"
                          type="email"
                          value={user.email}
                          error={inputError.email}
                          label="Usuario"
                          variant="outlined" 
                          fullWidth
                          onChange={handleInputChange} 
                        />
                      </Grid>
                      <Grid item>
                        <TextField 
                          name="password"
                          type="password"
                          value={user.password}
                          error={inputError.password}
                          label="Contraseña"
                          variant="outlined" 
                          fullWidth
                          onChange={handleInputChange} 
                        />
                      </Grid>
                      
                      <Box width={1} my={2} />

                      <Grid item>
                        <Button 
                          color="primary"
                          fullWidth
                          variant={user.email.length >= 6 && user.password.length >= 4 ? "contained" : "outlined"}
                          disabled={!loginEnabled || user.email.length < 6 || user.password.length < 4}
                          onClick={handleLogin}
                        >
                          Entrar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      }
    </Box>
  );
}

function AdminAppBar({ closeSession }) {
  return (
    <HeaderBar
      logo="/image/sm_logo_graydark.png"
      rightLinks={<HeaderLinks closeSession={closeSession} />}
      fixed
      color="secondary"
    />
  );
}
