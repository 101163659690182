import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    maxHeight: "96vh"
  },
  media: {
    width: "100%",
    height: "100%",
  }
});

function BannerSection() {
  const classes = useStyles();
  let windowScrollTop;
  if (window.innerWidth >= 768) {
    windowScrollTop = window.pageYOffset / 3;
  } else {
    windowScrollTop = 0;
  }
  const [transform, setTransform] = React.useState(
    "translate3d(0," + windowScrollTop + "px,0)"
  );
  React.useEffect(() => {
    if (window.innerWidth >= 768) {
      window.addEventListener("scroll", resetTransform);
    }
    return function cleanup() {
      if (window.innerWidth >= 768) {
        window.removeEventListener("scroll", resetTransform);
      }
    };
  });
  const resetTransform = () => {
    var windowScrollTop = window.pageYOffset / 3;
    setTransform("translate3d(0," + windowScrollTop + "px,0)");
  };

  return (
    <Grid container xs style={{ transform: transform }}>

      <Card className={classes.root}>
        <CardMedia
          className={classes.media}
          component="video"
          src="/video/nosotros_bg_video.mp4"
          autoPlay 
          loop 
          muted
        />
      </Card>
        
    </Grid>
  );
}

export default BannerSection;
