import React from 'react';

// @material-ui/core components
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';

// Views
import FooterNormal from './FooterNormal';
import FooterSmall from './FooterSmall';

function Footer({ background="" }) {
  return (
    <Box
      style={{
        background: background
      }}
    >
      <Hidden smDown>
        <FooterNormal />
      </Hidden>
      <Hidden mdUp>
        <FooterSmall />
      </Hidden>
    </Box>
  );
}

export default Footer;
