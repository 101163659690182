import React from 'react';

// @material-ui/core components
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Fab from "@material-ui/core/Fab";

import WhatsAppWidget from 'react-whatsapp-widget'
import 'react-whatsapp-widget/dist/index.css'

// @material-ui/icons
import MessageIcon from '@material-ui/icons/WhatsApp';

function FabButton() {
  return (

      <Fab
        color="primary"
        href='https://wa.me/+526671901950'
        target="WhatsApp"
        style={{
          background: "#25D366",
          margin: 0,
          top: 'auto',
          right: 24,
          bottom: 24,
          left: 'auto',
          position: 'fixed',
          zIndex: "8"
        }}
      >
        <MessageIcon/>
      </Fab>

  );
}

/*function FabButton() {
  return (
    <Hidden mdUp>
      <Box
        style={{
          margin: 0,
          top: 'auto',
          right: -12,
          bottom: -12,
          left: 'auto',
          position: 'fixed',
          zIndex: "8"
        }}
      >
        <WhatsAppWidget 
          phoneNumber={"+526673170000"}
          companyName="San Martín"
          textReplyTime=""
          message={"Hola!\n¿En qué podemos ayudarle?"}
          placeholder="Escribe un mensaje"
          sendButton="Envíar" />
      </Box>
    </Hidden>
  );
}*/

export default FabButton;
