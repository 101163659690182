import React from 'react';

// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";

// Components
import TextView from '../../../components/TextView';

function SmDescriptionSection() {
  return (
    <Box>
      <Hidden smDown>
        <Box mt={8} mx={4}>
          <Grid container justify="center" spacing={4}>
            <Grid item container md={4} lg={4}
              direction="column"
              justify="center"
              alignItems="center" 
            >
              <img src="/image/Paloma-02 (1) (1).png" width="90%" alt="" style={{ maxWidth: 256 }} />
            </Grid>

            <Grid item container md={7} lg={7}
              direction="column"
              justify="center"
              alignItems="flex-start"
            >
              <TextView variant="h4" textColor="#35647D" fontWeight={500}>
                Grupo San Martín, tu mejor inversión en los momentos difíciles.
              </TextView>
              <TextView  mt={3}
                variant="subtitle1"
                align="justify"
              >
                <p>
                  Con más de 130 años de experiencia, Grupo San Martín ofrece calidez y trato humano a quienes se acompañan de nuestros servicios en esos momentos inevitables. Nos encargamos de brindar a las familias tranquilidad, privacidad y comodidad que necesitan para despedir y honrar la memoria de aquellos que hoy viven en nuestro corazón.
                </p>
              </TextView>
            </Grid>
          </Grid>
        </Box>
      </Hidden>

      <Hidden mdUp>
        <Box mt={4} mx={4}>
          <Grid item container xs={12} sm={12}
            direction="column"
            justify="center"
            alignItems="center"
          >
            <TextView variant="h4" textColor="#326295" fontWeight={500} mb={4} textAlign="center">
              Grupo San Martín, tu mejor opción en los momentos de crisis.
            </TextView>

            <Grid item xs={12} sm={8}>
              <img src="/image/home_logo.png" width="100%" alt="" style={{ maxWidth: 160 }}/>
            </Grid>

            <TextView  mt={3}
              variant="subtitle1"
              align="justify"
            >
              <p>
                Con más de 130 años de experiencia, Grupo San Martín ofrece calidez y trato humano a quienes se acompañan de nuestros servicios en esos momentos inevitables. Nos encargamos de brindar a las familias tranquilidad, privacidad y comodidad que necesitan para despedir y honrar la memoria de aquellos que hoy viven en nuestro corazón.
              </p>
            </TextView>
          </Grid>
        </Box>
      </Hidden>

    </Box>
  );
}

export default SmDescriptionSection;
