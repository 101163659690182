import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

// @material-ui/core components
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Hidden from '@material-ui/core/Hidden';
import FabButton from "./views/FabButton";

// Pages
import HomePage from './pages/home/HomePage';
import LocationsPage from './pages/locations/LocationsPage';
import AboutUsPage from './pages/about/AboutUsPage';
import PaymentLoginPage from './pages/payment/PaymentLoginPage';
import FueraDeServicioPage from './pages/about/sections/FueraDeServicio';
import FueraDeHorarioPage from './pages/about/sections/FueraDeHorario';
import PaymentDetailsPage from './pages/payment/PaymentDetailsPage';
import PaymentResumePage from './pages/payment/PaymentResumePage';
import AdminPage from './pages/admin/AdminPage';
import ProductFormPage from './pages/product_form/ProductFormPage';
import ResultFormPage from './pages/product_form/ResultFormPage';
import BuyPlanPage from './pages/buy_plan/BuyPlanPage';
import BuyerFormPage from './pages/buy_plan/BuyerFormPage';
import PurchaseDetailsPage from './pages/buy_plan/PurchaseDetailsPage';
import WhatsAppPage from './pages/whatsapp/WhatsAppPage';
import PlanesPage from './pages/planes/PlanesPage';
import PlanParaisoPage from './pages/planes/landing/PlanParaisoPage';

// Views
import AppBar from './views/AppBar';
import Footer from './views/Footer';

import firebase, { fireDb } from './Firebase';

import { sendReporteVisitasEmail } from './Api';
import Recline from "./assets/fonts/recline.ttf";
import VolvoNovumRegular from "./assets/fonts/volvo_novum.ttf";

const volvoNovum = {
  fontFamily: 'Volvo Novum Regular',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${VolvoNovumRegular}) format('truetype')
  `,
};

const recline_ = {
  fontFamily: 'Recline',
  fontStyle: 'regular',
  fontDisplay: 'swap',
  fontWeight: 400,
  src:`
  url(${Recline}) format('truetype')
`,
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#35647D'
    }
  },
  typography: {
    fontFamily: [
      'Recline',
      'Volvo Novum Regular',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [recline_],
      },
    }
  }
});

const DIA_LUNES = 1;
const SLASH_ESC = "&#x2F;";
const PERIOD_ESC = "&#46;";
const VALID_PATHS = [
  "/",
  "/inicio",
  "/sucursales",
  "/nosotros",
  "/payment",
  "/payment/details",
  "/payment/resume",
  //"/simulador-planes",
  //"/simulador-resultado",
  //"/comprar-plan",
  "/datos-de-contacto",
  "/compra/resumen",
  "/whatsapp",
  //"/planes-y-productos",
  //plan/tranquilidad"
];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function getLocalDateString(date = new Date()) {
  let dateStr = date.toLocaleDateString("en-US", {timeZone: "America/Mazatlan"});
  let dateArr = dateStr.split('/');

  if (dateArr[0].length == 1) {
    dateArr[0] = '0' + dateArr[0];
  }
  if (dateArr[1].length == 1) {
    dateArr[1] = '0' + dateArr[1];
  }

  let localDate = `${dateArr[2]}-${dateArr[0]}-${dateArr[1]}`;
  return localDate;
}

export function getScreenRange() {
  const { width } = getWindowDimensions();
  if (width < 600) return "xs";
  if (width >= 600 && width < 960) return "sm";
  if (width >= 960 && width < 1280) return "md";
  return "lg";
}

export const XS = 1;
export const SM = 2;
export const MD = 3;
export const LG = 4;

export function getScreenRangeInt() {
  const { width } = getWindowDimensions();
  if (width < 600) return XS;
  if (width >= 600 && width < 960) return SM;
  if (width >= 960 && width < 1280) return MD;
  return LG;
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

async function loadVisitasDocs(dateRange) {
  let currentDayId = getLocalDateString(new Date());
  let visitas = [];

  let enviarCorreoVisitas = await fireDb.collection('reporteVisitas').doc(currentDayId).get().then(response => {
    return !response.exists;
  }).catch(error => {
    return false;
  });

  if (!enviarCorreoVisitas) {
    return;
  }

  fireDb.collection("reporteVisitas").doc(currentDayId).set({ 
    enviado: true
  });

  for (let i = 0; i < dateRange.length; i++) {
    let dayId = getLocalDateString(dateRange[i]);

    let itemVisita = await fireDb.collection('visitas').doc(dayId).get().then(response => {
      if (!response.exists) {
        return null;
      }
      let snapshop = response.data();
      let itemDay = {
        day: dayId,
        pages: []
      }
      for (let pageId in snapshop) {
        let pageIdEsc = pageId
                          .replaceAll(SLASH_ESC, '/')
                          .replaceAll(PERIOD_ESC, '.');
        let pageItem = {
          id: pageIdEsc,
          total: snapshop[pageId].length,
          data: snapshop[pageId]
        }
        itemDay.pages.push(pageItem);
      }
      
      return itemDay;
    }).catch(error => {
      console.log(error);
    });

    if (itemVisita) {
      visitas.push(itemVisita);
    }
  }

  processVisitasUnicas(visitas);
  sendReporteVisitasEmail(visitas);
}

function processVisitasUnicas(visitas) {
  for (let i = 0; i < visitas.length; i++) {
    let day = visitas[i].day;
    let pages = visitas[i].pages;
    for (let j = 0; j < pages.length; j++) {
      let map = new Map();
      let itemPage = pages[j];
      let visitaUnica = 0;
      for (let k = 0; k < itemPage.data.length; k++) {
        let itemVisita = itemPage.data[k];
        if (!map.has(itemVisita.userId)) {
          visitaUnica++;
          map.set(itemVisita.userId);
        }
      }
      itemPage.totalUnicas = visitaUnica;
    }
  }
}

function App() {
  const [snackbarData, setSnackbarContent] = React.useState({
    open: false,
    message: "",
    severity: "normal",
    duration: 3000
  });
  const [fabVisible, setFabVisible] = React.useState(true);

  const handleSnackbarClose = (event, reason) => {
    setSnackbarContent({
      ...snackbarData,
      open: false
    });
  };

  const showSnackbar = (message, severity="normal", duration=3000) => {
    setSnackbarContent({
      open: true,
      message: message,
      severity: severity,
      duration: duration
    });
  }

  const [dialogData, setDialogData] = React.useState({
    open: false
  });

  const handleDialogClose = () => {
    setDialogData({
      ...dialogData,
      open: false
    });
  };

  const showMessageDialog = (title, message, positiveLabel, negativeLabel, positiveAction, negativeAction, width=100) => {
    setDialogData({
      open: true,
      title: title,
      message: message,
      positiveLabel: positiveLabel,
      negativeLabel: negativeLabel,
      positiveAction: positiveAction,
      negativeAction: negativeAction,
      width: width
    });
  }

  const showCustomDialog = (children, title, positiveLabel, negativeLabel, positiveAction, negativeAction, width=100) => {
    setDialogData({
      open: children,
      children: children,
      title: title,
      positiveLabel: positiveLabel,
      negativeLabel: negativeLabel,
      positiveAction: positiveAction,
      negativeAction: negativeAction,
      width: width
    });
  }

  const [appBarState, setAppBarState] = React.useState(false);
  
  const [dayId, setDayId] = React.useState(getLocalDateString());
  const [userId, setUserId] = React.useState(null);
  const [dateRange, setDateRange] = React.useState(null);

  React.useEffect(() => {
    let currentDate = new Date();
    let lastUserId = localStorage.getItem('userId');

    setUserId(lastUserId ? lastUserId : uuidv4());

    if (currentDate.getDay() === DIA_LUNES && currentDate.getHours() >= 13) {
      let lastDays = [];

      for (let i = 1; i <= 7; i++) {
        let tmp = new Date();
        tmp.setDate(currentDate.getDate() - i);
        lastDays.push(tmp);
      }

      setDateRange(lastDays);
    }
  }, []);

  React.useEffect(() => {
    if (!userId) return;

    localStorage.setItem('userId', userId);
    let pageId = window.location.pathname;
    pageId = (pageId === '/') ? "/inicio" : pageId;

    if (pageId.charAt(pageId.length - 1) === '/') {
      pageId = pageId.substring(0, pageId.length - 1);
    }

    if (!VALID_PATHS.includes(pageId)) {
      return;
    }

    pageId = pageId.replaceAll('/', SLASH_ESC);
    pageId = pageId.replaceAll('.', PERIOD_ESC);

    let visita = {
      userId: userId,
      href: window.location.href,
      pathname: window.location.pathname,
      search: window.location.search,
      host: window.location.host,
      origin: window.location.origin,
      date: firebase.firestore.Timestamp.fromDate(new Date())
    };

    fireDb.collection("visitas").doc(dayId).get()
    .then(response => {
      if (response.exists) {
        fireDb.collection("visitas").doc(dayId).update({ 
          [pageId]: firebase.firestore.FieldValue.arrayUnion(visita)
        });
      } else {
        fireDb.collection("visitas").doc(dayId).set({ 
          [pageId]: firebase.firestore.FieldValue.arrayUnion(visita)
        });
      }
    });
    
  }, [userId]);

  React.useEffect(() => {
    if (!dateRange) return;
    loadVisitasDocs(dateRange);
  }, [dateRange]);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/" exact>
            <AppBar primaryWhite appBarState={appBarState}/>
            <Box
              style={{
                background: "#f0f0f0"
              }}
            >
              <HomePage setAppBarState={setAppBarState} showSnackbar={showSnackbar}/>
              <Footer />
            </Box>
            <FabButton />
          </Route>
          <Route path="/sucursales" exact>
            <AppBar  />
            <LocationsPage/>
            <Footer/>
            <FabButton />
          </Route>
          <Route path="/nosotros" exact>
            <AppBar main />
            <AboutUsPage/>
            <Footer/>
            <FabButton />
          </Route>
          <Route path="/payment" exact>
            <AppBar />
            <PaymentLoginPage 
              showSnackbar={showSnackbar}
              showDialog={showCustomDialog} />
            <FabButton />
          </Route>
          <Route path="/FueradeLinea" exact>
            <AppBar />
            <FueraDeServicioPage />
            <FabButton />
          </Route>
          <Route path="/FueraDeHorario" exact>
            <AppBar />
            <FueraDeHorarioPage />
            <FabButton />
          </Route>
          <Route path="/payment/details" exact>
            <PaymentDetailsPage 
              showSnackbar={showSnackbar}
              showCustomDialog={showCustomDialog} />
            <FabButton />
          </Route>
          <Route path="/payment/resume" exact>
            <PaymentResumePage 
              showSnackbar={showSnackbar} />
            <FabButton />
          </Route>
      {/*
          <Route path="/simulador-planes" element={<Navigate to="/" replace />} />
          <Route path="/simulador-resultado" exact>
            <ResultFormPage
              showSnackbar={showSnackbar}
              showCustomDialog={showCustomDialog} />
          </Route>
          <Route path="/comprar-plan" exact>
            <AppBar white />
            <BuyPlanPage
              showSnackbar={showSnackbar}
              showCustomDialog={showCustomDialog} />
            </Route>*/}
          <Route path="/datos-de-contacto" exact>
            <AppBar white />
            <BuyerFormPage
              showSnackbar={showSnackbar}
              showCustomDialog={showCustomDialog} />
          </Route>
          <Route path="/compra/resumen" exact>
            <AppBar white />
            <PurchaseDetailsPage
              showSnackbar={showSnackbar}
              showCustomDialog={showCustomDialog} />
          </Route>
          <Route path="/whatsapp" exact>
            <AppBar main />
            <WhatsAppPage/>
            <Footer background="#ECE5DD"/>
          </Route>
          {/*<Route path="/planes-y-productos" exact>
            <AppBar />
            <PlanesPage
              showSnackbar={showSnackbar}
              showCustomDialog={showCustomDialog}/>
            </Route>
          <Route path="/plan/tranquilidad" exact>
            <AppBar transparentPrimary />
            <PlanParaisoPage
              showSnackbar={showSnackbar}
              showCustomDialog={showCustomDialog}/>
          </Route>*/}
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>

        <Snackbar 
          open={snackbarData.open} 
          autoHideDuration={snackbarData.duration} 
          onClose={handleSnackbarClose}
          message={snackbarData.severity === "normal" ? snackbarData.message : ""}
          style={{ marginBottom: 24 }}
        >
          {snackbarData.severity === "normal" ? (
            null
          ) : (
            <Alert
              severity={snackbarData.severity} 
              onClose={handleSnackbarClose}
            >
              {snackbarData.message}
            </Alert>
          )}
        </Snackbar>

        <Dialog style={{ width: `${dialogData.width}%`, left: `${50 - (dialogData.width / 2)}%` }}
          open={dialogData.open}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          { dialogData.title ?
            <DialogTitle id="alert-dialog-title">{dialogData.title}</DialogTitle>
          :
            null
          }
          <DialogContent>
            { dialogData.message ?
              <DialogContentText id="alert-dialog-description">
                {dialogData.message}
              </DialogContentText>
            :
              null
            }
            { dialogData.children ?
              dialogData.children
            :
              null
            }
          </DialogContent>
          <DialogActions>
            {dialogData.negativeLabel ? (
              <Button 
                color="primary"
                onClick={!dialogData.negativeAction ? handleDialogClose : dialogData.negativeAction} 
              >
                {dialogData.negativeLabel}
              </Button>
            ) : (
              null
            )} 

            {dialogData.positiveLabel ? (
              <Button 
                color="primary" 
                autoFocus
                onClick={!dialogData.positiveAction ? handleDialogClose : dialogData.positiveAction} 
              >
                {dialogData.positiveLabel}
              </Button>
            ) : (
              null
            )} 

          </DialogActions>
        </Dialog>

      </Router>
    </ThemeProvider>
  );
}

export default App;
