import React from 'react';

// @material-ui/core components
import { createMuiTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

// Components
import TextView from '../../../components/TextView';
import { getScreenRange } from '../../../App';

const servicesData = [
  {
    title: "Previsión",
    description: (<p>Contratar un plan funerario es sencillo, lo pagas poco a poco y adquirir un plan funeral es una decisión inteligente, es previsión y protección para ti y tu familia.</p>),
    icon: "/image/ic_service_2.png"
  },
  {
    title: "Funeraria",
    description: (<p>Brindamos a las familias la tranquilidad, privacidad y comodidad que necesita para la despedida del ser querido, acompañado de un servicio cálido, profesional y con trato humano.</p>),
    icon: "/image/ic_service_3.png"
  },
  {
    title: "Parque Funeral",
    description: (<p>Un panteón estilo americano que brinda un ambiente de tranquilidad, ideal para visitar en familia a quienes ya partieron.<br/>Parque Funeral San Martín cuenta con criptas, nichos y mausoleos.<br/>Los servicios adicionales de parque funeral: Grabado láser de placas, limpieza de placas, venta de floreros, renta de carpas y sillas.</p>),
    icon: "/image/ic_service_4.png"
  },
  {
    title: "Diamante memorial",
    description: (<p>Rinde el mejor homenaje a la vida de tu ser querido con este gran gesto de amor que te permitirá conservar, para siempre, su esencia de la forma más bella y pura.
      <br/>Esta magnífica y única pieza, se da mediante el proceso de diamantización de las cenizas de un ser querido (una parte de las cenizas o cabello).</p>),
    icon: "/image/ic_service_5.png"
  },
  {
    title: "Florería",
    description: (<p>Ofrecemos arreglos florales para cualquier evento. Contamos con amplia variedad de productos, con flores de calidad.</p>),
    icon: "/image/ic_service_6.png"
  }
]

const theme = createMuiTheme({spacing: 6});

function ServiceItem({ title, description, icon }) {
  return (
    <Grid item container xs={12} spacing={2}
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid container xs={2} md={2}
        direction="column"
        justify="flex-start"
        alignItems="flex-end"
      >
        <img src={icon} height={32} alt="" />
      </Grid>
      <Grid container xs={8} md={9}
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        style={{
          marginLeft: theme.spacing(3),
          marginRight: theme.spacing(1)
        }}
      >
        <TextView variant="h5" align="left">
          {title}
        </TextView>
        <TextView variant="body2" align="justify" mb={4}>
          {description}
        </TextView>
      </Grid>
    </Grid>
  );
}

function OurServicesSection() {
  const range = getScreenRange();
  const imgServicePath = "/image/" + range + "/our_services.png";

  return (
    <Grid item container xs>

      <Grid item xs={12}
        direction="row"
        justify="center"
      >
        <TextView variant="h4" align="center" mb={8} textColor="#35647D" fontWeight={500}>
          Nuestros Servicios
        </TextView>
      </Grid>

      <Hidden mdUp>
        <Grid item container
          justify="center" 
          alignItems="center"
          style={{
            marginBottom: theme.spacing(6)
          }}
        >
          <img src={imgServicePath} width="auto" style={{maxHeight: 300}} alt="" />
        </Grid>
      </Hidden>

      <Grid container xs={12} sm={12} md={6}>
        { servicesData.map((item, index) => 
            <ServiceItem 
              key={index}
              title={item.title}
              description={item.description}
              icon={item.icon}
            />
          ) 
        }
      </Grid>

      <Hidden smDown>
        <Grid item container md={6}
          justify="center" 
          alignItems="center" 
        >
          <img src={imgServicePath} width="auto" style={{maxHeight: 420}} alt="" />
        </Grid>
      </Hidden>
      
    </Grid>
  );
}

export default OurServicesSection;
