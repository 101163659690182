import React from 'react';

// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Hidden from '@material-ui/core/Hidden';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';

// Components
import CardView from '../../../components/CardView';
import TextView from '../../../components/TextView';
import { planes, parque } from '../../../ArraysDB';
import { useWindowDimensions } from '../../../App';

const money = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
});

function PlanDetails({ plan }) {
  const [selectIndex, setSelectIndex] = React.useState(0);
  const [price, setPrice] = React.useState(0);

  React.useEffect(() => {
    let index = localStorage.getItem(`${plan.name}Index`);
    setSelectIndex(index !== null ? index : plan.pagosConAnticipo.length - 1);
  }, []);

  React.useEffect(() => {
    localStorage.setItem(`${plan.name}Index`, selectIndex);
  }, [selectIndex]);

  const handleSelectChange = (event) => {
    setSelectIndex(event.target.value);
  };

  return (
    <Grid item container xs={12}
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid item>
        <img 
          src={plan.logoPrimary} 
          alt="" 
          width="40%"
          style={{ 
            display: "block",
            marginLeft: "auto",
            marginRight: "auto"
          }}/>
      </Grid>
      <Grid item>
        <TextView variant="h6" textColor="#424242" mt={2}>
          Características
        </TextView>
      </Grid>
      <Grid item>
        <ul>
          <TextView textColor="#777777">
            {plan.description.map((option, key) => (
              <li key={key} style={{ marginBottom: 2 }}>{option}</li>
            ))}
          </TextView>
        </ul>
      </Grid>
      <Grid item container>
        <TextField
          select
          name="select"
          label="Plazo"
          variant="outlined" 
          fullWidth
          value={selectIndex}
          onChange={handleSelectChange}
        >
          {plan.pagosConAnticipo.map((item, key) => (
            <MenuItem key={key} value={key}>
              {item.plazo} meses
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item container justify="flex-end">
        <TextView variant="h6" textColor="#777777" textAlign="right" fontWeight={450} fullWidth mt={2}>
          {`Total: ${money.format(plan.pagosConAnticipo[selectIndex].quincenal.total)}`}
        </TextView>
      </Grid>
      <Grid item container justify="flex-end">
        <TextView variant="h6" textColor="#424242" textAlign="right" fullWidth>
          {`Anticipo: ${money.format(plan.pagosConAnticipo[selectIndex].quincenal.primerPago)}`}
        </TextView>
      </Grid>
      <Grid item container justify="flex-end">
        <TextView variant="h6" textColor="#777777" textAlign="right" fontWeight={450} fullWidth>
          {`Pago quincenal: ${money.format(plan.pagosConAnticipo[selectIndex].quincenal.pago)}`}
        </TextView>
      </Grid>
    </Grid>
  );
}

export default PlanDetails;