import React from 'react';

// @material-ui/core components
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

// @material-ui/icons
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YoutubeIcon from '@material-ui/icons/YouTube';
import PhoneIcon from '@material-ui/icons/Phone';

// Components
import TextView from '../components/TextView';

const theme = createMuiTheme({spacing: 4});

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: "#e5e5e5",
    borderRadius: theme.spacing(4, 4, 0, 0)
  }
});

function FooterNormal() {
  const classes = useStyles();

  return (
    <Card
      className={classes.root}
    >
      <CardContent>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
        >

          <Grid container 
            direction="row"
            alignItems="flex-start"
            spacing={2}
            style={{
              marginBottom: theme.spacing(4)
            }}
          >

            <Grid item xs={6} sm>
              <Grid item>
                <Typography variant="h6" align="left">
                  Sucursal Montebello
                  <IconButton href="tel:6677128000">
                    <PhoneIcon/>
                  </IconButton>
                </Typography>
              </Grid>
              <Grid item>
                <TextView>
                  <Link 
                    color="inherit" 
                    target="Maps"
                    href="https://goo.gl/maps/5WSVN6esHCJdiXrY6"
                  >
                    Calle Montebello 22 Oriente, Col. Montebello, 80227 Culiacán Rosales, Sin.
                  </Link>
                </TextView>
              </Grid>
            </Grid>

            <Grid item container sm={5}
              direction="column"
              justify="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item container xs={12}
                direction="row"
                justify="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <IconButton 
                    href="https://www.facebook.com/sanmartinGF"
                    target="Facebook"
                  >
                    <FacebookIcon fontSize="large" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton 
                    href="https://www.instagram.com/sanmartingf" 
                    target="Instagram"
                  >
                    <InstagramIcon fontSize="large" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton 
                    href="https://www.youtube.com/channel/UC94sz-VOwXpSOsrKIjGzdcw" 
                    target="Youtube"
                  >
                    <YoutubeIcon fontSize="large" />
                  </IconButton>
                </Grid>
              </Grid>

              <Grid item container justify="center" xs={12}>
                <Typography variant="caption" align="center">
                  <Link 
                    color="inherit" 
                    target="Maps"
                    href="tel:6673369128"
                  >
                    Los Mochis Teléfono: (667) 336 9128
                  </Link>
                  <br/>
                  <Link 
                    color="inherit" 
                    target="Maps"
                    href="https://goo.gl/maps/q8X5m9aXUC3ry2QCA"
                  >
                    Ote. Blvd. Rosendo G. Castro Pte. 495, Centro, 81200
                  </Link>
                </Typography>
              </Grid>
            </Grid>

            <Grid item container sm
              direction="column"
              justify="center"
              alignItems="flex-end"
            >
              <Grid item>
                <Typography variant="h6" align="right">
                  <IconButton href="tel:6677128000">
                    <PhoneIcon/>
                  </IconButton>
                  Sucursal Zapata
                </Typography>
              </Grid>
              <Grid item>
                <TextView align="right">
                  <Link 
                    color="inherit" 
                    target="Maps"
                    href="https://goo.gl/maps/jzdKHSAahkcpvhHQA"
                  >
                    Blvd. Emiliano Zapata 145, Guadalupe, Jorge Almada, 80220 Culiacán Rosales, Sin.
                  </Link>
                </TextView>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container 
            direction="row"
            alignItems="flex-end"
            spacing={4}
          >
            <Grid item xs container direction="column" alignItems="flex-end">
              <Typography variant="caption" align="right">
                <Link 
                  color="inherit" 
                  target="Maps"
                  href="tel:6677165939"
                >
                  OFICINAS CORPORATIVAS Teléfono: (667) 716 59 39
                </Link>
                <br/>
                <Link 
                  color="inherit" 
                  target="Maps"
                  href="https://goo.gl/maps/kfXZR6APSmfwCfpT6"
                >
                  Domingo Rubí 836 Sur. Col. Guadalupe, Culiacán, Sinaloa, México. C.P. 80220
                </Link>
              </Typography>
            </Grid>

            <Grid item xs container
              justify="flex-start"
            >
              <TextView>
                &copy; 2020, hecho por <Link color="inherit" target="Ryactiv" href="https://ryactiv.com">Ryactiv</Link>
              </TextView>
            </Grid>
          </Grid>

        </Grid>
      </CardContent>
    </Card>
  );
}

export default FooterNormal;
