import React from 'react';

// @material-ui/core components
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Fade from '@material-ui/core/Fade';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIosSharp';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIosSharp';

// Sections
import BannerSection from './sections/BannerSection';
//import AfiliadoSM from './sections/AfiliadoSM';
import SmDescriptionSection from './sections/SmDescriptionSection';
import OurServicesSection from './sections/OurServicesSection';
import HiringExampleSection from './sections/HiringExampleSection';
import PayMethodsSection from './sections/PayMethodsSection';
import ContactUsSection from './sections/ContactUsSection';

// Components
import Parallax from '../../components/Parallax';
import { useWindowDimensions, getScreenRange, getScreenRangeInt, XS, SM, MD, LG } from '../../App';
import { config } from '../../Api';
import Carousel from '../../components/Carousel';
import GalleryCarousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import { obtenerObituarios } from "../../Api";

const HORIZONTAL_VIDEOS = [
  "/video/web.mp4"
  // "/video/plan_tranquilidad/horizontal_carrusel_carro.mp4",
  // "/video/plan_tranquilidad/horizontal_carrusel_carrera.mp4",
  // "/video/plan_tranquilidad/horizontal_carrusel_casa.mp4",
  // "/video/plan_tranquilidad/horizontal_carrusel_negocio.mp4",
  // "/video/plan_tranquilidad/horizontal_carrusel_vacaciones.mp4"
];
const VERTICAL_VIDEOS = [
  "/video/web.mp4"
  // "/video/plan_tranquilidad/vertical_carrusel_carro.mp4",
  // "/video/plan_tranquilidad/vertical_carrusel_carrera.mp4",
  // "/video/plan_tranquilidad/vertical_carrusel_casa.mp4",
  // "/video/plan_tranquilidad/vertical_carrusel_negocio.mp4",
  // "/video/plan_tranquilidad/vertical_carrusel_vacaciones.mp4"
];

async function loadObituarios(setObituarios) {
  let response = await obtenerObituarios();
  let data = response.data;

  if (!response.result || response.error || data == null || data.length === 0) {
    setObituarios([]);
  } else {
    setObituarios(data);
  }
}

function VideoBanner({ video, btnSize, btnMarginTop, btnMarginLeft }) {
  return (
    <Box>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{
          top: 0
        }}
      >
        <Card
          elevation={0}
          style={{
            width: "100%",
            height: "100vh",
            background: "#f0f0f0"
          }}
        >
          <CardActionArea 
            //onClick={(e) => window.location.href='/plan/tranquilidad'} 
            style={{
              width: "100%",
              height: "100%"
            }}
          >
            <video 
              width="100%" 
              height="100%"
              muted
              autoPlay 
              loop 
              playsInline
            >
              <source src={video} type="video/mp4"/>
            </video>
          </CardActionArea>

          <Hidden xsDown>
            <Box
              mt={btnMarginTop}
              ml={btnMarginLeft}
            >
              
            </Box>
          </Hidden>

          <Hidden smUp>
            <Box mt={btnMarginTop} width={1}>
              <Grid xs={12}
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Button 
                  variant="contained" 
                  size={btnSize}
                  style={{
                    background: "#35647D",
                    color: "#ffffff"
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href='/plan/tranquilidad';
                  }}
                >
                  COMPRAR
                </Button>
              </Grid>
            </Box>
          </Hidden>

        </Card>

      </Grid>
    </Box>
  );
}

function GalleryCarouselSection({ obituarios, setAppBarState, videos }) {
  let showObituarios = obituarios.length > 0;
  //setAppBarState(!showObituarios);
  const handlerOnIndexChange = ({curIndex, curIndexForDisplay}) => {
    if (curIndex > 0) {
      setAppBarState(true);
    } else {
      setAppBarState(!showObituarios);
    }
  }
  const imageBanner = "/image/bg_home_header_24.png";
  const range = getScreenRangeInt();
  const dimension = useWindowDimensions();

  let btnMarginTop = 0;
  let btnMarginLeft = 0;
  let btnSize = "";

  if (range === XS) {
    btnMarginTop = -24;
    btnMarginLeft = 0;
    btnSize = "medium";
  }
  if (range === SM) {
    btnMarginTop = -(dimension.height * 0.053);
    btnMarginLeft = dimension.width * 0.016;
    btnSize = "small";
  }
  if (range === MD) {
    btnMarginTop = -(dimension.height * 0.05);
    btnMarginLeft = dimension.width * 0.016;
    btnSize = "medium";
  }
  if (range === LG) {
    btnMarginTop = -(dimension.height * 0.0395);
    btnMarginLeft = dimension.width * 0.0158;
    btnSize = "large";
  }

  const [carouselChildren, setCarouselChildren] = React.useState([
    <BannerSection 
      imageBanner={imageBanner} 
      obituarios={obituarios}/>,
    ...videos.map(v => 
      <VideoBanner
        video={v}
        btnSize={btnSize}
        btnMarginTop={btnMarginTop}
        btnMarginLeft={btnMarginLeft} />
    )
  ]);

  const [carouselChildrenOnlyVideos, setCarouselChildrenOnlyVideos] = React.useState(
    videos.map(v => 
      <VideoBanner
        video={v}
        btnSize={btnSize}
        btnMarginTop={btnMarginTop}
        btnMarginLeft={btnMarginLeft} />
    )
  );

  if (carouselChildren) {
    if (!showObituarios) {
      return (
        <Grid container xs>
          <GalleryCarousel 
            isAutoPlaying={true}
            autoPlayInterval={30000}
            hasMediaButton={false}
            hasSizeButton={false}
            hasIndexBoard={false}
            hasDotButtons="bottom"
            hasThumbnails={false}
            leftIcon={(<ArrowBackIcon/>)}
            rightIcon={(<ArrowForwardIcon/>)}
            children={carouselChildrenOnlyVideos}
            onIndexChange={handlerOnIndexChange}/>
        </Grid>
      );
    }
    return (
      <Grid container xs>
        <GalleryCarousel 
          isAutoPlaying={true}
          autoPlayInterval={30000}
          hasMediaButton={false}
          hasSizeButton={false}
          hasIndexBoard={false}
          hasDotButtons="bottom"
          hasThumbnails={false}
          leftIcon={(<ArrowBackIcon/>)}
          rightIcon={(<ArrowForwardIcon/>)}
          children={carouselChildren}
          onIndexChange={handlerOnIndexChange}/>
      </Grid>
    );
  }
  return (null);
}

function HomePage({ setAppBarState, showSnackbar }) {
  const { width } = useWindowDimensions();
  const [videos, setVideos] = React.useState(
    getScreenRangeInt() >= SM ? HORIZONTAL_VIDEOS : VERTICAL_VIDEOS
  );
  const [ obituarios, setObituarios ] = React.useState(null);
  const [ showCarousel, setShowCarousel ] = React.useState(false);

  let cardMargin = width * 0.02;
  const dividerMargin = (cardMargin <= 16) ? cardMargin / 2 : 8;

  let cardMarginTop = cardMargin * 2;
  if (cardMarginTop < 42) cardMarginTop = 42;
  if (cardMarginTop > 72) cardMarginTop = 72;

  if (cardMargin < 12) cardMargin = 12;

  React.useEffect(() => {
    loadObituarios(setObituarios);
  }, []);

  React.useEffect(() => {
    setShowCarousel(obituarios != null);
  }, [obituarios]);

  return (
    <Box>
      {showCarousel ? (
        <Fade in={showCarousel} timeout={500}>
          <GalleryCarouselSection 
            obituarios={obituarios}
            setAppBarState={setAppBarState} 
             videos={videos}
            />
        </Fade>
      ) : (
        <Card
          elevation={0}
          style={{
            width: "100%",
            height: "100vh",
            background: "#f0f0f0"
          }}
        />
      )}
      <Card 
        elevation={24}
        style={
          {
            marginTop: cardMarginTop,
            marginLeft: cardMargin,
            marginRight: cardMargin,
            marginBottom: cardMargin,
            borderRadius: 8,
            position: "relative",
            zIndex: "3"
          }
        }
      >          
        <Box my={dividerMargin} mx={dividerMargin}>
          {/* <AfiliadoSM/> */}
          <Divider />
          <SmDescriptionSection/>
          </Box>

        <Box my={dividerMargin} mx={dividerMargin}>
          <Divider />
        </Box>
        <OurServicesSection/>
        <Box my={dividerMargin} mx={dividerMargin}>
          <Divider />
        </Box>
        <PayMethodsSection/>
        <Box my={dividerMargin} mx={dividerMargin}>
          <Divider />
        </Box>
        <HiringExampleSection/>
      </Card>
      <ContactUsSection showSnackbar={showSnackbar}/>
    </Box>
  );
}

export default HomePage;