import React from 'react';

// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Hidden from '@material-ui/core/Hidden';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';

// Components
import CardView from '../../../components/CardView';
import TextView from '../../../components/TextView';
import { planes, parque } from '../../../ArraysDB';
import { useWindowDimensions } from '../../../App';

const money = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
});

function ParqueDetails({ parqueItem, updateIndex }) {
  const [selectIndex, setSelectIndex] = React.useState(0);
  const [price, setPrice] = React.useState(0);

  React.useEffect(() => {
    let index = localStorage.getItem(`${parqueItem.name}Index`);
    setSelectIndex(index !== null ? index : 0);
  }, []);
  
  React.useEffect(() => {
    setPrice(parqueItem.types[selectIndex].firstPayment);
    localStorage.setItem(`${parqueItem.name}Index`, selectIndex);
  }, [selectIndex]);

  const handleImageClick = (event) => {
    window.open(parqueItem.imageFullSize, '_blank').focus();
  };
  const handleSelectChange = (event) => {
    setSelectIndex(event.target.value);
  };

  return (
    <Grid item container xs={12}
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={12} style={{ width: "100%" }}>
        <CardActionArea 
          onClick={handleImageClick}
        >
        <img 
          src={parqueItem.image} 
          alt="" 
          width="100%"
          style={{ 
            display: "block",
            marginLeft: "auto",
            marginRight: "auto"
          }}/>
        </CardActionArea>
      </Grid>
      <Grid item>
        <TextView textColor="#777777" mt={2} mb={3}>
          {parqueItem.description}
        </TextView>
      </Grid>
      <Grid item container>
        <TextField
          select
          name="select"
          label="Seleccionar"
          variant="outlined" 
          fullWidth
          value={selectIndex}
          onChange={handleSelectChange}
        >
          {parqueItem.types.map((item, key) => (
            <MenuItem key={key} value={key}>
              {item.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item container justify="flex-end">
        <TextView variant="h6" textColor="#424242" textAlign="right" fullWidth my={2}>
          {`Anticipo: ${money.format(price)}`}
        </TextView>
      </Grid>
    </Grid>
  );
}

export default ParqueDetails;