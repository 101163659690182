import React from 'react';
import ScriptTag from 'react-script-tag';

// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Hidden from '@material-ui/core/Hidden';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

// Components
import CardView from '../../components/CardView';
import TextView from '../../components/TextView';
import { config, mpCreatePreference, sendProspectoEmail } from '../../Api';
import { planes } from '../../ArraysDB';
import firebase, { fireDb } from '../../Firebase';

import { eventTrack } from '../../Analytics';

// SDK de Mercado Pago
const mercadopago = require('mercadopago');

// Agrega credenciales
mercadopago.configure({
  access_token: config.mercadoPagoAccessToken
});

const styles = {
  buttonTextWhite: {
    color: 'white',
    textTransform: 'capitalize',
    fontSize: '3rem'
  },
  buttonTextPrimary: {
    color: '#35647D',
    textTransform: 'capitalize',
    fontSize: '1rem'
  }
};

const money = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
});

function getPlan(planId) {
  return planes.find(p => p.id === planId);
}

function savePreferenceId(preferenceId, data) {
  fireDb.collection('pagos').doc(preferenceId).set(data);
}

function MercadoPagoButton({ preferenceId }) {
  const muiClassContained = "MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary";
  const gridRef = React.useRef(null);

  const changeStyle = () => {
    if (gridRef && gridRef.current) {
      let gridChildren = gridRef.current.getElementsByTagName("button");
      let button = gridChildren.item(0);
      button.className = muiClassContained;
    }
  }
  
  return (
    <Grid item ref={gridRef}>
      <ScriptTag 
        src="https://www.mercadopago.com.mx/integrations/v1/web-payment-checkout.js"
        type="text/javascript"
        data-preference-id={preferenceId}
        onLoad={changeStyle}>
      </ScriptTag>
    </Grid>
  );
}

function BuyPlanPage({ showSnackbar, showCustomDialog }) {
  const [tipoDePlan, setTipoDePlan] = React.useState(null);
  const [selectedPlan, setSelectedPlan] = React.useState(null);
  const [pago, setPago] = React.useState(null);
  const [buyer, setBuyer] = React.useState(null);
  const [preferenceId, setPreferenceId] = React.useState(0);

  React.useEffect(() => {
    let resultTipoDePlan = JSON.parse(localStorage.getItem('tipoDePlan'));
    let resultBuyer = JSON.parse(localStorage.getItem('buyerData'));

    localStorage.removeItem('tipoDePlan');
    localStorage.removeItem('buyerData');

    if (!resultTipoDePlan) {
      window.location.href='/plan/tranquilidad';
      return;
    }

    if (!resultBuyer) {
      localStorage.setItem('tipoDePlan', JSON.stringify(resultTipoDePlan));
      window.location.href='/datos-de-contacto';
      return;
    }

    setBuyer(resultBuyer);
    setTipoDePlan(resultTipoDePlan);
    setSelectedPlan(getPlan(resultTipoDePlan.planId));

    let htmlPlan = `
      <ul>
        <li>Plan por el que se interesó: ${resultTipoDePlan.plazo} MESES CON PAGOS QUINCENALES DE ${money.format(resultTipoDePlan.quincenal.pago)} </li>
        <li>Tipo de pago: ${resultTipoDePlan.anticipo ? "ANTICIPO" : "PRIMER PAGO"} </li>
        <li>${resultTipoDePlan.anticipo ? "Anticipo" : "Primer pago"}: ${money.format(resultTipoDePlan.primerPago)} </li>
        <li>Importe: ${money.format(resultTipoDePlan.total)} </li>      
      </ul>
    `;

    sendProspectoEmail(
      resultBuyer.email, 
      resultBuyer.name, 
      resultBuyer.phone,        
      htmlPlan
    );

    eventTrack('Modal Contacto Venta', 'Enviar', 'Button');
  }, []);

  React.useEffect(() => {
    if (selectedPlan === null) {
      return;
    }

    let plazosArray = tipoDePlan.anticipo ? selectedPlan.pagosConAnticipo : selectedPlan.pagosSinAnticipo;
    let plazo = plazosArray.find(p => p.plazo === tipoDePlan.plazo);

    setPago({
      concepto: !tipoDePlan.nombreAlternativo ? selectedPlan.name : selectedPlan.alternativeName,
      descripcion: tipoDePlan.anticipo ? "Anticipo" : "Primer Pago",
      total: plazo.quincenal.primerPago
    });
  }, [selectedPlan]);

  React.useEffect(() => {
    if (pago === null) {
      return;
    }

    mpCreatePreference(
      `PLAN ${pago.concepto}`, 
      pago.total,
      selectedPlan.logoPrimary
    ).then(res => {
      setPreferenceId(res.id);
      let date = new Date();
      savePreferenceId(
        res.id,
        {
          planId: tipoDePlan.planId,
          client: buyer,
          pago: {
            id: 0,
            status: "pending",
            ...pago
          },
          anticipo: tipoDePlan.anticipo,
          parcialidad: tipoDePlan.parcialidad,
          plazo: tipoDePlan.plazo,
          primerPago: tipoDePlan.primerPago,
          total: tipoDePlan.total,
          date: firebase.firestore.Timestamp.fromDate(date),
          dateString: date.toISOString()
        }
      );
    });
  }, [pago]);

  const handleClickShowPlanes = event => {
    localStorage.setItem('tipoDePlan', JSON.stringify(tipoDePlan));
    localStorage.setItem('buyerData', JSON.stringify(buyer));
    localStorage.setItem('backToCheckout', true);
    window.location.href='/planes-y-productos';
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      style={{
        width: "100vw",
        height: "94vh",
        overflow: 'auto',
        flex: "1 1 auto"
      }}
    >
      { pago ? 
        (
          <React.Fragment>
            <Fade in={pago !== null} timeout={1500}>
              <Grid item container
                direction="row"
                justify="center"
                alignItems="flex-start"
              >

                <Grid item xs={10}>
                  <TextView variant="h6" align="center" mt={14} mb={4}>
                    ADQUIRIR PLAN
                  </TextView>
                </Grid>

                <Grid item xs={10} sm={8} md={6} lg={5}>
                  <Grid container xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    <Grid item xs={3}>
                      <Box>
                        <img src={selectedPlan.logoPrimary} alt="" style={{ maxWidth: "80%", maxHeight: 72 }}/>
                      </Box>
                    </Grid>
                    <Grid item xs={6}
                      direction="column"
                    >
                      <TextView>
                        {pago.concepto}
                      </TextView>
                      <TextView textColor="#777777">
                        {pago.descripcion}
                      </TextView>
                      <TextView textColor="#777777">
                        ({tipoDePlan.plazo} meses / Pagos quincenales)
                      </TextView>
                    </Grid>
                    <Grid item xs={3}
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="flex-start"
                    >
                      <TextView align="right">
                        {money.format(pago.total)}
                      </TextView>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item container justify="center" xs={12} >
                  <Grid item xs={12} sm={12} md={7} lg={6}>
                    <Box width={1} mt={2}>
                      <Divider/>
                    </Box>
                  </Grid>
                </Grid>

                
              </Grid>
            </Fade>

            <Grid item container xs={12} sm={12} md={6} lg={5}
              direction="row"
              justify="center"
              alignItems="center"
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                background: "#ffffff"
              }}
            >
              <Box width={1} mb={2}>
                <Divider/>
              </Box>
              <Grid item container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={4}
              >
                {!tipoDePlan.nombreAlternativo ? (
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleClickShowPlanes}
                    >
                      Ver otros
                    </Button>
                  </Grid>
                ) : (
                  null
                )}
                {preferenceId !== 0 ? (
                  <MercadoPagoButton preferenceId={preferenceId} />
                ) : (
                  null
                )}
              </Grid>
              <Box width={1} mt={2}>
                <Divider/>
              </Box>
            </Grid>
          </React.Fragment>
        ) : (
          null
        )
      }
    </Box>
  );
}

export default BuyPlanPage;
