import React from 'react';

// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

// Components
import CardView from '../../../components/CardView';
import TextView from '../../../components/TextView';

function DescriptionSection() {
  const [heightCard, setHeightCard] = React.useState(240);
  const cardRef = React.useRef();

  React.useEffect(() => {
    if (cardRef.current) {
      let height = cardRef.current.offsetHeight;
      setHeightCard(height);
    }
  }, [cardRef]);

  return (
    <Box mt={18} mx={4} 
    >
      <Grid container 
        direction="column"
        justify="flex-start"
        alignItems="center" 
      >
        <Box mb={10}>
                <img src="/image/mvv2024.jpg" height='100%' alt="" />
              </Box>
{/*
        <TextView variant="h4" align="center" my={10} textColor="#326295" fontWeight={500}>
          Nuestras Misiones
        </TextView>

        <Grid container sm={12} md={10} lg={8}
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={6}
        >

           <Grid item xs={12} sm={6} md={4} lg={4}>
            <CardView color="#35647D" 
              elevation={8} 
              radius={12}
            >
              <Box m={3} ref={cardRef}>
                <Grid container
                  direction="column"
                  justify="flex-start"
                  alignItems="center"
                >
                  <TextView variant="h5" fontWeight={500} textAlign="center" textColor="#fff">
                    Plan San Martín
                  </TextView>
                  <TextView variant="subtitle1" textColor="#fff" textAlign="justify">
                    <p>
                      Asesorar a las familias sinaloenses sobre la importancia de la adquisición de un plan funeral como una inversión de vida.
                    </p>
                  </TextView>
                </Grid>
              </Box>
            </CardView>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <CardView color="#9AABC8"
              elevation={8} 
              radius={12}
            >
              <Box m={3} style={{ height: heightCard }}>
                <Grid container
                  direction="column"
                  justify="flex-start"
                  alignItems="center"
                >
                  <TextView variant="h5" fontWeight={500} textAlign="center" textColor="#fff">
                    Funeraria
                  </TextView>
                  <TextView variant="subtitle1" textColor="#fff" textAlign="justify">
                    <p>
                      Acompañar a las familias sinaloenses y rendir tributo al ser querido que ha partido.
                    </p>
                  </TextView>
                </Grid>
              </Box>
            </CardView>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <CardView color="#9AABC8"
              elevation={8} 
              radius={12}
            >
              <Box m={3} style={{ height: heightCard }}>
                <Grid container
                  direction="column"
                  justify="flex-start"
                  alignItems="center"
                >
                  <TextView variant="h5" fontWeight={500} textAlign="center" textColor="#fff">
                    Parque Funeral
                  </TextView>
                  <TextView variant="subtitle1" textColor="#fff" textAlign="justify">
                    <p>
                      Ofrecer un espacio agradable para honrar y celebrar al ser querido que ha partido.
                    </p>
                  </TextView>
                </Grid>
              </Box>
            </CardView>
          </Grid>
          
        </Grid>

        <TextView variant="h4" align="center" mt={16} mb={10} textColor="#326295" fontWeight={500}>
          Nuestra Visión
        </TextView>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <CardView color="#9AABC8"
            elevation={8} 
            radius={12}
          >
            <Box m={3}>
              <Grid container
                direction="column"
                justify="flex-start"
                alignItems="center"
              >
                <TextView variant="subtitle1" textColor="#fff" textAlign="justify">
                  Acompañar y crear una cultura de previsión a más de 10,000 familias sinaloenses en la partida de un ser querido.
                </TextView>
              </Grid>
            </Box>
          </CardView>
        </Grid>

        <TextView variant="h4" textColor="#326295" fontWeight={500} mt={16} mb={10}>
          Nuestros Valores
        </TextView>

        <Grid container 
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={8}
        >

          <Grid item>
            <Grid container
              direction="column"
              justify="flex-start"
              alignItems="center"
              style={{width: 200}}
            >
              <Box mb={2}>
                <img src="/image/ic_pasion.png" height={96} alt="" />
              </Box>
              <TextView variant="h6" fontWeight={500} textAlign="center">
                Pasión y Respeto
              </TextView>
              <TextView variant="subtitle1" textAlign="center">
                "Doy lo mejor de mí"
              </TextView>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container
              direction="column"
              justify="flex-start"
              alignItems="center"
              style={{width: 200}}
            >
              <Box mb={2}>
                <img src="/image/ic_cortesia.png" height={96} alt="" />
              </Box>
              <TextView variant="h6" fontWeight={500} textAlign="center">
                Cortesía
              </TextView>
              <TextView variant="subtitle1" textAlign="center">
                "Me preocupo por el otro"
              </TextView>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container
              direction="column"
              justify="flex-start"
              alignItems="center"
              style={{width: 200}}
            >
              <Box mb={2}>
                <img src="/image/ic_experiencia.png" height={96} alt="" />
              </Box>
              <TextView variant="h6" fontWeight={500} textAlign="center">
                Experiencia y Calidez
              </TextView>
              <TextView variant="subtitle1" textAlign="center">
                "Nuestro trato nos distingue"
              </TextView>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container
              direction="column"
              justify="flex-start"
              alignItems="center"
              style={{width: 200}}
            >
              <Box mb={2}>
                <img src="/image/ic_memorias.png" height={96} alt="" />
              </Box>
              <TextView variant="h6" fontWeight={500} textAlign="center">
                Creando Memorias
              </TextView>
              <TextView variant="subtitle1" textAlign="center" mb={16}>
                "El servicio que doy, se recordará por siempre"
              </TextView>
            </Grid>
          </Grid>
          
        </Grid> */}

      </Grid>
    </Box>
  );
}

export default DescriptionSection;
