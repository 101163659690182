import React from 'react';
import ScriptTag from 'react-script-tag';

// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

// Components
import CardView from '../../components/CardView';
import TextView from '../../components/TextView';
import PersonalDataForm from '../product_form/parts/PersonalDataForm';

const NUMBER_PATTERN = /^[0-9]+$/;
const EMAIL_PATTERN = /\S+@\S+\.\S+/;

function isValidEmail(value) {
  return !isEmpty(value) && EMAIL_PATTERN.test(value);
}

function isEmpty(value) {
  return value == null || value.length === 0;
}

function formatPhone(value) {
  let formatted = "";
  for (let i = 0; i < value.length; i++) {
    if (i === 0) {
      formatted = "(";
    }
    if (i === 6) {
      formatted += "-";
    }
    formatted += value.charAt(i);
    if (i === 2) {
      formatted += ") ";
    }
  }
  return formatted;
}

function parsePhone(value) {
  let number = "";

  if (value.charAt(value.length - 1) === ")") {
    value = value.substring(0, value.length - 2);
  }

  for (let i = 0; i < value.length; i++) {
    let car = value.charAt(i);
    if (!NUMBER_PATTERN.test(car)) {
      continue;
    }
    number += car;
  }

  return number;
}

function BuyerFormPage({ showSnackbar, showCustomDialog }) {
  const [tipoDePlan, setTipoDePlan] = React.useState(null);
  const [values, setValues] = React.useState(
    {
      personalData: {
        name: {
          value: "",
          error: false
        },
        email: {
          value: "",
          error: false
        },
        phone: {
          value: "",
          error: false
        },
        city: {
          value: "",
          error: false
        }
      }
    }
  );

  React.useEffect(() => {
    let resultTipoDePlan = JSON.parse(localStorage.getItem('tipoDePlan'));
    localStorage.removeItem('tipoDePlan');

    if (!resultTipoDePlan) {
      window.location.href='/planes-y-productos';
      return;
    }

    setTipoDePlan(resultTipoDePlan);
  }, []);

  const validate = () => {
    if (isEmpty(values.personalData.name.value)) {
      values.personalData.name.error = true;
      showSnackbar("Favor de no dejar campos vacíos", "error");
      return false;
    }
    if (!isValidEmail(values.personalData.email.value)) {
      values.personalData.email.error = true;
      showSnackbar("Favor de introducir un correo válido", "error");
      return false;
    }
    if (parsePhone(values.personalData.phone.value).length !== 10) {
      values.personalData.phone.error = true;
      showSnackbar("Favor de introducir un teléfono válido de 10 dígitos", "error");
      return false;
    }
    if (isEmpty(values.personalData.city.value)) {
      values.personalData.city.error = true;
      showSnackbar("Favor de seleccionar una Ciudad", "error");
      return false;
    }
    setValues(values);
    return true;
  }

  const handlerBackClick = e => {
    window.location.href='/planes-y-productos';
  }

  const handlerNextClick = e => {
    if (!validate()) return;

    let buyer = {
      name: values.personalData.name.value,
      email: values.personalData.email.value,
      phone: values.personalData.phone.value,
      city: values.personalData.city.value
    }

    localStorage.setItem('tipoDePlan', JSON.stringify(tipoDePlan));
    localStorage.setItem('buyerData', JSON.stringify(buyer));
    
    window.location.href='/comprar-plan';
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      style={{
        width: "100vw",
        height: "94vh",
        overflow: 'auto',
        flex: "1 1 auto"
      }}
    >
      <React.Fragment>
        <Fade in={true} timeout={1500}>
          <Grid item container xs={12} sm={6} md={5} lg={4}
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >

            <PersonalDataForm 
              values={values} 
              updateValues={setValues}
              formatPhone={formatPhone}
              parsePhone={parsePhone}
              numberPattern={NUMBER_PATTERN}
              buyerForm={true} />

          </Grid>
        </Fade>

        <Grid item container xs={12} sm={12} md={6} lg={5}
          direction="row"
          justify="center"
          alignItems="center"
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            background: "#ffffff"
          }}
        >
          <Box width={1} mb={2}>
            <Divider/>
          </Box>
          <Grid item container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={handlerBackClick}
              >
                Atrás
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handlerNextClick}
              >
                Siguiente
              </Button>
            </Grid>
          </Grid>
          <Box width={1} mt={2}>
            <Divider/>
          </Box>
        </Grid>
      </React.Fragment>
    </Box>
  );
}

export default BuyerFormPage;
