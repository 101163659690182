import React from 'react';

// @material-ui/core components
import Grid from "@material-ui/core/Grid";

// Components
import TextView from '../../../components/TextView';

function InstructionSection() {
  return (
    <Grid item container xs={12} md={6}
      direction="column"
      justify="flex-start"
    >
      <Grid item>
        <TextView align="justify">
            Si vas a realizar tus pagos por primera vez, deberás crear tu NIP y agregar un correo electrónico.
        </TextView>
      </Grid>
      <Grid item>
        <ol>
          <TextView><li>Ingresa el Folio de tu contrato y tu NIP.</li></TextView>
          <TextView><li>Da click en entrar.</li></TextView>
          <TextView><li>Consulta tu saldo actual.</li></TextView>
          <TextView><li>Click en Realizar pagos.</li></TextView>
          <TextView><li>Selecciona los pagos que deseas realizar y da click en continuar.</li></TextView>
          <TextView><li>Ingresa tus datos bancarios y da click en Pagar.</li></TextView>
        </ol>
      </Grid>
      <Grid item>
        <TextView align="justify">
            Después de ser autorizado tu pago, recibirás el comprobante de pago al correo que agregaste anteriormente. Para cualquier duda o aclaración comunícate a nuestras oficinas Tel.: 667 716 5939
        </TextView>
      </Grid>
    </Grid>
  );
}

export default InstructionSection;
