import React from 'react';

// Firebase
import { fireDb } from '../../../Firebase';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Fab from "@material-ui/core/Fab";
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons
import ArrowBackIcon from '@material-ui/icons/ArrowBackIosSharp';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIosSharp';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

// Components
import Parallax from '../../../components/Parallax';
import CardView from '../../../components/CardView';
import TextView from '../../../components/TextView';
import Carousel from '../../../components/Carousel';

import { getScreenRange } from '../../../App';

const useStyles = makeStyles({
  fab: {
    background: "#ffffffcc"
  }
});

function getFormattedDate(time) {
  /*let date = new Date(time.seconds * 1000);

  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hour = date.getHours();
  let min = date.getMinutes();
  let amPm = hour < 12 ? "A.M." : "P.M.";

  if (hour > 12) {
    hour -= 12;
  }

  month = (month < 10 ? "0" : "") + month;
  day = (day < 10 ? "0" : "") + day;
  hour = (hour < 10 ? "0" : "") + hour;
  min = (min < 10 ? "0" : "") + min;

  let formattedDate =  `${day}/${month}/${year} ${hour}:${min} ${amPm}`;

  return formattedDate;*/
  return time;
}

function InfoSection({ textAlign }) {
  const range = getScreenRange();
  let titleSize = "h2";
  let hashSize = "h5";
  let iconSize = "large";
  let alignItems = "flex-start";

  if (range === "xs" || range === "sm") {
    titleSize = "h3";
    hashSize = "h6";
    iconSize = "small";
    alignItems = "center";
  }
  if (range === "md") {
    titleSize = "h3";
    hashSize = "h5";
    alignItems = "center";
  }

  return (
    <Grid item container xs={12} md={5} 
      direction="column"
      justify="center" 
      alignItems={alignItems}
    >

      <Grid item container xs={11} sm={10} md={10} lg={10}>
        <TextView 
          variant={titleSize}
          textColor="#FFFFFF"
          fontWeight={600}
          textAlign={textAlign}
          fullWidth={textAlign === "center"}
        >
          PROTEGERNOS
        </TextView>

        <TextView 
          variant={titleSize}
          textColor="#FFFFFF"
          fontWeight={350}
          textAlign={textAlign}
          fullWidth={textAlign === "center"}
        >
          ES EL FUTURO INMEDIATO.
        </TextView>
        <Grid item container justify="center">
          <Box mt={6}>
          </Box>
        </Grid>
      </Grid>
      
    </Grid>
  );
}

function Obituario(props) {
  const { nombre, imagen, velacion, ceremoniaLugar, ceremoniaFecha, sepelioLugar, sepelioFecha } = props;
  return (
    <CardView 
      color="#ffffffcc" 
      elevation={16} 
      radius={12}
    >
      <Grid container
        direction="column"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item xs>
          <Box m={2}>
            <img src={imagen} height={92} alt="" />
          </Box>
        </Grid>
        <Box mx={2} mb={1}>
          <TextView variant="h6" textColor="#cca079">
            {nombre}
          </TextView>
          <TextView variant="body2" my={1} >
            Velación en {velacion} <br/>
            Ceremonia Religiosa el día {getFormattedDate(ceremoniaFecha)} en {ceremoniaLugar} <br/>
            Sepelio el {getFormattedDate(sepelioFecha)} en {sepelioLugar}
          </TextView>
        </Box>
      </Grid>
    </CardView>
  );
}

function ObituariosSection({ obituarios }) {
  const classes = useStyles();

  const backButton = (
    <Fab className={classes.fab}>
      <ArrowBackIcon/>
    </Fab>
  );

  const nextButton = (
    <Fab className={classes.fab}>
      <ArrowForwardIcon/>
    </Fab>
  );

  return (
    <Grid item container justify="center">
      { obituarios.length > 0 ? (
        <Grid item container xs={10} sm={9} md={11} lg={8}
          direction="column"
          justify="center" 
          alignItems="center" 
        >
          <TextView variant="h6" textColor="#fff" mt={3} mb={2}>
            Obituarios
          </TextView>
          <Carousel 
            showSelectors={false} 
            backButton={backButton} 
            nextButton={nextButton}
          >
            {obituarios.map((e, key) => (
              <Obituario
                key={key}
                /*nombre={e.nombre}
                imagen={e.imagen}
                velacion={e.velacion}
                ceremoniaLugar={e.ceremoniaLugar} 
                ceremoniaFecha={e.ceremoniaFecha} 
                sepelioLugar={e.sepelioLugar} 
                sepelioFecha={e.sepelioFecha}*/
                nombre={e.nb_finado}
                imagen="/image/obituario_2024.png"
                velacion={e.sala + e.ciudad}
                ceremoniaLugar={e.de_Misa} 
                ceremoniaFecha={e.fh_Misa} 
                sepelioLugar={e.de_sepelio} 
                sepelioFecha={e.fh_sepelio}
              />
            ))}
          </Carousel>
        </Grid>
      ) : (
        null
      )}
    </Grid>
  );
}

function BannerSection({ imageBanner, obituarios }) {
  return (
    <Parallax image={imageBanner} 
      style={{
        width: "100%"
      }}
    >
      <Box width={1}>
        <Grid
          item
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          style={{
            top: 0,
            width: "100%"
          }}
        >
          <Grid item container xs={12} md={6}
            direction="column"
            justify="center" 
            alignItems="center" 
          >
            <ObituariosSection obituarios={obituarios}/>
          </Grid>
        </Grid>
      </Box>
    </Parallax>
  );
}

export default BannerSection;
