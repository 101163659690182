import React from 'react';

// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";

// Components
import CardView from '../../../components/CardView';
import TextView from '../../../components/TextView';
import { useWindowDimensions } from '../../../App';

const sucursales = [
  {
    "ciudad": "Culiacán, Sinaloa.",
    "lugares": [
      {
        imagen: "/image/montebello_2024.JPG",
        sucursal: "Sucursal Montebello",
        direccion: "Calle Montebello 22 Oriente, Col. Montebello, 80227 Culiacán Rosales, Sin.",
        telefono: "(667) 712 8000",
        mapsLink: "https://goo.gl/maps/jsvUhXDpYZdUmcgm8"
      },
      {
        imagen: "/image/zapata_2024.jpg",
        sucursal: "Sucursal Zapata",
        direccion: "Blvd. Emiliano Zapata 145, Guadalupe, Jorge Almada, 80220 Culiacán Rosales, Sin.",
        telefono: "(667) 712 8000",
        mapsLink: "https://goo.gl/maps/TqUS4w6ELSqurEn76"
      },
      {
        imagen: "/image/parque_funeral_culiacan.jpg",
        sucursal: "Parque Funeral",
        direccion: "Culiacán Km 208+10, Internacional, Abastos, 80390 Culiacán Rosales, Sin.",
        telefono: "(667) 718 9010",
        mapsLink: "https://goo.gl/maps/WUNAMvC9KMWneVUFA"
      },
      {
        imagen: "/image/corpo_2024.jpg",
        sucursal: "Parque Funeral",
        direccion: "Domingo Rubí sur Col. Guadalupe, 80220 Culiacán Rosales, Sin.",
        telefono: "(667) 716 5939",
        mapsLink: "https://goo.gl/maps/jZQrgMWUdrQVpTcN8"
      }
    ]
  },
  {
    "ciudad": "Costa Rica, Sinaloa.",
    "lugares": [
      {
        imagen: "/image/costarica_2024.jpg",
        sucursal: "Sucursal Costa Rica",
        direccion: "Calle 1042 Col. Costa Rica, Mocorito, Sinaloa, 80430 Sin",
        telefono: "(667) 712 8000",
        mapsLink: "https://goo.gl/maps/NLSsc3A4bT2LqeH56"
      }
    ]
  },
  {
    "ciudad": "Navolato, Sinaloa.",
    "lugares": [
      {
        imagen: "/image/navolato_2024.JPG",
        sucursal: "Sucursal Navolato",
        direccion: "Avenida las palmas y flor de dalia 607 Sur Col. La Primavera, Jardín 80378, Navolato, Sin",
        telefono: "(672) 727 0105",
        mapsLink: "https://goo.gl/maps/vn4oPCA2KHiaianh9"
      }
    ]
  },
  {
    "ciudad": "Guasave, Sinaloa.",
    "lugares": [
      {
        imagen: "/image/guasave_2024.jpg",
        sucursal: "Sucursal Guasave",
        direccion: "Ladrillera de Oroco, Carretera a León Fonseca, 81101 Guasave, Sin.",
        telefono: "(687) 870 1133",
        mapsLink: "https://goo.gl/maps/RhQ9aftxpYRSwjne9"
      }
    ]
  },
  {
    "ciudad": "Los Mochis, Sinaloa.",
    "lugares": [
      {
        imagen: "/image/mochis_2024.jpg",
        sucursal: "Sucursal Los Mochis",
        direccion: "Ote. Blvd. Rosendo G. Castro Pte. 495, Centro, 81200 Los Mochis, Sin.",
        telefono: "(667) 336 9128",
        mapsLink: "https://goo.gl/maps/q8X5m9aXUC3ry2QCA"
      }
    ]
  }
]

function Sucursal({ cardHeight, imagen, lugar, direccion, telefono, mapsLink }) {
  return (
    <Grid item xs={12} sm={11} md={6}>
      <CardView
        height={cardHeight}
        elevation={8} 
        radius={12}
      >
        <CardActionArea href={mapsLink} target="Maps">
          <CardMedia image={imagen} style={{ width: "100%", height: 200 }}/>
        </CardActionArea>
        
        <Box m={3}>
          <Grid container
            direction="column"
            justify="flex-start"
          >
            <TextView variant="h5" fontWeight={500}>
             {lugar}
            </TextView>
            <TextView variant="subtitle1" align="justify" mb={1}>
              <Link 
                color="inherit" 
                target="Maps"
                href={mapsLink}
              >
                {direccion}
              </Link>
            </TextView>
            <TextView variant="subtitle1" fontWeight={500}  textAlign="right">
              <Link 
                color="inherit" 
                target="Telefono"
                href={"tel:" + telefono}
              >
                Tel: {telefono}
              </Link>
            </TextView>
          </Grid>
        </Box>
      </CardView>
    </Grid>
  );
}

function Ciudad({ ciudad, lugares, cardHeight, index }) {
  return (
    <Grid container 
      direction="column"
      justify="flex-start"
      alignItems="center"
    >

      { index > 0 ?
        <Grid item container xs={12} sm={11} md={9}>
          <Box width={1} mt={10} mb={7}>
            <Divider/>
          </Box>
        </Grid>
      :
        null
      }

      <Grid item container xs={12} sm={10} md={8}>
        <TextView variant="h4" textColor="#35647D" fontWeight={500} mb={8}>
          {ciudad}
        </TextView>
      </Grid>

      <Grid container 
        direction="row"
        justify="center"
        alignItems="center"
        spacing={4}
        xs={12} sm={9} md={9} lg={8} xl={7}
      >
        {lugares.map(e => (
          <Sucursal
            cardHeight={cardHeight}
            imagen={e.imagen}
            lugar={e.lugar}
            direccion={e.direccion}
            telefono={e.telefono}
            mapsLink={e.mapsLink} />
        ))}
      </Grid>

    </Grid>
  );
}

function DescriptionSection() {
  const cardHeight = "auto";
  const { width } = useWindowDimensions();
  const boxMargin = width * 0.004;

  return (
    <Box mt={18} mx={boxMargin}>

      <Grid item container xs={12} sm={11} md={9}>
        <Box width={1} mt={boxMargin * 2.25} />
      </Grid>

      {sucursales.map((e, index) => ( 
        <Ciudad
          cardHeight={cardHeight}
          ciudad={e.ciudad}
          lugares={e.lugares}
          index={index}/>
      ))}

      <Grid item container xs={12} sm={11} md={9}>
        <Box width={1} my={8} />
      </Grid>

    </Box>
  );
}

export default DescriptionSection;
