import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';

export default function CardView(props) {
  const { elevation=2, color="#fff", image, radius=4, children, height="auto" } = props;
  const useStyles = makeStyles(
    image === undefined ?
      {
        root: {
          backgroundColor: color,
          borderRadius: radius,
          height: height
        }
      }
    :
      {
        root: {
          background: "url(" + image + "), rgba(0, 0, 0, 0.5)",
          backgroundBlendMode: "overlay",
          backgroundSize: "cover",
          borderRadius: radius,
          height: height
        }
      }
  );
  const classes = useStyles();

  return (
    <Card 
      className={classes.root} 
      elevation={elevation}
    >
      {children}
    </Card>
  );
}