import React from 'react';

// @material-ui/core components
import { makeStyles, fade } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Card from '@material-ui/core/Card';
import Collapse from '@material-ui/core/Collapse';

// @material-ui/icons
import SendIcon from '@material-ui/icons/Send';

// Components
import TextView from '../../../components/TextView';
import { sendContactUsEmail } from '../../../Api';

const NUMBER_PATTERN = /^[0-9]+$/;
const EMAIL_PATTERN = /\S+@\S+\.\S+/;
const NUMBERS = [
  { nameStr: "cero", nameInt: "0", value: 0 },
  { nameStr: "uno", nameInt: "1", value: 1 },
  { nameStr: "dos", nameInt: "2", value: 2 },
  { nameStr: "tres", nameInt: "3", value: 3 },
  { nameStr: "cuatro", nameInt: "4", value: 4 },
  { nameStr: "cinco", nameInt: "5", value: 5 },
  { nameStr: "seis", nameInt: "6", value: 6 },
  { nameStr: "siete", nameInt: "7", value: 7 },
  { nameStr: "ocho", nameInt: "8", value: 8 },
  { nameStr: "nueve", nameInt: "9", value: 9 },
]
const OPERATOR = [ "más", "+" ];

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  }
});

const useStylesReddit = makeStyles((theme) => ({
  root: {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#fcfcfb',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
}));

function randomNumber(range) {
  return Math.floor(Math.random() * range);
}

function generateMathCaptcha() {
  let first = NUMBERS[randomNumber(10)];
  let second = NUMBERS[randomNumber(10)];

  first.show = first[randomNumber(2) === 0 ? "nameStr" : "nameInt"];
  second.show = second[randomNumber(2) === 0 ? "nameStr" : "nameInt"];

  return {
    first: first,
    second: second,
    operator: OPERATOR[randomNumber(2)]
  }
}

function TextFieldInner(props) {
  const classes = useStylesReddit();
  return <TextField InputProps={{ classes, disableUnderline: true }} {...props} />;
}

function ContactUsSection({ showSnackbar }) {
  const classes = useStyles();
  const [ collapse, setCollapse ] = React.useState(false);
  const [ mathCaptcha, setMathCaptcha ] = React.useState(null);
  const [ form, updateForm ] = React.useState({ 
    email: { val: '', error: false }, 
    name: { val: '', error: false },
    phone: { val: '', error: false },
    subject: { val: '', error: false },
    message: { val: '', error: false },
    captchaResult: { val: '', error: false }
  });

  React.useEffect(() => {
    setMathCaptcha(generateMathCaptcha());
  }, []);

  const clearForm = () => {
    updateForm({ 
      email: { val: '', error: false }, 
      name: { val: '', error: false },
      phone: { val: '', error: false },
      subject: { val: '', error: false },
      message: { val: '', error: false },
      captchaResult: { val: '', error: false }
    });
  }

  const handleInputChange = event => {
    let newValue = event.target.value;

    if (event.target.name === 'captchaResult' && 
          !NUMBER_PATTERN.test(newValue) &&
          newValue !== '') {
      newValue = form[event.target.name].val;
    }

    updateForm({
      ...form,
      [event.target.name]: { 
        val: newValue, 
        error: false
      } 
    });
  }

  const setInputError = (input, error) => {
    updateForm({
      ...form,
      [input]: { 
        val: form[input].val, 
        error: error
      } 
    });
  }

  const onNameInputFocus = event => {
    if (!collapse) {
      setCollapse(true);
    }
  }

  const onClickSend = event => {
    if (!validateInputs()) {
      setMathCaptcha(generateMathCaptcha());
      return;
    }

    sendContactUsEmail(
      form.email.val, 
      form.name.val, 
      form.phone.val, 
      form.subject.val, 
      form.message.val);
    clearForm();
    setCollapse(false);
    showSnackbar("Correo enviado", "success");
    setMathCaptcha(generateMathCaptcha());
  }

  const validateInputs = () => {
    for (let input in form) {
      if (isEmpty(form[input].val)) {
        showSnackbar("Favor de no dejar campos vacíos", "error");
        setInputError(input, true);
        return false;
      }
    }

    if (!isValidEmail(form.email.val)) {
      showSnackbar("Favor de introducir un correo válido", "error");
      setInputError('email', true);
      return false;
    }

    if (!isValidCaptcha(form.captchaResult.val)) {
      showSnackbar("Respuesta incorrecta, favor de revisar", "error");
      setInputError('captchaResult', true);
      return false;
    }

    return true;
  }

  const isValidEmail = value => {
    return EMAIL_PATTERN.test(value);
  }

  const isValidCaptcha = () => {
    let input = parseInt(form.captchaResult.val);
    let answer = mathCaptcha.first.value + mathCaptcha.second.value;
    return input === answer;
  }

  const isEmpty = value => value == null || value.length === 0;

  return (
    <Box my={8}>
      <Grid container justify="center">
        <Grid item xs={11} sm={10} md={6} lg={5}>
          <Card
            elevation={8} 
            style={{
              backgroundColor: '#35647D',
              borderRadius: 12
            }}
          >
            <Box mx={4} my={2}>
              <Grid container
                direction="column"
                justify="center"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item container>
                  <TextView variant="h5" fontWeight="bold" textColor="#fff" mb={2}>
                    Para mayor información ¡Contáctanos!
                  </TextView>
                </Grid>
                <Grid item container>
                  <TextFieldInner
                    type="email"
                    name="email"
                    value={form.email.val}
                    error={form.email.error}
                    className={classes.margin} 
                    fullWidth 
                    variant="filled" 
                    label="Correo electrónico"
                    onFocus={onNameInputFocus}
                    onChange={handleInputChange}  />
                </Grid>

                <Grid item container>
                  <Box width={1}>
                    <Collapse in={collapse} timeout="auto" unmountOnExit>
                      <Grid item container
                        direction="column"
                        spacing={2}
                      >
                        <Grid item container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <TextFieldInner 
                              name="name"
                              value={form.name.val}
                              error={form.name.error}
                              className={classes.margin} 
                              fullWidth 
                              variant="filled" 
                              label="Nombre"
                              onChange={handleInputChange}  />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextFieldInner className={classes.margin}
                              name="phone"
                              value={form.phone.val} 
                              error={form.phone.error}
                              type="tel"
                              fullWidth 
                              variant="filled" 
                              label="Teléfono"
                              onChange={handleInputChange}  />
                          </Grid>
                        </Grid>
                        <Grid item container>
                          <TextFieldInner className={classes.margin} 
                            name="subject"
                            value={form.subject.val}
                            error={form.subject.error}
                            fullWidth 
                            variant="filled" 
                            label="Asunto"
                            onChange={handleInputChange}  />
                        </Grid>
                        <Grid item container>
                          <TextFieldInner className={classes.margin}
                            name="message"
                            value={form.message.val}
                            error={form.message.error} 
                            fullWidth 
                            variant="filled" 
                            label="Mensaje" 
                            multiline 
                            rows={5} 
                            rowsMax={10}
                            onChange={handleInputChange} />
                        </Grid>
                        <Grid item container>
                          <TextView textColor="#fff">
                            {mathCaptcha ?
                              `¿Cuánto es "${mathCaptcha.first.show} ${mathCaptcha.operator} ${mathCaptcha.second.show}"?`
                            :
                              "¿Cuánto es ...?"
                            }
                          </TextView>
                        </Grid>
                        <Grid item container>
                          <TextFieldInner className={classes.margin}
                            name="captchaResult"
                            value={form.captchaResult.val}
                            error={form.captchaResult.error} 
                            fullWidth 
                            variant="filled" 
                            label="Resultado"
                            inputmode="numeric"
                            onChange={handleInputChange} />
                        </Grid>
                        <Grid item container
                          direction="row"
                          justify="flex-end"
                          alignItems="center"
                        >
                          <IconButton onClick={onClickSend}>
                            <SendIcon fontSize="large" style={{ color: "#fff" }} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Box>
                </Grid>

              </Grid>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ContactUsSection;
