import React from 'react';

// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles, fade } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from '@material-ui/core/TextField';

// Components
import TextView from '../../../components/TextView';

const NAME_LIMIT = 100;
const EMAIL_LIMIT = 64;
const PHONE_LIMIT = 10;

const SUBJECT_LIMIT = 100;
const MESSAGE_LIMIT = 1000;

const useStylesReddit = makeStyles((theme) => ({
  root: {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#fcfcfb',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
}));

function TextFieldInner(props) {
  const classes = useStylesReddit();
  return <TextField InputProps={{ classes, disableUnderline: true }} {...props} />;
}

function PersonalContactDataForm({ values, updateValues, formatPhone, parsePhone, numberPattern, buyerForm=false, modalForm=false }) {
  const [personalData, updatePersonalData] = React.useState(values.personalData);
  const [fieldMargin, setFieldMargin] = React.useState(modalForm ? 0 : 2);

  const handleInputChange = event => {
    let newValue = event.target.value;

    if (event.target.name === 'name' && newValue.length > NAME_LIMIT) {
      newValue = personalData[event.target.name].value;
    }

    if (event.target.name === 'email' && newValue.length > EMAIL_LIMIT) {
      newValue = personalData[event.target.name].value;
    }

    if (event.target.name === 'phone') {
      newValue = parsePhone(newValue);
      if (!numberPattern.test(newValue) && newValue !== '') {
        newValue = personalData[event.target.name].value;
      }
      if (newValue.length > PHONE_LIMIT) {
        newValue = parsePhone(personalData[event.target.name].value);
      }
      newValue = formatPhone(newValue);
    }

    if (event.target.subject === 'subject' && newValue.length > SUBJECT_LIMIT) {
      newValue = personalData[event.target.subject].value;
    }

    if (event.target.message === 'message' && newValue.length > MESSAGE_LIMIT) {
      newValue = personalData[event.target.message].value;
    }


    updatePersonalData({
      ...personalData,
      [event.target.name]: { 
        value: newValue,
        error: false
      } 
    });
  }

  React.useEffect(() => {
    updateValues({
      ...values,
      personalData: personalData
    });
  }, [personalData]);

  return (
    <React.Fragment>

      {!modalForm ? (
        <Grid item>
          <TextView variant="h5" textColor="#757575" mt={!buyerForm ? 3 : 16} mb={1} mx={1}>
            Datos de contacto
          </TextView>
        </Grid>
      ) : ( null )}

      <Grid item container>
        <Box width={1} mx={fieldMargin}>
          <TextField 
            name="name"
            label="Nombre completo"
            variant="outlined" 
            fullWidth
            value={personalData.name.value}
            error={personalData.name.error}
            onChange={handleInputChange}
          />
        </Box>
      </Grid>
      <Grid item container>
        <Box width={1} mx={fieldMargin}>
          <TextField 
            name="email"
            label="Correo electrónico"
            type="email"
            variant="outlined" 
            fullWidth
            value={personalData.email.value}
            error={personalData.email.error}
            onChange={handleInputChange}
          />
        </Box>
      </Grid>
      <Grid item container>
        <Box width={1} mx={fieldMargin}>
          <TextField 
            name="phone"
            label="Teléfono/Celular"
            type="tel"
            variant="outlined" 
            fullWidth
            value={personalData.phone.value}
            error={personalData.phone.error}
            onChange={handleInputChange}
          />
        </Box>
      </Grid>

      <Grid item container>
        <Box width={1} mx={fieldMargin}>
          <TextField 
            name="subject"
            label="Asunto"
            variant="outlined" 
            fullWidth
            value={personalData.subject.value}
            error={personalData.subject.error}
            onChange={handleInputChange}
          />
        </Box>
      </Grid>

      <Grid item container>
        <TextFieldInner className={fieldMargin}
          name="message"
          value={personalData.message.val}
          error={personalData.message.error} 
          fullWidth 
          variant="filled" 
          label="Mensaje" 
          multiline 
          rows={4} 
          rowsMax={10}
          onChange={handleInputChange} />
      </Grid>

      

    </React.Fragment>
  );
}

export default PersonalContactDataForm;
