import React from 'react';

// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

// Components
import HeaderBar from '../../components/HeaderBar';
import HeaderLinks from '../../components/PaymentHeaderLinks';
import TextView from '../../components/TextView';


async function consultarPago(pagosData, setPagoConsultado, setPagosData, setTicketLink, setErrorMessage, showSnackbar,setBorderColor) {
  if (!pagosData || !pagosData.response) {
    setPagosData(null);
    setErrorMessage("Transacción no aprovada, Favor de Revisar");
    errorPago("Ocurrió un problema, favor de ponerse en contacto con el área de atención al cliente", showSnackbar);
    return;
  }

  setPagosData(pagosData);
  setBorderColor('2px solid red');

  if (pagosData.response.de_estatus_respuesta === "approved") {
    pagosData.status = "APROBADO";
    setTicketLink(pagosData.response.link_ticket)
    showSnackbar("Transacción exitosa!", "success");
    setBorderColor('2px solid green');
    return;
  }
  
  setBorderColor('2px solid red');
  if (pagosData.response.de_estatus_respuesta === "denied") {
    pagosData.status = "DENEGADO";
    errorPago("Transacción denegada por el Banco, Favor de Revisar", showSnackbar);
  } else if (pagosData.response.de_estatus_respuesta === "reject") {
    pagosData.status = "RECHAZADO";
    errorPago("Transacción rechazada por el Banco, Favor de Revisar", showSnackbar);
  } else {
    pagosData.status = "NO APROBADO";
    errorPago("Transacción no aprovada, Favor de Revisar", showSnackbar);
  }
}

function errorPago(message, showSnackbar) {
  showSnackbar(message, "error", 6000);
}

function PaymentResumePage({ showSnackbar }) {
  const xsSpan = 11;
  const smSpan = 10;
  const mdSpan = 6;

  const [isPagoConsultado, setPagoConsultado] = React.useState(false);
  const [pagosData, setPagosData] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState("Datos no encontrados");
  const [ticketLink, setTicketLink] = React.useState(null);
  const [borderColor, setBorderColor] = React.useState(null);

  React.useEffect(() => {
    let pagosStorage = JSON.parse(localStorage.getItem('pagosData'));

    setPagosData(pagosStorage);
    localStorage.removeItem('pagosData');

    if (pagosStorage) {
      consultarPago(pagosStorage, setPagosData, setPagoConsultado, setTicketLink, setErrorMessage, showSnackbar,setBorderColor);
    } else {
      showSnackbar("Ocurrió un problema, favor de ponerse en contacto con el área de atención al cliente", "error", 10000);
    }
  }, []);

  React.useEffect(() => {
    if (pagosData != null) {
      setPagoConsultado(true);
    }
  }, [pagosData]);

  const handleAccpet = () => {
    window.open("https://pel.sanmartin.com.mx/" + ticketLink, '_blank');
    window.location.href = '/payment/details';
  }

  return (
    <React.Fragment>
      {pagosData ?
        <React.Fragment>
          <PaymentAppBar
            username={pagosData.nombreCliente} />
          <Box mt={16} style={{ border: borderColor }}> 
            <br />
            <Grid item container direction="column" justifyContent="center" alignItems="flex-start">
              {isPagoConsultado ?
                <Grid item container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                  <Grid item container xs={xsSpan} sm={smSpan} md={mdSpan} direction="row" justifyContent="center" spacing={2} alignItems="center">
                    <Grid item container justifyContent="flex-end" xs>
                      <TextView variant="body2" fontWeight={400}>
                        Estatus:
                      </TextView>
                    </Grid>
                    <Grid item container justifyContent="flex-start" xs>
                      <TextView variant="body2" fontWeight={200}>
                        {pagosData.status}
                      </TextView>
                    </Grid>
                  </Grid>

                  <Grid item container xs={xsSpan} sm={smSpan} md={mdSpan} direction="row" justifyContent="center" spacing={2} alignItems="center">
                    <Grid item container justifyContent="flex-end" xs>
                      <TextView variant="body2" fontWeight={400}>
                        No Autorización:
                      </TextView>
                    </Grid>
                    <Grid item container justifyContent="flex-start" xs>
                      <TextView variant="body2" fontWeight={200}>
                        {pagosData.response.xmlRespuesta.CENTEROFPAYMENTS.auth[0]}
                      </TextView>
                    </Grid>
                  </Grid>

                  <Grid item container xs={xsSpan} sm={smSpan} md={mdSpan} direction="row" justifyContent="center" spacing={2} alignItems="center">
                    <Grid item container justifyContent="flex-end" xs>
                      <TextView variant="body2" fontWeight={400}>
                        Empresa:
                      </TextView>
                    </Grid>
                    <Grid item container justifyContent="flex-start" xs>
                      <TextView variant="body2" fontWeight={200}>
                        San Martín Grupo Funerario
                      </TextView>
                    </Grid>
                  </Grid>

                  <Grid item container xs={xsSpan} sm={smSpan} md={mdSpan} direction="row" justifyContent="center" spacing={2} alignItems="center">
                    <Grid item container justifyContent="flex-end" xs>
                      <TextView variant="body2" fontWeight={400}>
                        Referencia:
                      </TextView>
                    </Grid>
                    <Grid item container justifyContent="flex-start" xs>
                      <TextView variant="body2" fontWeight={200}>
                        {pagosData.response.xmlRespuesta.CENTEROFPAYMENTS.reference[0]}
                      </TextView>
                    </Grid>
                  </Grid>

                  <Grid item container xs={xsSpan} sm={smSpan} md={mdSpan} direction="row" justifyContent="center" spacing={2} alignItems="center">
                    <Grid item container justifyContent="flex-end" xs>
                      <TextView variant="body2" fontWeight={400}>
                        No. operación:
                      </TextView>
                    </Grid>
                    <Grid item container justifyContent="flex-start" xs>
                      <TextView variant="body2" fontWeight={200}>
                        {pagosData.response.xmlRespuesta.CENTEROFPAYMENTS.foliocpagos[0]}
                      </TextView>
                    </Grid>
                  </Grid>

                  <Grid item container xs={xsSpan} sm={smSpan} md={mdSpan} direction="row" justifyContent="center" spacing={2} alignItems="center">
                    <Grid item container justifyContent="flex-end" xs>
                      <TextView variant="body2" fontWeight={400}>
                        Liga:
                      </TextView>
                    </Grid>
                    <Grid item container justifyContent="flex-start" xs>
                      <TextView variant="body2" fontWeight={200}>
                        {pagosData.response.xmlRespuesta.CENTEROFPAYMENTS.id_url[0]}
                      </TextView>
                    </Grid>
                  </Grid>

                  <Grid item container xs={xsSpan} sm={smSpan} md={mdSpan} direction="row" justifyContent="center" spacing={2} alignItems="center">
                    <Grid item container justifyContent="flex-end" xs>
                      <TextView variant="body2" fontWeight={400}>
                        Fecha y hora:
                      </TextView>
                    </Grid>
                    <Grid item container justifyContent="flex-start" xs>
                      <TextView variant="body2" fontWeight={200}>
                        {pagosData.response.xmlRespuesta.CENTEROFPAYMENTS.date ?
                          pagosData.response.xmlRespuesta.CENTEROFPAYMENTS.date[0] + " " + pagosData.response.xmlRespuesta.CENTEROFPAYMENTS.time[0]
                          :
                          "----"
                        }
                      </TextView>
                    </Grid>
                  </Grid>

                  <Grid item container xs={xsSpan} sm={smSpan} md={mdSpan} direction="row" justifyContent="center" spacing={2} alignItems="center">
                    <Grid item container justifyContent="flex-end" xs>
                      <TextView variant="body2" fontWeight={400}>
                        Tarjeta:
                      </TextView>
                    </Grid>
                    <Grid item container justifyContent="flex-start" xs>
                      <TextView variant="body2" fontWeight={200}>
                        {pagosData.response.xmlRespuesta.CENTEROFPAYMENTS.cc_mask ?
                          pagosData.response.xmlRespuesta.CENTEROFPAYMENTS.cc_mask[0]
                          :
                          "----"
                        }
                      </TextView>
                    </Grid>
                  </Grid>

                  <Grid item container xs={xsSpan} sm={smSpan} md={mdSpan} direction="row" justifyContent="center" spacing={2} alignItems="center">
                    <Grid item container justifyContent="flex-end" xs>
                      <TextView variant="body2" fontWeight={400}>
                        Banco:
                      </TextView>
                    </Grid>
                    <Grid item container justifyContent="flex-start" xs>
                      <TextView variant="body2" fontWeight={200}>
                        {pagosData.response.xmlRespuesta.CENTEROFPAYMENTS.cc_type[0]}
                      </TextView>
                    </Grid>
                  </Grid>

                  <Grid item container xs={xsSpan} sm={smSpan} md={mdSpan} direction="row" justifyContent="center" spacing={2} alignItems="center">
                    <Grid item container justifyContent="flex-end" xs>
                      <TextView variant="body2" fontWeight={400}>
                        Correo:
                      </TextView>
                    </Grid>
                    <Grid item container justifyContent="flex-start" xs>
                      <TextView variant="body2" fontWeight={200}>
                        {pagosData.response.xmlRespuesta.CENTEROFPAYMENTS.email[0]}
                      </TextView>
                    </Grid>
                  </Grid>

                  <Grid item container xs={xsSpan} sm={smSpan} md={mdSpan} direction="row" justifyContent="center" spacing={2} alignItems="center">
                    <Grid item container justifyContent="flex-end" xs>
                      <TextView variant="body2" fontWeight={400}>
                        Importe:
                      </TextView>
                    </Grid>
                    <Grid item container justifyContent="flex-start" xs>
                      <TextView variant="body2" fontWeight={200}>
                        ${pagosData.importe}
                      </TextView>
                    </Grid>
                  </Grid>

                  <Grid item container direction="row" justifyContent="center" spacing={2}>
                    <Grid item>
                      <Box mt={4}>
                        {ticketLink ?
                          <Button variant="contained" color="primary" onClick={handleAccpet}>
                            Aceptar
                          </Button>
                          :
                          <Button variant="contained" color="primary" href="/payment/details">
                            Aceptar
                          </Button>
                        }
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                :
                <Grid item container direction="column" justifyContent="center" alignItems="center">
                  <Grid item>
                    <TextView variant="h4" m={4}>
                      Procesando transacción...
                    </TextView>
                  </Grid>
                </Grid>
              }
            </Grid>
            <br />
          </Box>
        </React.Fragment>
        :
        <Grid item container direction="column" justifyContent="center" alignItems="center" style={{ width: "100vw%", height: "100vh" }}>
          <Grid item>
            <TextView variant="h4" m={4}>
              {errorMessage}
            </TextView>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" href="/payment/details">
              Regresar
            </Button>
          </Grid>
        </Grid>
      }
    </React.Fragment>
  );
}

function closeSession() {
  localStorage.setItem('user', JSON.stringify({}));
  window.location.href = '/payment'
}

function PaymentAppBar({ username = "¿?" }) {
  return (
    <HeaderBar logo="/image/SMDark.png" rightLinks={<HeaderLinks username={username} closeSession={closeSession} />} fixed color="secondary" />
  );
}

export default PaymentResumePage;
