import React from 'react';

// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

// Components
import TextView from '../../../components/TextView';
import { roles, cities } from '../../../ArraysDB';

const NAME_LIMIT = 100;
const EMAIL_LIMIT = 64;
const PHONE_LIMIT = 10;

function PersonalDataForm({ values, updateValues, formatPhone, parsePhone, numberPattern, buyerForm=false, modalForm=false }) {
  const [personalData, updatePersonalData] = React.useState(values.personalData);
  const [fieldMargin, setFieldMargin] = React.useState(modalForm ? 0 : 2);

  const handleInputChange = event => {
    let newValue = event.target.value;

    if (event.target.name === 'name' && newValue.length > NAME_LIMIT) {
      newValue = personalData[event.target.name].value;
    }

    if (event.target.name === 'email' && newValue.length > EMAIL_LIMIT) {
      newValue = personalData[event.target.name].value;
    }

    if (event.target.name === 'phone') {
      newValue = parsePhone(newValue);
      if (!numberPattern.test(newValue) && newValue !== '') {
        newValue = personalData[event.target.name].value;
      }
      if (newValue.length > PHONE_LIMIT) {
        newValue = parsePhone(personalData[event.target.name].value);
      }
      newValue = formatPhone(newValue);
    }

    updatePersonalData({
      ...personalData,
      [event.target.name]: { 
        value: newValue,
        error: false
      } 
    });
  }

  React.useEffect(() => {
    updateValues({
      ...values,
      personalData: personalData
    });
  }, [personalData]);

  return (
    <React.Fragment>

      {!modalForm ? (
        <Grid item>
          <TextView variant="h5" textColor="#757575" mt={!buyerForm ? 3 : 16} mb={1} mx={1}>
            Datos de contacto
          </TextView>
        </Grid>
      ) : ( null )}

      <Grid item container>
        <Box width={1} mx={fieldMargin}>
          <TextField 
            name="name"
            label="Nombre completo"
            variant="outlined" 
            fullWidth
            value={personalData.name.value}
            error={personalData.name.error}
            onChange={handleInputChange}
          />
        </Box>
      </Grid>
      <Grid item container>
        <Box width={1} mx={fieldMargin}>
          <TextField 
            name="email"
            label="Correo electrónico"
            type="email"
            variant="outlined" 
            fullWidth
            value={personalData.email.value}
            error={personalData.email.error}
            onChange={handleInputChange}
          />
        </Box>
      </Grid>
      <Grid item container>
        <Box width={1} mx={fieldMargin}>
          <TextField 
            name="phone"
            label="Teléfono/Celular"
            type="tel"
            variant="outlined" 
            fullWidth
            value={personalData.phone.value}
            error={personalData.phone.error}
            onChange={handleInputChange}
          />
        </Box>
      </Grid>

      <Grid item container>
        <Box width={1} mx={fieldMargin}>
          { !buyerForm ?
            <TextField
              select
              name="rol"
              label="Rol familiar"
              variant="outlined" 
              fullWidth
              value={personalData.rol.value}
              error={personalData.rol.error}
              onChange={handleInputChange}
            >
              {roles.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          :
            <TextField
              select
              name="city"
              label="Ciudad"
              variant="outlined" 
              fullWidth
              value={personalData.city.value}
              error={personalData.city.error}
              onChange={handleInputChange}
            >
              {cities.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          }
        </Box>
      </Grid>

    </React.Fragment>
  );
}

export default PersonalDataForm;
