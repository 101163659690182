import React from 'react';

// @material-ui/core components
import Grid from "@material-ui/core/Grid";

// Components
import TextView from '../../../components/TextView';

function TitleSection() {
  return (
    <Grid item container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid item>
        <TextView  variant="h4" textColor="#35647D" fontWeight={700} textAlign="center" >
          Pago en línea
        </TextView>
      </Grid>
      <Grid item>
        <TextView variant="h5" textAlign="center" mt={2}>
          Realiza tus pagos de manera fácil y segura
        </TextView>
      </Grid>
    </Grid>
  );
}

export default TitleSection;
