import React from 'react';

// Components
import HeaderBar from '../components/HeaderBar';
import HeaderBarPrimaryWhite from '../components/HeaderBarPrimaryWhite';
import HeaderLinks from '../components/HeaderLinks';

function AppBar({ main=false, white=false, transparentPrimary=false, primaryWhite=false, appBarState=false }) {
  if (primaryWhite) {
    return primaryWhiteAppBar(appBarState);
  }
  if (transparentPrimary) {
    return transparentPrimaryAppBar();
  }
  if (white) {
    return whiteAppBar();
  }
  return main ? mainAppBar() : secondaryAppBar();
}

function mainAppBar() {
  return (
    <HeaderBar
      brand="San Martín"
      logo="/image/LogoSMWhite.png"
      rightLinks={<HeaderLinks />}
      fixed
      color="transparent"
      changeColorOnScroll={{
        height: 190,
        color: "primary"
      }}
    />
  );
}

function transparentPrimaryAppBar() {
  return (
    <HeaderBar
      brand="San Martín"
      logo="/image/SMBlue.png"
      changeLogo="/image/LogoSMWhite.png"
      rightLinks={<HeaderLinks />}
      fixed
      color="transparentPrimary"
      changeColorOnScroll={{
        height: 190,
        color: "primary"
      }}
    />
  );
}

function primaryWhiteAppBar(appBarState) {
  return (
    <HeaderBarPrimaryWhite
      brand="San Martín"
      logo="/image/LogoSMWhite.png"
      logoScroll="/image/SMBlue.png"
      rightLinks={<HeaderLinks />}
      fixed
      color="transparent"
      colorTwo="transparentPrimary"
      changeColorOnScroll={{
        height: 190,
        color: "primary"
      }}
      appBarState={appBarState}
    />
  );
}

function secondaryAppBar() {
  return (
    <HeaderBar
      brand="San Martín"
      logo="/image/LogoSMWhite.png"
      rightLinks={<HeaderLinks />}
      fixed
      color="primary"
    />
  );
}

function whiteAppBar() {
  return (
    <HeaderBar
      brand="San Martín"
      logo="/image/SMGray.png"
      rightLinks={<HeaderLinks />}
      fixed
      color="secondary"
    />
  );
}

export default AppBar;
